function Terms() {
  return (
    <section className="terms_container">
      <h1>Terms and Conditions of Nosara Shuttle</h1>
      <div className="terms_section">
        <h2>1. Acceptance of Terms and Conditions</h2>
        <p>1.1. By accessing and using the Nosara Shuttle application ("the Application"), you agree to comply with the terms and conditions set forth below. If you do not agree to these terms, please do not use the application.</p>
      </div>
      <div className="terms_section">
        <h2>2. Use of the Application</h2>
        <p>2.1. Nosara Shuttle provides travel monitoring services and coordination between drivers and users.</p>
        <p>2.2. Nosara Shuttle reserves the right to modify, suspend, or discontinue any aspect of the application at any time without prior notice.</p>
      </div>
      <div className="terms_section">
        <h2>3. Privacy and Data Protection</h2>
        <p>3.1. Nosara Shuttle collects and uses limited personal information, including names, surnames, email addresses, phone numbers, and photos, solely for the purpose of facilitating and improving the user experience and trip coordination.</p>
        <p>3.2. User passwords are securely stored and encrypted.</p>
        <p>3.3. The user has the right to request the deletion of their account by sending an email to info@nosara.com.</p>
        <p>3.4. Nosara Shuttle is committed to complying with all applicable data protection laws and regulations, including the General Data Protection Regulation (GDPR) of the European Union.</p>
      </div>
      <div className="terms_section">
        <h2>4. Information Security</h2>
        <p>4.1. Nosara Shuttle implements security measures to protect user information, but does not guarantee absolute security.</p>
        <p>4.2. Users are responsible for maintaining the confidentiality of their login information and for notifying Nosara Shuttle of any unauthorized use of their account.</p>
      </div>
      <div className="terms_section">
        <h2>5. User Responsibilities</h2>
        <p>5.1. Users agree to provide accurate and up-to-date information.</p>
        <p>5.2. Users commit to using the application ethically and legally, respecting the rights of other users and third parties.</p>
      </div>
      <div className="terms_section">
        <h2>6. Modifications to the Terms and Conditions</h2>
        <p>6.1. Nosara Shuttle reserves the right to update or modify these terms and conditions at any time. Changes will take effect after being posted in the application.</p>
      </div>
      <div className="terms_section">
        <h2>7. Limitation of Liability</h2>
        <p>7.1. Nosara Shuttle is not responsible for direct or indirect damages that may arise from the use of the application.</p>
      </div>
      <div className="terms_section">
        <h2>8. Applicable Law and Jurisdiction</h2>
        <p>8.1. These terms and conditions are subject to the laws of Costa Rica, and any dispute will be subject to the jurisdiction of the courts of Nosara.</p>
      </div>
      <div className="terms_section">
        <h2>9. User Conduct</h2>
        <p>9.1. Users acknowledge that Nosara Shuttle is not responsible for the content of messages exchanged between drivers and users through the chat feature.</p>
      </div>
      <div className="terms_section">
        <h2>10. Intellectual Property</h2>
        <p>10.1. All content and intellectual property within the application are the property of Nosara Shuttle. Users are prohibited from using, reproducing, or distributing any content without explicit permission.</p>
      </div>
      <div className="terms_section">
        <h2>11. Termination of Services</h2>
        <p>11.1. Nosara Shuttle reserves the right to terminate services or restrict access to the application at its discretion.</p>
      </div>
      <div className="terms_section">
        <h2>12. Communication and Notices</h2>
        <p>12.1. Users agree to receive communications and notices from Nosara Shuttle electronically.</p>
      </div>
    </section>
  )
}

export default Terms;