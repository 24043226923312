import React from 'react'

const ProfilePictureDemo2 = () => {
  return (
    <svg
      width="200"
      height="251"
      viewBox="0 0 200 251"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M170.276 0H29.6714C13.2843 0 0 13.1773 0 29.4322V221.436C0 237.691 13.2843 250.868 29.6714 250.868H170.276C186.663 250.868 199.948 237.691 199.948 221.436V29.4322C199.948 13.1773 186.663 0 170.276 0Z"
        fill="white"
      />
      <path
        d="M142.68 215.244C111.114 215.244 83.1645 229.282 65.8994 250.868H170.276C183.083 250.868 193.996 242.82 198.155 231.543C182.549 221.296 163.397 215.244 142.688 215.244H142.68Z"
        fill="#959595"
      />
      <path
        d="M165 225.819C150.353 229.835 135.006 229.835 120.367 225.819V197.393H165V225.819Z"
        fill="#B1B1B1"
      />
      <path
        d="M199.947 152.246V105.267C192.156 74.4933 169.466 52.228 142.68 52.228C109.455 52.228 82.5195 86.4876 82.5195 128.757C82.5195 171.025 109.455 205.285 142.68 205.285C169.466 205.285 192.156 183.02 199.947 152.246Z"
        fill="#D9D9D9"
      />
      <path
        d="M199.947 86.8464C188.964 66.2345 167.438 52.228 142.68 52.228C106.766 52.228 77.6533 81.6915 77.6533 118.041C77.6533 128.398 80.0198 138.193 84.226 146.904C84.226 146.904 95.0206 108.527 102.096 112.676C109.172 116.825 123.599 128.749 138.309 128.749C150.322 128.749 166.046 112.013 171.416 105.867C172.635 104.471 174.655 104.066 176.314 104.908C182.116 107.856 194.751 116.809 199.94 140.361V86.8386L199.947 86.8464Z"
        fill="#959595"
      />
    </svg>
  );
}

export default ProfilePictureDemo2