import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useAuth();
  const location = useLocation();

  return (
    allowedRoles && auth?.role && allowedRoles.includes(auth?.role)
      ? <Outlet />
      : auth?.user
        ? <Navigate to="/unauthorize" state={{ from: location }} replace />
        : <Navigate to="/" state={{ from: location }} replace />
  );
}

export default RequireAuth;