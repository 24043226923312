import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form";
import { contentTypeEs } from "../../../models/contentTypes"

import Select from 'react-select';

const AddArticleInformation = ({ state, formState, createArticle, validArticle }) => {
  const { register, control, formState: { errors, isDirty, isValid }, handleSubmit } = useForm();

  const onSubmit = (data) => {
    createArticle(data);
  }

  useEffect(() => {
    validArticle({ isDirty, isValid })
  }, [isDirty, isValid, validArticle])

  return (
    <form
      id="article-information"
      onSubmit={handleSubmit(onSubmit)}
    >
      <section style={state === formState.UserInformation ? {} : { display: "none" }}>
        <h3>Información del artículo</h3>
        <div className="form__input_container">
          <label htmlFor="">Nombre del Artículo</label>
          <input
            className={errors.tittle?.message ? "error" : ""}
            type="text"
            placeholder="Ingrese un título"
            {...register("title", {
              required: "Es requerido un título",
            })}
          />
        </div>
        <div className="form__input_container">
          <label htmlFor="">Categoría</label>
          <Controller
            render={({ field: { onChange } }) => (
              <Select
                classNamePrefix="react-select"
                isClearable={false}
                options={contentTypeEs}
                placeholder="Seleccione una  categoría"
                onChange={(value) => {
                  onChange(value.value);
                }}
              />
            )}
            control={control}
            name="category"
            rules={{ required: true }}
          />
        </div>
        <div className="form__input_container">
          <label htmlFor="">Descripción</label>
          <textarea
            className={errors.tittle?.message ? "error" : ""}
            type="text"
            placeholder="Ingrese una descripción"
            {...register("description", {
              required: "Es requerido un título",
            })}
          />
        </div>
        <div className="form__input_container">
          <label htmlFor="">Enlace</label>
          <input
            className={errors.contentUrl?.message ? "error" : ""}
            type="text"
            placeholder="Ingrese un enlace"
            {...register("contentUrl", {
              required: "Es requerido un enlace",
            })}
          />
        </div>
      </section>
    </form>
  );
};

export default AddArticleInformation;
