import React from 'react'

const TouristIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
    >
      <path
        d="M0.756836 1.2605H15.7456C16.5725 1.2605 17.2429 1.93084 17.2429 2.75775V21.2423C17.2429 22.0692 16.5725 22.7395 15.7456 22.7395H0.756836V1.2605Z"
        stroke="#959595"
        strokeWidth="1.5"
      />
      <circle
        cx="8.99995"
        cy="8.82197"
        r="4.33393"
        stroke="#959595"
        strokeWidth="1.5"
      />
      <path
        d="M10.4443 8.82197C10.4443 10.1486 10.2108 11.314 9.86303 12.1196C9.68862 12.5237 9.50024 12.8031 9.3292 12.9707C9.16153 13.135 9.05125 13.1559 9 13.1559C8.94875 13.1559 8.83846 13.135 8.6708 12.9707C8.49976 12.8031 8.31138 12.5237 8.13697 12.1196C7.78922 11.314 7.55566 10.1486 7.55566 8.82197C7.55566 7.49533 7.78922 6.32996 8.13697 5.5243C8.31138 5.12021 8.49976 4.84083 8.6708 4.67323C8.83846 4.50893 8.94875 4.48804 9 4.48804C9.05125 4.48804 9.16153 4.50893 9.3292 4.67323C9.50024 4.84083 9.68862 5.12021 9.86303 5.5243C10.2108 6.32996 10.4443 7.49533 10.4443 8.82197Z"
        stroke="#959595"
        strokeWidth="1.5"
      />
      <path
        d="M14.0159 8.82153H3.9834"
        stroke="#959595"
        strokeWidth="1.5"
      />
      <path
        d="M14.0159 17.4927H3.9834"
        stroke="#959595"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12.641 19.8751H5.3584"
        stroke="#959595"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default TouristIcon