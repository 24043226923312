import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import NewsIcon from "../../assets/svg/NavIcons/NewsIcon";
import TransportIcon from "../../assets/svg/NavIcons/TransportIcon";
import ProfileIcon from "../../assets/svg/NavIcons/ProfileIcon";
import HistoryIcon from "../../assets/svg/NavIcons/HistoryIcon";

const UserNavbar = ({ user }) => {
  const navigate = useNavigate();

  const routeToGo = () => {
    switch (user) {
      case "Super":
        navigate("/admin");
        break;

      case "Admin":
        navigate("/admin");
        break;

      case "Tourist":
        navigate("/user");
        break;

      case "Driver":
        navigate("/driver");
        break;

      default:
        navigate("/");
        break;
    }
  };

  return (
    <nav className="userNavbar_container">
      <div className="userNavbar_item userNavbar_logo">
        <div className="withoutPadding">
          <img
            onClick={routeToGo}
            className="navbar_logo__big"
            src={require("../../assets/icons/NosaraLogo.png")}
            alt="Nosara Logo"
          ></img>
        </div>
      </div>
      {user === "Tourist" && (
        <section>
          <div className="userNavbar_item">
            <div className="userNavbar_item__mobile"></div>
            <div className="userNavbar_button__container">
              <NavLink
                to="user/news"
                className={({ isActive }) => `userNavbar_button ${isActive ? "userNavbarItemTripActive" : ""}`}
              >
                <NewsIcon />
                <p>News</p>
              </NavLink>
            </div>
          </div>
        </section>
      )}
      {user === "Tourist" && (
        <section>
          <div className="userNavbar_item">
            <div className="userNavbar_button__container">
              <NavLink
                to="user/reservations"
                className={({ isActive }) => `userNavbar_button ${isActive ? "userNavbarItemTripActive" : ""}`}
              >
                <TransportIcon />
                <p>My Rides</p>
              </NavLink>
            </div>
          </div>
        </section>
      )}
      {user === "Tourist" && (
        <section>
          <div className="userNavbar_item">
            <div className="userNavbar_button__container">
              <NavLink
                to="user/profile"
                className={({ isActive }) => `userNavbar_button ${isActive ? "userNavbarItemActive" : ""}`}
              >
                <ProfileIcon />
                <p>Profile</p>
              </NavLink>
            </div>
          </div>
        </section>
      )}

      {user === "Driver" && (
        <section>
          <div className="userNavbar_item">
            <div className="userNavbar_item__mobile"></div>
            <div className="userNavbar_button__container">
              <NavLink
                to="driver/history"
                className={({ isActive }) => `userNavbar_button ${isActive ? "userNavbarItemActive" : ""}`}
              >
                <HistoryIcon />
                <p>Historial</p>
              </NavLink>
            </div>
          </div>
        </section>
      )}
      {user === "Driver" && (
        <section>
          <div className="userNavbar_item">
            <div className="userNavbar_button__container">
              <NavLink
                to="driver/trips"
                className={({ isActive }) => `userNavbar_button ${isActive ? "userNavbarItemTripActive" : ""}`}
              >
                <TransportIcon />
                <p>Viajes asignados</p>
              </NavLink>
            </div>
          </div>
        </section>
      )}
      {user === "Driver" && (
        <section>
          <div className="userNavbar_item">
            <div className="userNavbar_button__container">
              <NavLink
                to="driver/profile"
                className={({ isActive }) => `userNavbar_button ${isActive ? "userNavbarItemActive" : ""}`}
              >
                <ProfileIcon />
                <p>Perfil</p>
              </NavLink>
            </div>
          </div>
        </section>
      )}
    </nav>
  );
};

export default UserNavbar;
