import { useState, useEffect } from "react";
import { useUpdateUsers } from "../../../hooks/useUsers";

import EditTouristInformation from "./EditTouristInformation";
import EditTouristAccount from "./EditTouristAccount";
import LazyLoading from "../../../components/LazyLoading";
import GoBackButton from "../../GoBackButton";

const EditTourist = ({ tourist, close }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [state, setState] = useState({ success: false, fail: false })
  const [category, setCategory] = useState({
    information: true,
    account: false,
  });

  const { updatedUser, updateUserError, updateUser } = useUpdateUsers();

  const handleCategory = (activeCategory) => {
    const initialValue = {
      information: false,
      account: false,
    };
    setState({ success: false, fail: false })

    const updatedCategories = { ...initialValue };
    updatedCategories[activeCategory] = true;

    setCategory(updatedCategories);
  };

  useEffect(() => {
    if (tourist) {
      setUser(tourist)
    }
  }, [tourist])

  useEffect(() => {
    if (updatedUser) {
      setUser(updatedUser)
    }
  }, [updatedUser])

  useEffect(() => {
    if (formData) {
      setIsLoading(true);
      updateUser(formData, tourist._id);
    }
  }, [formData])

  useEffect(() => {
    if (updatedUser) {
      setState({ success: true, fail: false })
      setIsLoading(false);
    } else if (updateUserError) {
      setState({ success: false, fail: true })
      setIsLoading(false);
    }
  }, [updatedUser, updateUserError])

  return (
    <section className="edit-popup">
      <div className="edit-popup__box">
        <div className="edit-popup__container">
          <div className="edit-popup__header">
            <GoBackButton
              title="Edite la información del Turista"
              subtitle="Edite la información del turista según sea necesario"
              onClick={close}
              isClose
            />
            {state.success && (
              <p className="edit-popup__status edit-popup__status--success">Cambios guardados con éxito</p>
            )}
            {state.fail && <p className="edit-popup__status edit-popup__status--error">Cambios no procesados</p>}
          </div>
          {!isLoading && (
            <>
              <div className="edit-popup__category">
                <button
                  className={`category-button ${category.information ? "category-button--active" : ""}`}
                  onClick={() => handleCategory("information")}
                >
                  Información
                </button>
                <button
                  className={`category-button ${category.account ? "category-button--active" : ""}`}
                  onClick={() => handleCategory("account")}
                >
                  Cuenta
                </button>
              </div>
              {(category.information && user) && (
                <EditTouristInformation
                  data={user}
                  setFormData={setFormData}
                />
              )}
              {(category.account && user) && (
                <EditTouristAccount
                  data={user}
                  setFormData={setFormData}
                />
              )}
            </>
          )}
          {isLoading && <LazyLoading />}
        </div>
      </div>
    </section>
  );
};

export default EditTourist;
