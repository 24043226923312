import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import ProfilePicture from "../../../assets/svg/ProfilePicture";
import DocumentIcon from "../../../assets/svg/DocumentIcon";
import LicenseImage from "../../../assets/svg/LicenseImage";
import CloudIcon from "../../../assets/svg/CloudIcon";
import ProfilePictureDemo1 from "../../../assets/svg/ProfilePicturesDemo/ProfilePictureDemo1";
import ProfilePictureDemo2 from "../../../assets/svg/ProfilePicturesDemo/ProfilePictureDemo2";
import ProfilePictureDemo3 from "../../../assets/svg/ProfilePicturesDemo/ProfilePictureDemo3";
import UploadLicense from "../../uploadFile/uploadLicense";
import UploadProfilePicture from "../../uploadFile/uploadProfilePicture";

const AddDriverDocuments = ({ state, formState, sectionOpen, setSectionOpen, uploadPictures, validImages }) => {
  const [profilePictureName, setProfilePictureName] = useState("");
  const [licenseName, setLicenseName] = useState("");
  const [documentsCompleted, setDocumentsCompleted] = useState(0);

  const {
    register,
    getFieldState,
    formState: { isDirty, isValid },
    handleSubmit,
  } = useForm();

  const isLicenseUploadValid = getFieldState("licensePicture");
  const isProfilePictureUploadValid = getFieldState("profilePicture");

  const onSubmit = (data) => {
    uploadPictures(data);
  };

  useEffect(() => {
    if (isLicenseUploadValid.isDirty && isProfilePictureUploadValid.isDirty) {
      setDocumentsCompleted(2);
      return;
    }
    if (isLicenseUploadValid.isDirty || isProfilePictureUploadValid.isDirty) {
      setDocumentsCompleted(1);
    }
  }, [isLicenseUploadValid, isProfilePictureUploadValid]);

  useEffect(() => {
    validImages({ isDirty, isValid });
  }, [isDirty, isValid, validImages]);

  useEffect(() => {
    setSectionOpen((prevSectionOpen) => ({...prevSectionOpen, license: false}));
    setSectionOpen((prevSectionOpen) => ({...prevSectionOpen, profilePicture: false }));
  }, [licenseName, profilePictureName])

  return (
    <form
      id="driver-pictures"
      onSubmit={handleSubmit(onSubmit)}
      className="form__upload"
      style={state === formState.UserImages ? {} : { display: "none" }}
    >
      {!isLicenseUploadValid.invalid && !isProfilePictureUploadValid.invalid && (
        <>
          <div className={`form__document_progress ${documentsCompleted > 0 && "form__document_progress--active"}`}>
            <p>
              <strong>Documento {documentsCompleted}</strong> de {2}
            </p>
            <div className="form__document_progress--bar">
              <span
                style={{
                  width: `${(documentsCompleted / 2) * 100}%`,
                }}
              ></span>
            </div>
          </div>
          <h3 className="form__subtitle">Documentos requeridos</h3>
        </>
      )}
      <div
        className={`form__mobile_upload ${sectionOpen.license || sectionOpen.profilePicture ? "form__mobile_upload--hidden" : ""
          }`}
      >
        <button
          type="button"
          className={`form__mobile_upload--button ${licenseName ? "form__mobile_upload--completed" : ""}`}
          onClick={() => setSectionOpen((prevState) => ({ ...prevState, license: true }))}
        >
          <span>
            <DocumentIcon />
          </span>
          <div className="form__mobile_upload--text">
            <p>Licencia de conducir</p>
            <p>{licenseName ? "El documento se ha subido con éxito" : "Haz click aquí para adjuntar el documento"}</p>
          </div>
        </button>
        <button
          type="button"
          className={`form__mobile_upload--button ${profilePictureName ? "form__mobile_upload--completed" : ""}`}
          onClick={() => setSectionOpen((prevState) => ({ ...prevState, profilePicture: true }))}
        >
          <span>
            <DocumentIcon />
          </span>
          <div className="form__mobile_upload--text">
            <p>Fotografía de perfil</p>
            <p>
              {profilePictureName ? "El documento se ha subido con éxito" : "Haz click aquí para adjuntar el documento"}
            </p>
          </div>
        </button>
      </div>

      <UploadLicense
        show={sectionOpen.license}
        licenseName={licenseName}
        setLicenseName={setLicenseName}
        register={register}
      />

      <UploadProfilePicture
        show={sectionOpen.profilePicture}
        profilePictureName={profilePictureName}
        setProfilePictureName={setProfilePictureName}
        register={register}
      />
    </form>
  );
};

export default AddDriverDocuments;
