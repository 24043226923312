import { useState, useEffect } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { useDeleteUser, useReadUsers } from "../../../hooks/useUsers";
import { languagesEn, transformToStringLanguages } from "../../../models/languages";
import { gendersEn, transformToStringGender } from "../../../models/genders";

import userExample from "../../../assets/icons/user.png";
import Header from "../../../components/Header.js";
import Overlays from "../../../components/modals/overlays.js";
import EditTouristProfile from "../../../components/forms/editTouristProfile/editTouristProfile.js";
import PopUp from "../../../components/modals/popUp.js";
import useLogout from "../../../hooks/useLogout.js";

function UserProfile() {
  const [editUser, setEditUser] = useState(false);
  const [deleteProfile, setDeleteProfile] = useState(false);

  const { auth } = useAuth();
  const { user, getUserById } = useReadUsers();
  const { deleteAccount } = useDeleteUser()
  const logout = useLogout()

  const onDelete = async () => {
    await deleteAccount()
    logout()
  }

  useEffect(() => {
    if (auth) {
      getUserById(auth?.user);
    }
  }, [auth]);

  return (
    <>
      <section className="userProfile">
        <section className="userProfile__body">
          <Header name="My profile" />
          <div className="userProfile__image">
            <img
              src={user?.profilePicture ? user?.profilePicture : userExample}
              alt="Your profile"
            />
            <p className="userProfile__id">
              ID Tourist
              <strong>{user?._id}</strong>
            </p>
          </div>
          <div className="userProfile__info">
            <div className="two-columns">
              <div className="two-rows">
                <p className="userProfile__info_row">
                  Full Name
                  <span>
                    {user?.name} {user?.lastName}
                  </span>
                </p>
                <p className="userProfile__info_row">
                  Gender
                  <span>{user && transformToStringGender(user?.gender, gendersEn)}</span>
                </p>
              </div>
              <div className="two-rows">
                <p className="userProfile__info_row">
                  Phone number
                  <span>{user?.phone}</span>
                </p>
                <p className="userProfile__info_row">
                  Languages
                  <span>{user && transformToStringLanguages(user?.languages, languagesEn)}</span>
                </p>
              </div>
            </div>
          </div>
          <button
            className="userProfile__editButton action-button action-button--secondary"
            onClick={() => setEditUser(true)}
          >
            Edit your profile
          </button>
          <button
            className="userProfile__editButton alert-button alert-button--secondary"
            onClick={() => setDeleteProfile(true)}
          >
            Delete your account
          </button>
        </section>
      </section>
      <Overlays>
        {editUser && <EditTouristProfile close={() => setEditUser(false)} />}
        {deleteProfile && (
          <PopUp
            type="warning"
            title="¿Estás seguro que deseas eliminar tu cuenta?"
            subtitle="Esta acción es irreversible"
            close={() => setDeleteProfile(false)}
          >
            <button
              className="alert-button alert-button--primary"
              onClick={onDelete}
            >
              Sí
            </button>
            <button
              className="alert-button alert-button--secondary"
              onClick={() => setDeleteProfile(false)}
            >
              No
            </button>
          </PopUp>
        )}
      </Overlays>
    </>
  );
}

export default UserProfile;
