import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from "./useAuth";
import axios from "../api/nosaraShuttle";
import jwt_decode from "jwt-decode";

const API_KEY = process.env.REACT_APP_API_KEY;

const useRefreshToken = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setAuth, persist } = useAuth();
  const token = persist.refreshToken;

  const refresh = async () => {
    try {
      const response = await axios.post(
        'auth/refresh',
        {},
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${token}`,
            'apikey': API_KEY
          }
        }
      );

      let decodedToken = jwt_decode(response?.data?.access_token);
      let role = decodedToken.role;
      let email = decodedToken.email;
      let user = decodedToken.sub;

      setAuth(prev => {


        return {
          ...prev,
          role: role,
          email: email,
          user: user,
          accessToken: response.data.access_token,
          refreshToken: response.data.refresh_token
        }
      });

      return response.data.access_token

    } catch (error) {
      setAuth({});
      console.log('saliendo')
      navigate('/', { state: { from: location }, replace: true });
    }

  }

  return refresh;
};

export default useRefreshToken;