import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { languagesEs } from "../../../models/languages";
import { gendersEs } from "../../../models/genders";
import { PhoneInput } from "react-international-phone";

import Select from 'react-select'

const EditDriverInformation = ({ data, setFormData }) => {
  const [countryCode, setCountryCode] = useState("");
  const { register, reset, control, formState: { errors, isValid }, handleSubmit } = useForm();

  const onSubmit = (user) => {
    const newUser = {
      name: user.name,
      lastName: user.lastName,
      gender: user.gender,
      phone: user.phone,
      countryCode: countryCode ? countryCode.toUpperCase() : data.countryCode,
      languages: user.languages
    }
    setFormData(newUser);
  }

  const findValue = (value, array) => {
    return array.find(item => item.value === value);
  };

  const findValues = (values, array) => {
    return array.filter(obj => values.includes(obj.value));
  };

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [reset, data])

  return (
    <>
      <h2 className="edit-popup__subtitle">Información Personal</h2>
      <form
        className="edit-popup__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="edit-popup__form-wrapper">
          <div className="form__two_columns">
            <div className="form__input_container">
              <label htmlFor="">Nombre (s)</label>
              <input
                type="text"
                placeholder="Escribe su nombre"
                {...register("name", {
                  required: "Es necesario un nombre",
                })}
              />
            </div>
            <div className="form__input_container">
              <label htmlFor="">Apellido (s)</label>
              <input
                type="text"
                placeholder="Escribe su apellido"
                {...register("lastName", {
                  required: "Es necesario un apellido",
                })}
              />
            </div>
          </div>
          <div className="form__two_columns">
            <div className="form__input_container">
              <label htmlFor="">Teléfono</label>
              <Controller
                render={({ field: { onChange } }) => (
                  <PhoneInput
                    inputStyle={errors.phone ? { borderColor: "#F55555" } : { borderColor: "#7C7C7C" }}
                    defaultCountry="cr"
                    value={data?.phone}
                    onChange={(phone, country) => {
                      setCountryCode(country.country.iso2);
                      onChange(phone);
                    }}
                  />
                )}
                control={control}
                name="phone"
                rules={{ required: true, minLength: 5 }}
              />
            </div>
            <div className="form__input_container">
              <label htmlFor="">Género</label>
              <Controller
                render={({ field: { onChange } }) => (
                  <Select
                    classNamePrefix="react-select"
                    isClearable={false}
                    options={gendersEs}
                    defaultValue={findValue(data?.gender, gendersEs)}
                    placeholder="Seleccione un género"
                    onChange={(value) => {
                      onChange(value.value);
                    }}
                  />
                )}
                control={control}
                name="gender"
                rules={{ required: true }}
              />
            </div>
          </div>
          <div className="form__input_container">
            <label htmlFor="">Idiomas</label>
            <Controller
              render={({ field: { onChange } }) => (
                <Select
                  classNamePrefix="react-select"
                  isClearable={true}
                  isMulti={true}
                  options={languagesEs}
                  defaultValue={findValues(data?.languages, languagesEs)}
                  placeholder="Seleccione los idiomas"
                  onChange={(data) => {
                    onChange(data.map((id) => id.value));
                  }}
                />
              )}
              control={control}
              name="languages"
              rules={{ required: true }}
            />
          </div>
        </div>
        <button
          type="submit"
          className="edit-popup__button action-button action-button--primary"
          disabled={!isValid}
        >
          Guardar cambios
        </button>
      </form>
    </>
  );
};

export default EditDriverInformation;
