import BarChar from "../barChart";
import CarIcon from "../../assets/svg/CarIcon";
import TimeIcon from "../../assets/svg/TimeIcon";

import NoUserImage from "../../assets/icons/user.png";
import { gendersEs, transformToStringGender } from "../../models/genders";
import { languagesEs, transformToStringLanguages } from "../../models/languages";

const AboutManagerInfo = ({ admin, driverTenure, totalTrips, stats, setSelectedDay }) => {
  return (
    <div className="aboutInfo">
      <div className="aboutInfo__profile">
        <div className="aboutInfo__profileName">
          <div className="aboutInfo__image">
            <img
              src={admin?.profilePicture ? admin.profilePicture : NoUserImage}
              alt="Client"
            />
          </div>
          <h2>
            {admin?.name} {admin?.lastName}
          </h2>
        </div>
        <div className="aboutInfo__profileContent">
          <p className="aboutInfo__profileField">
            Correo
            <span>{admin?.email}</span>
          </p>
          <p className="aboutInfo__profileField">
            Género
            <span>{transformToStringGender(admin?.gender, gendersEs)}</span>
          </p>
          <p className="aboutInfo__profileField">
            Número de teléfono
            <span>{admin?.phone}</span>
          </p>
          <p className="aboutInfo__profileField">
            Idiomas
            <span>{admin && transformToStringLanguages(admin?.languages, languagesEs)}</span>
          </p>
        </div>
      </div>

      <section className="aboutInfo__stats">
        <div className="aboutInfo__column">
          <CarIcon />
          {/* {totalTrips?.totalTrips && <h2>{totalTrips?.totalTrips}</h2>} */}
          <h2>2 Viajes</h2>
          <p>Agendados en total</p>
        </div>
        <div className="aboutInfo__column">
          <TimeIcon />
          {/* {driverTenure?.unit && <h2> {`${driverTenure?.tenure} ${driverTenure?.unit}`} </h2>} */}
          <h2>2 Años</h2>
          <p>En la plataforma</p>
        </div>
      </section>
      <BarChar
        stats={stats}
        setSelectedDay={setSelectedDay}
      />
    </div>
  );
};

export default AboutManagerInfo;
