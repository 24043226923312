import React from 'react'

const NewsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="34"
      viewBox="0 0 38 34"
      fill="none"
    >
      <path
        d="M33.3965 15.4566H25.1147V4.70174C25.1147 2.40565 23.2622 0.539185 20.9833 0.539185H4.2398C1.96089 0.539185 0.108398 2.40565 0.108398 4.70174V25.6577C0.108398 29.8346 3.48174 33.2381 7.63209 33.2381H29.8905C34.0882 33.2381 37.5089 29.7964 37.5089 25.5622V19.5953C37.5089 17.3135 35.6659 15.4566 33.4012 15.4566H33.3965ZM7.63209 30.374C5.04996 30.374 2.9511 28.2593 2.9511 25.6577V4.70651C2.9511 3.99048 3.52912 3.4081 4.2398 3.4081H20.9833C21.694 3.4081 22.272 3.99048 22.272 4.70651V25.5622C22.272 27.381 22.9069 29.0565 23.9634 30.374H7.63209ZM34.6615 25.5622C34.6615 28.2163 32.52 30.374 29.8857 30.374C27.2515 30.374 25.1147 28.2164 25.11 25.567V18.3255H33.3917C34.0882 18.3255 34.6567 18.8983 34.6567 19.6001V25.567L34.6615 25.5622Z"
        fill="#7C7C7C"
      />
      <path
        d="M19.4111 7.90466H11.4799C10.6935 7.90466 10.0586 8.54432 10.0586 9.33673C10.0586 10.1291 10.6935 10.7688 11.4799 10.7688H19.4111C20.1976 10.7688 20.8324 10.1291 20.8324 9.33673C20.8324 8.54432 20.1976 7.90466 19.4111 7.90466Z"
        fill="#7C7C7C"
      />
      <path
        d="M19.4111 12.9596H11.4799C10.6935 12.9596 10.0586 13.5993 10.0586 14.3917C10.0586 15.1841 10.6935 15.8237 11.4799 15.8237H19.4111C20.1976 15.8237 20.8324 15.1841 20.8324 14.3917C20.8324 13.5993 20.1976 12.9596 19.4111 12.9596Z"
        fill="#7C7C7C"
      />
      <path
        d="M19.4115 18.0155H5.81393C5.02745 18.0155 4.39258 18.6552 4.39258 19.4476C4.39258 20.24 5.02745 20.8796 5.81393 20.8796H19.4115C20.198 20.8796 20.8329 20.24 20.8329 19.4476C20.8329 18.6552 20.198 18.0155 19.4115 18.0155Z"
        fill="#7C7C7C"
      />
      <path
        d="M19.4115 23.0708H5.81393C5.02745 23.0708 4.39258 23.7105 4.39258 24.5029C4.39258 25.2953 5.02745 25.9349 5.81393 25.9349H19.4115C20.198 25.9349 20.8329 25.2953 20.8329 24.5029C20.8329 23.7105 20.198 23.0708 19.4115 23.0708Z"
        fill="#7C7C7C"
      />
    </svg>
  );
}

export default NewsIcon