import { useState, useEffect } from "react";
import { io } from "socket.io-client";
import LeftArrow from "../assets/svg/LeftArrow";
import SendIcon from "../assets/svg/SendIcon";
import { useAuth } from "../hooks/useAuth";

const socketMessage = io("https://nosara-shuttle-api.onrender.com/chat", { transports: ["websocket"] });

function Chat({ user, isChatOpen, setIsChatOpen, room }) {
  const {
    auth: { user: userId },
  } = useAuth();

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);

  const onSendMessage = (e) => {
    e.preventDefault();
    socketMessage.emit("send_message", { content: message, senderId: userId, room });
    setMessage("");
  };

  useEffect(() => {
    socketMessage.on("connect", () => {
      console.log("Connected to Message Socket");
    });

    socketMessage.emit("join_room", room);

    return () => {
      socketMessage.emit("leave_room", room);
      console.log("Disconnecting from message socket");
    };
  }, [room]);

  useEffect(() => {
    const handleReceiveMessage = (newMessage) => {
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      console.log(newMessage);
      console.log("senderID", newMessage.senderId, "usuario", userId)
    };

    socketMessage.on("receive_message", handleReceiveMessage);

    return () => {
      socketMessage.off("receive_message", handleReceiveMessage);
    };
  }, []);

  return (
    <article className={`chat_container ${isChatOpen ? "open" : ""}`}>
      <div className="chat_container__header">
        <div className="go_back_button">
          <button
            className="go_back_button"
            onClick={() => setIsChatOpen(false)}
          >
            <LeftArrow />
          </button>
        </div>
        <div className="chat_container__user">
          <img
            src={user?.profilePicture ? user?.profilePicture : require("../assets/icons/user.png")}
            alt="User"
          />
          <div className="chat_container__userName">
            <h4>{`${user.name} ${user.lastName}`}</h4>
            {/* <p>Online</p> */}
          </div>
        </div>
      </div>
      <section className="chat_container__body">
        {messages?.map((data, index) => (
          <div
            className={`chat_message ${data.senderId !== userId ? "chat_right" : "chat_left"}`}
            key={index}
          >
            <p>{data.content}</p>
          </div>
        ))}
      </section>
      <section className="chat_container__footer">
        <form
          className="chat_container__input"
          onSubmit={onSendMessage}
        >
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button className="action-button action-button--primary chat_container__close">
            <SendIcon />
          </button>
        </form>
      </section>
    </article>
  );
}

export default Chat;
