import React from 'react'

const ReservationIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6514 3.58868H3.65137C2.5468 3.58868 1.65137 4.48411 1.65137 5.58868V19.5887C1.65137 20.6933 2.5468 21.5887 3.65137 21.5887H17.6514C18.7559 21.5887 19.6514 20.6933 19.6514 19.5887V5.58868C19.6514 4.48411 18.7559 3.58868 17.6514 3.58868Z"
        stroke="#959595"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6514 1.58868V5.58868"
        stroke="#959595"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.65137 1.58868V5.58868"
        stroke="#959595"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6513 9.6424L8.75874 15.5349L6.65137 13.4276"
        stroke="#959595"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ReservationIcon