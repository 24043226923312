import { useState } from "react";
import { useForm } from "react-hook-form";
import UploadSitePicture from "../../uploadFile/uploadSitePicture";

const EditPointPicture = ({ data, setFormData }) => {
  const [pointPictureName, setPointPictureName] = useState(null);

  const { register, getFieldState, handleSubmit } = useForm();

  const isPointPictureUploadValid = getFieldState("poiImage");

  const onSubmit = (pictureData) => {
    setFormData(pictureData);
  };

  return (
    <>
      <h2 className="edit-popup__subtitle">Documentos requeridos</h2>
      <form
        className="edit-popup__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="edit-popup__form-wrapper">
          <UploadSitePicture
            show
            pointPictureName={pointPictureName}
            setPointPictureName={setPointPictureName}
            register={register}
          />
        </div>
        <button
          type="submit"
          className="edit-popup__button action-button action-button--primary"
          disabled={!isPointPictureUploadValid.isDirty}
        >
          Guardar cambios
        </button>
      </form>
    </>
  );
};

export default EditPointPicture;
