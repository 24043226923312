import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useReadContent, useDeleteContent } from "../../../hooks/useContent";

import userExample from "../../../assets/images/user-example.png";
import AddButton from "../../../components/addButton";
import Card from "../../../components/Card";
import LazyLoading from "../../../components/LazyLoading";
import Header from "../../../components/Header";
import CategoryButton from "../../../components/CategoryButton";

function Cover() {
  const { images, getCovers } = useReadContent();
  const { deleteImageLoading, deleteImageError, deleteCover } = useDeleteContent();
  const navigate = useNavigate();

  const buttonName = "Agregar portada";

  useEffect(() => {
    getCovers();
  }, []);

  useEffect(() => {
    if (!deleteImageError && !deleteImageLoading) {
      getCovers();
    }
  }, [deleteImageError, deleteImageLoading]);

  return (
    <section className="contentLayout">
      <section className="contentLayout__container">
        <div className="contentLayout__title">
          <Header name="Contenido" />
        </div>
        <div className="contentLayout__buttons">
          <CategoryButton
            name="Portadas"
            isActive={true}
          />
          <CategoryButton
            name="Noticias"
            onClick={() => navigate("/admin/content/articles")}
          />
          <AddButton
            name={buttonName}
            route={"add"}
            className="add-button"
          />
        </div>
        <h2 className="contentLayout__subtitle">Añada, Edite o Elimine una portada</h2>
        <div className="contentLayout__grid">
          {images &&
            !deleteImageLoading &&
            images.map((image, index) => (
              <Card
                key={index}
                type={"cover"}
                data={image}
                deleteCard={deleteCover}
              ></Card>
            ))}
        </div>
        {deleteImageLoading && <LazyLoading />}
      </section>
    </section>
  );
}

export default Cover;
