import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation, Link } from "react-router-dom";

import { useAuthLogin, useAuth } from "../../hooks/useAuth";

function LoginForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname;

  const { setAuth } = useAuth();
  const { userAuthData, userAuthDataLoading, userAuthDataError, login } = useAuthLogin();

  const {
    register,
    formState: { error },
    handleSubmit,
  } = useForm();

  const onSubmit = async (data) => {
    await login(data);
  };

  const goTo = () => {
    navigate("register");
  };

  const redirectTo = (role) => {
    switch (role) {
      case "Driver":
        navigate(from ? from : "/driver", { state: { from: location }, replace: true });
        break;

      case "Admin":
        navigate(from ? from : "/admin", { state: { from: location }, replace: true });
        break;

      case "Super":
        navigate(from ? from : "/admin", { replace: true });
        break;

      case "Tourist":
        navigate(from ? from : "/user", { replace: true });
        break;

      default:
        navigate(from ? from : "/", { replace: true });
        break;
    }
  };

  useEffect(() => {
    if (!userAuthDataLoading && userAuthData) {
      const accessToken = userAuthData.data.access_token;
      const refreshToken = userAuthData.data.refresh_token;
      const role = userAuthData.decodedToken.role;
      const email = userAuthData.decodedToken.email;
      const user = userAuthData.decodedToken.sub;
      setAuth({ accessToken, refreshToken, role, user, email });
      let access = { accessToken: accessToken, refreshToken: refreshToken };
      localStorage.setItem("nosara-persist", JSON.stringify(access));
      redirectTo(role);
    }
  }, [userAuthDataLoading, userAuthData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {userAuthDataError?.response?.status === 403 && (
        <section className="login_form__error">
          <p>Wrong User or Password!</p>
        </section>
      )}
      <section className="login_form__container">
        <div className="form__input_container">
          <label>Email</label>
          <input
            type="text"
            autoComplete="username"
            {...register("email", {
              required: true,
            })}
          />
        </div>
        <div className="form__input_container">
          <label>Password</label>
          <input
            type="password"
            autoComplete="current-password"
            {...register("password", {
              required: true,
            })}
          />
          <div className="login__linkContainer">
            <Link
              to={"/terms-and-conditions"}
              className="login__link"
              id="second"
            >
              Terms and conditions
            </Link>
            <Link
              to={"/privacy-policy"}
              className="login__link"
              id="second"
            >
              Privacy policy
            </Link>
          </div>
        </div>
      </section>
      <section className="form_login__buttons">
        <button
          className="action-button action-button--secondary"
          type="submit"
        >
          <p>Login</p>
        </button>
        <button
          className="action-button action-button--primary"
          onClick={() => navigate("register")}
        >
          <p>Register</p>
        </button>
      </section>
    </form>
  );
}

export default LoginForm;
