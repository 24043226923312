import { useEffect, useState } from "react";
import CheckIcon from "../assets/svg/NavIcons/CheckIcon";

const NewStepProcessBar = ({ stepsNumber, actualStep }) => {
  const [dots, setDots] = useState([]);

  useEffect(() => {
    let tempDots = [];

    for (let stepIndex = 1; stepIndex <= stepsNumber; stepIndex++) {
      tempDots.push(
        <div
          key={stepIndex}
          className="stepProcessBar__dot-container"
        >
          <span
            className={`stepProcessBar__dot ${
              stepIndex === actualStep ? "active" : actualStep > stepIndex ? "done" : ""
            }`}
          >
            {actualStep > stepIndex ? <CheckIcon /> : stepIndex}
          </span>
        </div>
      );
    }

    setDots(tempDots);
  }, [actualStep]);

  return <div className="stepProcessBar">{dots}</div>;
};

export default NewStepProcessBar;
