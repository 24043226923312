import React from 'react'

const ProfilePictureDemo1 = () => {
  return (
    <svg
      width="200"
      height="251"
      viewBox="0 0 200 251"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M170.276 0.00756836H29.6714C13.2843 0.00756836 0 13.1849 0 29.4398V221.443C0 237.698 13.2843 250.876 29.6714 250.876H170.276C186.663 250.876 199.948 237.698 199.948 221.443V29.4398C199.948 13.1849 186.663 0.00756836 170.276 0.00756836Z"
        fill="white"
      />
      <path
        d="M29.6711 250.868H167.446C142.303 226.88 106.853 211.922 67.5661 211.922C43.296 211.922 20.4882 217.63 0.667969 227.644C3.54548 240.917 15.4329 250.861 29.6711 250.861V250.868Z"
        fill="#959595"
      />
      <path
        d="M100.014 227.301C78.7162 233.142 56.4037 233.142 35.1133 227.301V185.968H100.014V227.301Z"
        fill="#B1B1B1"
      />
      <path
        d="M0 29.4398V156.847C16.0464 181.639 40.348 197.447 67.5664 197.447C115.879 197.447 155.04 147.629 155.04 86.1753C155.04 51.4321 142.523 20.4167 122.915 0.0153809H29.6792C13.2947 0.0153809 0.00786373 13.1951 0.00786373 29.4476L0 29.4398Z"
        fill="#D9D9D9"
      />
      <path
        d="M0 29.44V68.2697C2.97185 63.7308 5.94371 61.2586 8.56178 62.795C18.8532 68.8311 39.837 86.1676 61.2218 86.1676C78.6834 86.1676 101.554 61.8357 109.361 52.8984C111.13 50.8708 114.07 50.2859 116.476 51.5025C125.659 56.1661 146.604 71.1474 152.555 112.543C158.672 99.8699 162.108 85.6295 162.108 70.5781C162.108 42.6354 150.267 17.4924 131.398 0H29.6714C13.2869 0 0 13.1798 0 29.4322V29.44Z"
        fill="#959595"
      />
    </svg>
  );
}

export default ProfilePictureDemo1