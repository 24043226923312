import { useState, useEffect } from "react";
import { useReadUsers } from "../../../hooks/useUsers";

import Search from "../../../components/search";
import ManagerCard from "../../../components/informativeCards/managerCard";
import AddButton from "../../../components/addButton";
import Paginator from "../../../components/paginator/paginator";
import Header from "../../../components/Header";
import ManagerTable from "../../../components/tables/ManagerTable";

const MAX_RESULTS = 10;
const BUTTON_NAME = "Añadir Administrador";
const BUTTON_ROUTE = "add";

function Manager() {
  const { users, getUsers } = useReadUsers();

  const [userSelected, setUserSelected] = useState(null);
  const [search, setSearch] = useState("");

  function handleSearchUser(limit, page) {
    getUsers("Admin", limit, page, "Super", search);
  }

  useEffect(() => {
    getUsers("Admin", MAX_RESULTS, 1, "Super", search);
  }, [search]);

  return (
    <section className="cardLayout">
      <div className="cardLayout__title">
        <Header name="Administradores" />
      </div>
      <section className="cardLayout__container">
        <div className="cardLayout__column">
          <div className="cardLayout__subtitleWithButton">
            <h2>Añada, consulte o elimine un administrador</h2>
            <AddButton
              name={BUTTON_NAME}
              route={BUTTON_ROUTE}
              className="add-button"
            />
          </div>
          <Search
            search={setSearch}
            calendar={false}
            typeOfSearch={"apellido"}
          />
          <div className="cardLayout__content">
            <ManagerTable selected={setUserSelected} data={users?.data} />
          </div>
          {users && (
            <Paginator
              maxPages={users.totalPages}
              search={handleSearchUser}
              resultsShown={MAX_RESULTS}
              maxResults={users.count}
              page={users.page}
            />
          )}
        </div>
        <div className="cardLayout__card">
          {userSelected && (
            <ManagerCard
              admin={userSelected}
              setClose={setUserSelected}
              isDescriptive={true}
            />
          )}
        </div>
      </section>
    </section>
  );
}

export default Manager;
