import { useRef, useEffect } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken = 'pk.eyJ1Ijoicm9ja2V0ZW5naW5lZXIiLCJhIjoiY2xqZDQ3dXYwMXp1NTNkbWhsZHhxcmxobSJ9.aBB2AtB0TUv19-sSCJkThw';

function ClickableMap({ pointLocation, setPointLocation, isTouchable }) {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const marker = useRef(null);
    const longitude = -85.259352;
    const latitude = 10.35174;
    const zoom = 8;

    useEffect(() => {
        if (map.current) return;

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [longitude, latitude],
            zoom: zoom
        });
    }, [longitude, latitude, zoom]);

    useEffect(() => {
        if (!map.current) return;

        map.current.on('click', function (e) {
            if (isTouchable) {
                let newCoordinates = { longitude: e.lngLat.lng, latitude: e.lngLat.lat }
                setPointLocation(newCoordinates);
                if (marker.current) marker.current.remove();
                let coordinates = e.lngLat;
                let el = document.createElement('div');
                el.className = 'marker';
                marker.current = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(map.current);
            }
        });
    }, [setPointLocation]);

    useEffect(() => {
        if (pointLocation) {
            if (marker.current) marker.current.remove();
            let coordinates = { lat: pointLocation.latitude, lng: pointLocation.longitude };
            let el = document.createElement('div');
            el.className = 'marker';
            marker.current = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(map.current);
        }
    }, [pointLocation])

    return (
        <div className="map">
            <div ref={mapContainer} className='map-container'></div>
        </div>
    );
}

export default ClickableMap;