import BarChar from "../barChart";
import CarIcon from "../../assets/svg/CarIcon";
import TimeIcon from "../../assets/svg/TimeIcon";

import NoUserImage from "../../assets/icons/user.png";
import { gendersEs, transformToStringGender } from "../../models/genders";
import { languagesEs, transformToStringLanguages } from "../../models/languages";
import HistoryTable from "../tables/HistoryTable";
import { useReadRides, useReadRideStats } from "../../hooks/useRides";
import { useEffect } from "react";

const AboutTouristInfo = ({ tourist, driverTenure, totalTrips, stats, setSelectedDay }) => {
  const { rides, getRidesByUser } = useReadRideStats()

  useEffect(() => {
    if (tourist) {
      getRidesByUser(tourist._id)
    }
    console.log(rides)
  }, [tourist])
  return (
    <div className="aboutInfo">
      <div className="aboutInfo__profile">
        <div className="aboutInfo__profileName">
          <div className="aboutInfo__image">
            <img
              src={NoUserImage}
              alt="Client"
            />
          </div>
          <h2>
            {tourist?.name} {tourist?.lastName}
          </h2>
        </div>
        <div className="aboutInfo__profileContent">
          <p className="aboutInfo__profileField">
            Correo
            <span>{tourist?.email}</span>
          </p>
          <p className="aboutInfo__profileField">
            Género
            <span>{transformToStringGender(tourist?.gender, gendersEs)}</span>
          </p>
          <p className="aboutInfo__profileField">
            Número de teléfono
            <span>{tourist?.phone}</span>
          </p>
          <p className="aboutInfo__profileField">
            Idiomas
            <span>{tourist && transformToStringLanguages(tourist?.languages, languagesEs)}</span>
          </p>
        </div>
      </div>

      {/* <section className="aboutInfo__stats">
        <div className="aboutInfo__column">
          <CarIcon />
          {totalTrips?.totalTrips && <h2>{totalTrips?.totalTrips}</h2>}
          <h2>2 Viajes</h2>
          <p>Realizados en total</p>
        </div>
        <div className="aboutInfo__column">
          <TimeIcon />
          {driverTenure?.unit && <h2> {`${driverTenure?.tenure} ${driverTenure?.unit}`} </h2>}
          <h2>2 Años</h2>
          <p>En la plataforma</p>
        </div>
      </section>
      <BarChar
        stats={stats}
        setSelectedDay={setSelectedDay}
      /> */}

      {rides && <HistoryTable data={rides.data} />}
    </div>
  );
};

export default AboutTouristInfo;
