import { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateUsers, useUpdateUsers } from "../../../hooks/useUsers";
import {
  createUserStates,
  FormEvents,
  FormState,
} from "../../../states/createUser";

import NewStepProcessBar from "../../../components/newStepProcessBar";
import Overlays from "../../../components/modals/overlays";
import PopUp from "../../../components/modals/popUp";
import AddManagerInformation from "../../../components/forms/AddManager/AddManagerInformation";
import AddManagerPicture from "../../../components/forms/AddManager/AddManagerPicture";
import LazyLoading from "../../../components/LazyLoading";
import Header from "../../../components/Header";
import GoBackButton from "../../../components/GoBackButton";

function AddManager() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(null);
  const [validInformation, setValidInformation] = useState({
    isDirty: false,
    isValid: false,
  });
  const [popUp, setPopUp] = useState({
    informative: false,
    warning: false,
    positive: false,
  });

  const [state, dispatch] = useReducer(
    createUserStates,
    FormState.UserInformation
  );

  const { user, createUserError, createUser } = useCreateUsers();
  const { profilePicture, updateUserError, uploadProfilePicture } =
    useUpdateUsers();

  const updateState = () => {
    if (state === FormState.UserInformation) {
      dispatch(FormEvents.CreateUser);
    } else if (state === FormState.UserImages) {
      dispatch(FormEvents.UploadImages);
    }
  };

  useEffect(() => {
    if (state === FormState.CreatingUser) {
      setPopUp({ ...popUp, informative: false });
      createUser(formData);
    } else if (state === FormState.UploadingImages) {
      setPopUp({ ...popUp, informative: false });
      uploadProfilePicture(formData, user.id);
    } else if (state === FormState.Completed) {
      setPopUp({ ...popUp, positive: true });
    }
  }, [state, formData]);

  useEffect(() => {
    if (updateUserError) {
      dispatch(FormEvents.UploadImagesFail);
    }
  }, [updateUserError]);

  useEffect(() => {
    if (createUserError) {
      dispatch(FormEvents.CreateUserFail);
    }
  }, [createUserError]);

  useEffect(() => {
    if (profilePicture) {
      dispatch(FormEvents.UploadImagesSuccess);
      setPopUp({ ...popUp, positive: true });
    }
  }, [profilePicture]);

  useEffect(() => {
    if (user) {
      dispatch(FormEvents.CreateUserSuccess);
    }
  }, [user]);

  return (
    <section className="formLayout">
      <div className="formLayout__title">
        <Header name="Administradores" />
      </div>
      <div className="formLayout__container">
        <div className="formLayout__subtitle">
          <GoBackButton
            onClick={() => {
              setPopUp({ ...popUp, warning: true });
            }}
            title="Complete la Información del Administrador"
            subtitle="Rellene la información del nuevo administrador según se le solicita"
          />
          <NewStepProcessBar
            stepsNumber={FormState.Completed}
            actualStep={state + 1}
          />
          <p className="form__steps">
            <strong>Paso {state + 1}</strong> de {FormState.Completed + 1}
          </p>
        </div>
        <div className="formLayout__form">
          <AddManagerInformation
            state={state}
            formState={FormState}
            createUser={setFormData}
            validUser={setValidInformation}
          />
          <AddManagerPicture
            state={state}
            formState={FormState}
            uploadPictures={setFormData}
            validImages={setValidInformation}
          />
          {(state === FormState.CreatingUser ||
            state === FormState.UploadingImages) && <LazyLoading />}
        </div>
        {state !== FormState.CreatingUser &&
          state !== FormState.UploadingImages &&
          state !== FormState.Completed && (
            <div className="formLayout__buttons">
              <button
                className="action-button action-button--primary"
                form={
                  state === FormState.UserInformation
                    ? "manager-information"
                    : "manager-picture"
                }
                onClick={() => setPopUp({ ...popUp, informative: true })}
                disabled={
                  !validInformation.isDirty || !validInformation.isValid
                }
              >
                Continuar
              </button>
            </div>
          )}
      </div>
      <Overlays>
        {popUp.warning && (
          <PopUp
            type={"warning"}
            title={"¿Desea salir?"}
            subtitle={
              "Si sales ahora, perderás todo avance e información que has escrito hasta el momento."
            }
          >
            <button
              className="alert-button alert-button--secondary"
              onClick={() => navigate(-1)}
            >
              Sí
            </button>
            <button
              className="alert-button alert-button--primary"
              onClick={() => setPopUp({ ...popUp, warning: false })}
            >
              No
            </button>
          </PopUp>
        )}
        {popUp.informative && (
          <PopUp
            type={"informative"}
            title={"¿Está todo listo?"}
            subtitle={`Al presionar "Sí" se creará el nuevo perfil del administrador`}
          >
            <button
              className="action-button action-button--secondary"
              onClick={() => updateState()}
            >
              Sí
            </button>
            <button
              className="action-button action-button--primary"
              onClick={() => setPopUp({ ...popUp, informative: false })}
            >
              No
            </button>
          </PopUp>
        )}
        {popUp.positive && (
          <PopUp
            type={"positive"}
            title={"¡El administrador se ha creado correctamente!"}
            subtitle={`Al presionar "Aceptar" volverá a la vista principal`}
          >
            <button
              className="action-button action-button--primary"
              onClick={() => navigate(-1)}
            >
              Sí
            </button>
          </PopUp>
        )}
      </Overlays>
    </section>
  );
}

export default AddManager;
