import { useNavigate } from "react-router-dom";
import VanLogo from "../../assets/images/van-logo.png";

const VehicleTable = ({ selected, data, toAddReservation }) => {
  const navigate = useNavigate();

  const isMobileView = window.innerWidth <= 810;

  const handleCard = (user) => {
    if (isMobileView && !toAddReservation) {
      navigate(user?._id);
    } else {
      selected(user);
    }
  };

  return (
    <section className="dataTable">
      <section className="dataTable__header four-columns">
        <p>Modelo</p>
        <p>Capacidad</p>
        <p>Placa</p>
        <p>Estado</p>
      </section>
      <section className="dataTable__body">
        {data?.map((vehicle, i) => (
          <div
            key={vehicle._id}
            className="dataTable__row"
            onClick={() => handleCard(vehicle)}
          >
            <img
            className="vanLogo"
              src={VanLogo}
              alt=""
            />
            <div className="dataTable__rowItems four-columns">
              <p>{vehicle.model}</p>
              <p>{vehicle.passengerCapacity} pasajeros</p>
              <p>{vehicle.plate}</p>
              <p>{vehicle.status}</p>
            </div>
            <div className="dataTable__rowItems--mobile">
              <p>{vehicle.model}</p>
              <p>{vehicle.passengerCapacity} pasajeros</p>
              <p>{vehicle.plate}</p>
            </div>
            <div className="dataTable__arrow">
              <svg
                width="18"
                height="25"
                viewBox="0 0 10 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.70557 15.4904L8.58643 8.60959L1.70557 1.72873"
                  stroke="#959595"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ))}
      </section>
    </section>
  );
};

export default VehicleTable;
