import React from 'react'

const LicenseImage = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="318"
      height="219"
      viewBox="0 0 318 219"
      fill="none"
    >
      <g filter="url(#filter0_d_1516_6129)">
        <path
          d="M278.923 15H39.903C26.6829 15 15.9658 25.717 15.9658 38.9372V179.991C15.9658 193.212 26.6829 203.929 39.903 203.929H278.923C292.143 203.929 302.86 193.212 302.86 179.991V38.9372C302.86 25.717 292.143 15 278.923 15Z"
          fill="white"
        />
        <path
          d="M39.903 15H278.919C292.131 15 302.857 25.7254 302.857 38.9372V63.6133H15.9658V38.9372C15.9658 25.7254 26.6912 15 39.903 15Z"
          fill="#B1B1B1"
        />
        <path
          d="M107.116 75.4011H50.4C43.79 75.4011 38.4315 80.7596 38.4315 87.3697V165.448C38.4315 172.058 43.79 177.416 50.4 177.416H107.116C113.726 177.416 119.085 172.058 119.085 165.448V87.3697C119.085 80.7596 113.726 75.4011 107.116 75.4011Z"
          fill="#F1F1F1"
        />
        <path
          d="M271.185 75.4011H141.918V93.1606H271.185V75.4011Z"
          fill="#B1B1B1"
        />
        <path
          d="M271.185 137.844H141.918V146.109H271.185V137.844Z"
          fill="#F1F1F1"
        />
        <path
          d="M271.185 153.311H141.918V161.576H271.185V153.311Z"
          fill="#F1F1F1"
        />
        <path
          d="M177.069 168.781H141.918V177.045H177.069V168.781Z"
          fill="#F1F1F1"
        />
        <path
          d="M201.457 97.334H141.918V115.093H201.457V97.334Z"
          fill="#B1B1B1"
        />
        <path
          d="M50.4 177.413H107.116C110.005 177.413 112.656 176.389 114.724 174.683C109.345 161.23 95.2743 151.63 78.758 151.63C62.2417 151.63 48.1706 161.23 42.792 174.683C44.8597 176.389 47.5109 177.413 50.4 177.413Z"
          fill="#959595"
        />
        <path
          d="M87.7614 155.934C81.8532 157.567 75.6628 157.567 69.7578 155.934V144.374H87.7614V155.934Z"
          fill="#B1B1B1"
        />
        <path
          d="M78.7581 147.584C92.1604 147.584 103.025 133.651 103.025 116.463C103.025 99.2763 92.1604 85.3433 78.7581 85.3433C65.3558 85.3433 54.4911 99.2763 54.4911 116.463C54.4911 133.651 65.3558 147.584 78.7581 147.584Z"
          fill="#D9D9D9"
        />
        <path
          d="M55.1792 123.843C55.1792 123.843 59.5334 108.237 62.3876 109.927C65.2418 111.618 71.0612 116.463 76.9947 116.463C81.8405 116.463 88.1832 109.658 90.3492 107.159C90.8407 106.591 91.6558 106.426 92.3249 106.769C94.8715 108.072 100.681 112.261 102.334 123.84C104.03 120.298 104.985 116.314 104.985 112.103C104.985 97.3214 93.2414 85.3401 78.7548 85.3401C64.2682 85.3401 52.5248 97.3214 52.5248 112.103C52.5248 116.314 53.4793 120.298 55.176 123.84"
          fill="#959595"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1516_6129"
          x="0.96582"
          y="0"
          width="316.894"
          height="218.929"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1516_6129"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1516_6129"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default LicenseImage