import { languagesEs, transformToStringLanguages } from "../../models/languages";

import NoUserImage from "../../assets/icons/user.png";

const ReservationUser = ({ booking }) => {
  return (
    <section className="reservationProfile">
      <h3>Persona que solicitó la reservación</h3>
      <div className="reservationProfile__container">
        <img
          className="reservationProfile__userImage"
          src={booking?.clientInfo?.profilePicture ? booking.clientInfo.profilePicture : NoUserImage}
          alt="Imagen del cliente"
        />
        <div className="reservationProfile__info">
          <div className="reservationProfile__twoColumns">
            <p className="reservationProfile__field">
              Nombre Completo
              <span>{booking?.clientInfo?.name ? booking?.clientInfo?.name : "No registrado"}</span>
            </p>
            <p className="reservationProfile__field">
              Teléfono
              <span>{booking?.clientInfo?.phone ? booking?.clientInfo?.phone : "No registrado"}</span>
            </p>
          </div>
          <p className="reservationProfile__field">
            Idiomas
            <span>{booking?.clientInfo?.languages ? transformToStringLanguages(booking?.clientInfo?.languages, languagesEs) : "No registrado"}</span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default ReservationUser;
