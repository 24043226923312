import React, { useRef, useEffect, useState } from "react";
import { useReadMap } from "../hooks/useMaps";

import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken =
  "pk.eyJ1Ijoicm9ja2V0ZW5naW5lZXIiLCJhIjoiY2xqZDQ3dXYwMXp1NTNkbWhsZHhxcmxobSJ9.aBB2AtB0TUv19-sSCJkThw";

function MapBoxRoutes({ ride }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const {
    route,
    initialCoordinates,
    endCoordinates,
    getRoute,
    getPlaceCoordinates,
  } = useReadMap();

  const [coordinate, setCoordinate] = useState([-84.079578, 9.932543]);
  const [zoom, setZoom] = useState(6.5);

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: coordinate,
      zoom: zoom,
    });

  }, [coordinate, zoom]);

  useEffect(() => {
    if (ride) {
      try {
        getPlaceCoordinates(ride?.bookings[0]?.ticket?.pickUpLocation, true);
        getPlaceCoordinates(ride?.bookings[0]?.ticket?.dropOffLocation, false);
      } catch (error) {
        getPlaceCoordinates(ride?.ticket?.pickUpLocation, true);
        getPlaceCoordinates(ride?.ticket?.dropOffLocation, false);
      }
    }
  }, [ride]);

  useEffect(() => {
    if (initialCoordinates && endCoordinates) {
      getRoute(initialCoordinates, endCoordinates);
    }
  }, [initialCoordinates, endCoordinates]);

  useEffect(() => {
    if (route && map.current) {
      if (map.current.getSource("route")) {
        map.current.getSource("route").setData(route);
      } else {
        map.current.addLayer({
          id: "route",
          type: "line",
          source: {
            type: "geojson",
            data: route,
          },
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#0971AB",
            "line-width": 5,
            "line-opacity": 0.75,
          },
        });
        setCoordinate(route.coordinates[0]);
      }
    }
  }, [route]);

  return (
    <div className="map">
      <div ref={mapContainer} className="map-container"></div>
    </div>
  );
}

export default MapBoxRoutes;
