import { useReadRideStats } from "../hooks/useRideStats";

import CarIcon from "../assets/svg/CarIcon";
import TimeIcon from "../assets/svg/TimeIcon";
import { useEffect } from "react";

function TripStats({ driverId }) {
  const { totalTrips, driverTenure, getTotalTrips, getDriverTenure } = useReadRideStats();

  useEffect(() => {
    getTotalTrips(driverId);
    getDriverTenure(driverId);
  }, [driverId])

  return (
    <section className="tripStats_container">
      <div className="tripStats_column">
        <CarIcon />
        {totalTrips && <h2>{totalTrips}</h2>}
        <p>Viajes Realizados</p>
      </div>
      <div className="tripStats_column">
        <TimeIcon />
        {driverTenure && <h2> {`${driverTenure?.tenure} ${driverTenure?.unit}`} </h2>}
        <p>Tiempo Trabajado</p>
      </div>
    </section>
  );
}

export default TripStats;
