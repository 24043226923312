import { useEffect, useState } from "react";
import { useReadRideStats } from "../../../hooks/useRides";
import TripsTable from "../../../components/tables/TripsTable";
import { Link } from "react-router-dom";
import InformativeIcon from "../../../assets/svg/InformativeIcon";
import Header from "../../../components/Header";
import { useAuth } from "../../../hooks/useAuth";
import LazyLoading from "../../../components/LazyLoading";
import Paginator from "../../../components/paginator/paginator";
import TripCard from "../../../components/informativeCards/tripCard";

const MAX_RESULTS = 10;

function UserReservations() {
  const [category, setCategory] = useState("Pending");
  const [tripSelected, setTripSelected] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const { rides, getPendingRidesByUser, getRidesByUser } = useReadRideStats();
  const { auth } = useAuth();

  const fetchRides = async (page) => {
    setIsLoading(true);
    await getRidesByUser(auth.user, category, page);
    setIsLoading(false);
  };

  useEffect(() => {
    if (category === 'Pending') {
      getPendingRidesByUser(auth.user, 1);
      setIsLoading(false)
    }

    if (category === 'Completed') {
      getRidesByUser(auth.user, 'Completed', 1)
    }
  }, [category]);

  return (
    <section className="cardLayout">
      <section className="cardLayout__title">
        <Header name="My Rides" />
      </section>
      <section className="cardLayout__container">
        <div className="cardLayout__column">
          <div className="cardLayout__categories">
            <button
              className={`category-button ${category === "Pending" ? "category-button--active" : ""}`}
              onClick={() => setCategory("Pending")}
            >
              Upcoming Trips
            </button>
            <button
              className={`category-button ${category === "Completed" ? "category-button--active" : ""}`}
              onClick={() => setCategory("Completed")}
            >
              Previous Trips
            </button>
          </div>
          <h2 className="cardLayout__subtitle">Check your upcoming or previous trips</h2>
          <section className="cardLayout__content">
            {!isLoading && rides && rides.data.length > 0 && (
              <TripsTable
                selected={setTripSelected}
                data={rides.data}
              />
            )}
            {isLoading && <LazyLoading />}
            {!isLoading && (!rides || rides?.data.length === 0) && (
              <div className="informativeContainer">
                <div>
                  <InformativeIcon />
                </div>
                <h2>You don't have any {category === "Pending" ? "upcoming" : "previous"} trip!</h2>
                <p>
                  To schedule a trip, log in to our online platform. You can access with a button or continue navigating
                  in the app.
                </p>
                <Link
                  className="action-button action-button--primary"
                  to="https://reservations.nosara.com/"
                >
                  Book on the web
                </Link>
              </div>
            )}
          </section>
          {rides?.totalPages > 1 && (
            <Paginator
              maxPages={rides.totalPages}
              search={fetchRides}
              resultsShown={MAX_RESULTS}
              maxResults={rides.count}
              page={rides.page}
            />
          )}
        </div>
        <section className="cardLayout__card">
          {tripSelected && (
            <TripCard
              onClose={() => setTripSelected(null)}
              data={tripSelected}
            />
          )}
        </section>
      </section>
    </section>
  );
}

export default UserReservations;
