import { useState } from "react";
import PopUp from "./modals/popUp";
import EditArticle from "./forms/EditArticle/EditArticle";
import Overlays from "./modals/overlays";

import ImageNotFound from "../assets/images/ImageNotFound.png"

const Card = ({ type, data, deleteCard, getArticles }) => {
  const [popUp, setPopUp] = useState({
    edit: false,
    warning: false,
    informative: false,
  });

  const handleEditPopup = () => {
    setPopUp((prevState) => ({
      ...prevState,
      edit: false,
    }));
    getArticles();
  };

  return (
    <div className="card">
      <div className="card__container">
        <div className="card__header">
          <img src={data.imageUrl ? data.imageUrl : ImageNotFound} alt={type === "cover" ? data.caption : data.title} />
        </div>
        <div className="card__body">
          <h2 className="card__title">{type === "cover" ? data.caption : data.title}</h2>
          {data?.description && <p className="card__description">{data.description}</p>}
          {data?.contentUrl && <a className="card__link" href={data.contentUrl}>{data.contentUrl}</a>}
        </div>
        <div className="card__footer">
          <div className={type === "cover" ? "" : "two-columns"}>
            {type !== "cover" && (
              <button
                className={"action-button action-button--primary"}
                onClick={() => setPopUp({ ...popUp, edit: true, })}
              >
                <p>Editar</p>
              </button>
            )}
            <button
              onClick={() => setPopUp({ ...popUp, warning: true, })}
              className={`action-button action-button--${type === "cover" ? "primary" : "secondary"}`}
            >
              <p>Eliminar</p>
            </button>
          </div>
        </div>
      </div>

      <Overlays>
        {popUp.edit && (
          <EditArticle
            data={data}
            close={handleEditPopup}
          />
        )}
        {popUp.warning && (
          <PopUp
            type={"warning"}
            title={"¿Desea eliminarlo?"}
            subtitle={"Esta acción es irreversible"}
          >
            <button
              className="alert-button alert-button--secondary"
              onClick={() => { setPopUp({ ...popUp, warning: false, }); deleteCard(data._id) }}
            >
              Sí
            </button>
            <button
              className="alert-button alert-button--primary"
              onClick={() => setPopUp({ ...popUp, warning: false, })}
            >
              No
            </button>
          </PopUp>
        )}
      </Overlays>

    </div>
  );
};

export default Card;
