import { useRef, useEffect } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";

import { useReadMap } from "../../hooks/useMaps";

mapboxgl.accessToken = 'pk.eyJ1Ijoicm9ja2V0ZW5naW5lZXIiLCJhIjoiY2xqZDQ3dXYwMXp1NTNkbWhsZHhxcmxobSJ9.aBB2AtB0TUv19-sSCJkThw';

function RouteMap({ routeCoordinates, isLocked }) {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const isMapLoaded = useRef(false);

    const markers = useRef([]);

    const { route, getRoute } = useReadMap();

    useEffect(() => {
        if (map.current) return;
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [-85.5, 10.3],
            zoom: 7
        });

        map.current.on('styledata', () => {
            isMapLoaded.current = true;
        });

        return () => {
            if (map.current) {
                map.current.remove();
                map.current = null;
            }
        };
    }, []);


    useEffect(() => {
        if (map.current && routeCoordinates && isMapLoaded.current) {

            const paintPoint = (point) => {
                const markerElement = document.createElement('div');
                markerElement.className = 'marker-route';

                const marker = new mapboxgl.Marker(markerElement)
                    .setLngLat([point[0], point[1]])
                    .addTo(map.current);

                markers.current.push(marker);
            }

            paintPoint(routeCoordinates.start)
            paintPoint(routeCoordinates.end)
        }
    }, [routeCoordinates]);

    useEffect(() => {
        if (routeCoordinates) {
            getRoute(routeCoordinates.start, routeCoordinates.end);
        }
    }, [routeCoordinates]);

    useEffect(() => {
        if (route && map.current && isMapLoaded.current) {
            if (map.current.getSource("route")) {
                map.current.getSource("route").setData(route);
            } else {
                map.current.addLayer({
                    id: "route",
                    type: "line",
                    source: {
                        type: "geojson",
                        data: route,
                    },
                    layout: {
                        "line-join": "round",
                        "line-cap": "round",
                    },
                    paint: {
                        "line-color": "#0971AB",
                        "line-width": 4,
                        "line-opacity": 1,
                    },
                });
            }
        }
    }, [route]);

    return (
        <div className={`map ${isLocked ? "locked" : ""}`}>
            <div ref={mapContainer} className='map-container'></div>
        </div>
    );
}

export default RouteMap;