import React from 'react'

const ProfilePicture = () => {
  return (
    <svg
      width="368"
      height="368"
      viewBox="0 0 368 368"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1646_1638"
        style={{maskType: "alpha"}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="368"
        height="368"
      >
        <circle
          cx="184"
          cy="184.356"
          r="183.598"
          fill="#F1F1F1"
        />
      </mask>
      <g mask="url(#mask0_1646_1638)">
        <circle
          cx="183.999"
          cy="177.356"
          r="190.599"
          fill="#D9D9D9"
          fill-opacity="0.5"
        />
        <path
          d="M87.4947 367.955H280.507C290.339 367.955 299.362 364.497 306.398 358.738C288.094 313.325 240.208 280.919 184.001 280.919C127.794 280.919 79.9075 313.325 61.6035 358.738C68.6402 364.497 77.6627 367.955 87.4947 367.955Z"
          fill="#959595"
        />
        <path
          d="M214.636 295.446C194.53 300.959 173.463 300.959 153.367 295.446V256.424H214.636V295.446Z"
          fill="#B1B1B1"
        />
        <path
          d="M183.997 267.259C229.607 267.259 266.581 220.225 266.581 162.206C266.581 104.187 229.607 57.1533 183.997 57.1533C138.387 57.1533 101.413 104.187 101.413 162.206C101.413 220.225 138.387 267.259 183.997 267.259Z"
          fill="#D9D9D9"
        />
        <path
          d="M103.752 187.117C103.752 187.117 118.57 134.435 128.283 140.141C137.997 145.847 157.801 162.205 177.993 162.205C194.484 162.205 216.069 139.231 223.441 130.795C225.113 128.879 227.887 128.322 230.164 129.479C238.831 133.879 258.602 148.021 264.225 187.106C269.999 175.148 273.248 161.702 273.248 147.485C273.248 97.587 233.283 57.1416 183.983 57.1416C134.683 57.1416 94.7188 97.587 94.7188 147.485C94.7188 161.702 97.9673 175.148 103.741 187.106"
          fill="#959595"
        />
      </g>
    </svg>
  );
}

export default ProfilePicture