import { useState, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateContent, useUpdateContent } from "../../../hooks/useContent";
import {
  createUserStates,
  FormEvents,
  FormState,
} from "../../../states/createUser";

import NewStepProcessBar from "../../../components/newStepProcessBar";
import Overlays from "../../../components/modals/overlays";
import PopUp from "../../../components/modals/popUp";
import AddArticleInformation from "../../../components/forms/AddArticle/AddArticleInformation";
import LazyLoading from "../../../components/LazyLoading";
import AddArticlePicture from "../../../components/forms/AddArticle/AddArticlePicture";
import Header from "../../../components/Header";
import GoBackButton from "../../../components/GoBackButton";

function AddArticle() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(null);
  const [validInformation, setValidInformation] = useState({
    isDirty: false,
    isValid: false,
  });
  const [popUp, setPopUp] = useState({
    informative: false,
    warning: false,
    positive: false,
  });

  const { content, createArticle } = useCreateContent();
  const { contentImage, updateContentError, uploadArticleImage } =
    useUpdateContent();
  const [state, dispatch] = useReducer(
    createUserStates,
    FormState.UserInformation
  );

  const updateState = () => {
    if (state === FormState.UserInformation) {
      dispatch(FormEvents.CreateUser);
    } else if (state === FormState.UserImages) {
      dispatch(FormEvents.UploadImages);
    }
  };

  useEffect(() => {
    if (state === FormState.CreatingUser) {
      setPopUp({ ...popUp, informative: false });
      createArticle(formData, dispatch);
    } else if (state === FormState.UploadingImages) {
      setPopUp({ ...popUp, informative: false });
      uploadArticleImage(formData, content._id);
    } else if (state === FormState.Completed) {
      setPopUp({ ...popUp, positive: true });
    }
  }, [state, formData]);

  useEffect(() => {
    if (contentImage) {
      dispatch(FormEvents.UploadImagesSuccess);
    } else if (updateContentError) {
      dispatch(FormEvents.UploadImagesFail);
    }
  }, [contentImage, updateContentError]);

  return (
    <section className="formLayout">
      <div className="formLayout__title">
        <Header name="Contenido" />
      </div>
      <div className="formLayout__container">
        <div className="formLayout__subtitle">
          <GoBackButton
            onClick={() => {
              setPopUp({ ...popUp, warning: true });
            }}
            title="Complete la Información de la Noticia"
            subtitle="Rellene la información de la nueva noticia según se le solicita"
          />
          <NewStepProcessBar
            stepsNumber={FormState.Completed + 1}
            actualStep={state + 1}
          />
          <p className="form__steps">
            <strong>Paso {state + 1}</strong> de 5
          </p>
        </div>
        <div className="formLayout__content">
          <section
            className="formLayout__form"
            style={
              state === FormState.UserInformation ||
              state === FormState.UserImages
                ? {}
                : { display: "none" }
            }
          >
            <AddArticleInformation
              state={state}
              formState={FormState}
              createArticle={setFormData}
              validArticle={setValidInformation}
            />
            <AddArticlePicture
              state={state}
              formState={FormState}
              uploadPictures={setFormData}
              validImages={setValidInformation}
            />
          </section>
          {(state === FormState.CreatingUser ||
            state === FormState.UploadingImages) && <LazyLoading />}
        </div>
        <div className="formLayout__buttons">
          {(state === FormState.UserInformation ||
            state === FormState.UserImages) && (
            <button
              form={
                state === FormState.UserInformation
                  ? "article-information"
                  : "article-picture"
              }
              className="action-button action-button--primary"
              onClick={() => setPopUp({ ...popUp, informative: true })}
              disabled={!validInformation.isDirty || !validInformation.isValid}
            >
              Continuar
            </button>
          )}
        </div>
      </div>
      <Overlays>
        {popUp.warning && (
          <PopUp
            type={"warning"}
            title={"¿Desea salir?"}
            subtitle={
              "Si sales ahora, perderás todo avance e información que has escrito hasta el momento."
            }
          >
            <button
              className="alert-button alert-button--secondary"
              onClick={() => navigate(-1)}
            >
              Sí
            </button>
            <button
              className="alert-button alert-button--primary"
              onClick={() => setPopUp({ ...popUp, warning: false })}
            >
              No
            </button>
          </PopUp>
        )}
        {popUp.informative && (
          <PopUp
            type={"informative"}
            title={"¿Está todo listo?"}
            subtitle={
              state === FormState.UserInformation
                ? 'Al presionar "Sí" se creará una nueva noticia'
                : 'Al presionar "Sí" se cargará la imagen a la noticia'
            }
          >
            <button
              className="action-button action-button--secondary"
              onClick={() => updateState()}
            >
              Sí
            </button>
            <button
              className="action-button action-button--primary"
              onClick={() => setPopUp({ ...popUp, informative: false })}
            >
              No
            </button>
          </PopUp>
        )}
        {popUp.positive && (
          <PopUp
            type={"positive"}
            title={"¡Noticia creada con éxito!"}
            subtitle={'Al presionar "Aceptar" volverá a la página de inicio'}
          >
            <button
              className="action-button action-button--secondary"
              onClick={() => navigate(-1)}
            >
              Aceptar
            </button>
          </PopUp>
        )}
      </Overlays>
    </section>
  );
}

export default AddArticle;
