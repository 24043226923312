import { useState, useContext } from "react";
import AuthContext from "../context/AuthProvider";
import axios from "../api/nosaraShuttle";
import jwt_decode from "jwt-decode";

const API_KEY = process.env.REACT_APP_API_KEY;

export const useAuth = () => {
  return useContext(AuthContext);
}

export const useAuthLogin = () => {

  const [userAuthData, setUserAuthData] = useState(null);
  const [userAuthDataLoading, setUserAuthDataLoading] = useState(false);
  const [userAuthDataError, setUserAuthDataError] = useState(null);

  let isMounted = true;

  const login = async (user) => {
    setUserAuthDataLoading(true);
    setUserAuthDataError(null);

    try {
      const response = await axios.post(
        'auth/local/login',
        JSON.stringify(user),
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'apikey': API_KEY
          }
        }
      );
      if (isMounted) {
        const decodedToken = jwt_decode(response?.data?.access_token);
        const data = response?.data;
        setUserAuthData({ data, decodedToken })
      }
    } catch (error) {
      if (isMounted) {
        setUserAuthDataError(error);
        setUserAuthData(null);
      }
    } finally {
      isMounted && setUserAuthDataLoading(false);
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  const socialLogin = async (user) => {
    setUserAuthDataLoading(true);

    try {
      const response = await axios.post(
        'auth/social',
        JSON.stringify(user),
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'apikey': API_KEY
          }
        }
      );
      if (isMounted) {
        const decodedToken = jwt_decode(response?.data?.access_token);
        const data = response?.data;
        setUserAuthData({ data, decodedToken })
      }
    } catch (error) {
      if (isMounted) {
        setUserAuthDataError(error);
        setUserAuthData(null);
      }
    } finally {
      isMounted && setUserAuthDataLoading(false);
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  const signUp = async (user, imageData) => {
    setUserAuthDataLoading(true);
    setUserAuthDataError(null)

    try {
      const response = await axios.post(
        'auth/local/signup',
        JSON.stringify(user),
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'apikey': API_KEY
          }
        }
      );
      if (isMounted) {
        const decodedToken = jwt_decode(response?.data?.access_token);
        const data = response?.data;
        if (imageData) {
          await uploadImage(imageData, decodedToken.sub, data.access_token);
        }
        setUserAuthData({ data, decodedToken })
      }
    } catch (error) {
      if (isMounted) {
        setUserAuthDataError(error);
        setUserAuthData(null);
      }
    } finally {
      isMounted && setUserAuthDataLoading(false);
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  const uploadImage = async (imageData, userId, accessToken) => {
    const formData = new FormData();
    formData.append('image', imageData);

    try {
      await axios.patch(
        `user/${userId}/upload`,
        formData,
        {
          headers: {
            'apikey': API_KEY,
            'Authorization': `Bearer ${accessToken}`
          }
        }
      );
    } catch (error) {
      console.error(error);
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  return { userAuthData, userAuthDataLoading, userAuthDataError, login, signUp, socialLogin };
}