const rolesEs = [
    { label: "Administrador", value: "Admin" },
    { label: "Super Administrador", value: "Super" },
    { label: "Turista", value: "Tourist" },
    { label: "Conductor", value: "Driver" },
];

const rolesEn = [
    { label: "Manager", value: "Admin" },
    { label: "Super Manager", value: "Super" },
    { label: "Tourist", value: "Tourist" },
    { label: "Driver", value: "Driver" },
];

const adminForm = [
    { label: "Administrador", value: "Admin" },
    { label: "Super Administrador", value: "Super" },
]

const transformToStringRole = (value, genders) => {
    return genders.find(item => item.value === value)?.label;
}

export { adminForm, rolesEs, rolesEn, transformToStringRole };