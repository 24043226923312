
import VanImage from "../../assets/images/van-logo.png"

const ReservationVehicle = ({ vehicleSelected }) => {
  return (
    <section className="reservationProfile">
      <h3>Vehículo Asignado</h3>
      <div className="reservationProfile__container">
        <img
            className="reservationProfile__vehicleImage"
          src={VanImage}
          alt="Imagen del Vehículo"
        />
        <div className="reservationProfile__info">
          <div className="reservationProfile__twoColumns">
            <p className="reservationProfile__field">
              Placa
              <span>{vehicleSelected?.plate}</span>
            </p>
            <p className="reservationProfile__field">
              Capacidad
              <span>{vehicleSelected?.passengerCapacity}</span>
            </p>
          </div>
          <div className="reservationProfile__twoColumns">
            <p className="reservationProfile__field">
              Modelo
              <span>{vehicleSelected?.model}</span>
            </p>
            <p className="reservationProfile__field">
              Estado
              <span>{vehicleSelected?.status}</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReservationVehicle;
