const placesEs = [
  { label: "Transporte", value: "Transportation" },
  { label: "Real Estate", value: "Real Estate" },
  { label: "Negocios", value: "Business" },
];

const placesEn = [
  { label: "Ride Service", value: "Transportation" },
  { label: "Real Estate", value: "Real Estate" },
  { label: "Business", value: "Business" },
];

const transformToStringPlaces = (value, places) => {
  return places.find((item) => item.value === value)?.label;
};

export { placesEs, placesEn, transformToStringPlaces };
