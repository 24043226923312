import BarChar from "../barChart";
import CarIcon from "../../assets/svg/CarIcon";
import TimeIcon from "../../assets/svg/TimeIcon";

import VanImage from "../../assets/images/van-logo.png";
import HistoryTable from "../tables/HistoryTable";
import { tractions, transformToStringTractions } from "../../models/tractions";

const AboutVehicleInfo = ({ vehicle, driverTenure, totalTrips, stats, setSelectedDay }) => {
  return (
    <div className="aboutInfo">
      <div className="aboutInfo__profile aboutInfo__vehicle">
        <div className="aboutInfo__profileName">
          <div className="aboutInfo__image">
            <img
              src={VanImage}
              alt="Client"
            />
          </div>
          <h2>{vehicle?.plate || "Placa no disponible"}</h2>
        </div>
        <div className="aboutInfo__profileContent">
          <p className="aboutInfo__profileField">
            Modelo
            <span>{vehicle?.model}</span>
          </p>
          <p className="aboutInfo__profileField">
            Tracción
            <span>{vehicle && transformToStringTractions(vehicle.traction, tractions)}</span>
          </p>
          <p className="aboutInfo__profileField">
            Capacidad
            <span>{vehicle?.passengerCapacity}</span>
          </p>
          <p className="aboutInfo__profileField">
            Tablas de Surf
            <span>{vehicle?.surfboardCapacity}</span>
          </p>
          <p className="aboutInfo__profileField">
            Maletas
            <span>{vehicle?.trunkCapacity}</span>
          </p>
        </div>
      </div>

      {/* <section className="aboutInfo__stats">
        <div className="aboutInfo__column">
          <CarIcon />
          {totalTrips?.totalTrips && <h2>{totalTrips?.totalTrips}</h2>}
          <h2>2 Viajes</h2>
          <p>Realizados en total</p>
        </div>
        <div className="aboutInfo__column">
          <TimeIcon />
          {driverTenure?.unit && <h2> {`${driverTenure?.tenure} ${driverTenure?.unit}`} </h2>}
          <h2>2 Años</h2>
          <p>En la plataforma</p>
        </div>
      </section>
      <BarChar
        stats={stats}
        setSelectedDay={setSelectedDay}
      /> */}
      <div className="aboutInfo__table">
        <h2>Historial de viajes</h2>
        <HistoryTable data={null} />
      </div>
    </div>
  );
};

export default AboutVehicleInfo;
