import { useEffect, useState } from "react";
import Header from "../../../components/Header";
import { useReadDrivers } from "../../../hooks/useDrivers"
import { useReadRides } from "../../../hooks/useRides";
import TripsTable from "../../../components/tables/TripsTable";
import LazyLoading from "../../../components/LazyLoading";
import InformativeIcon from "../../../assets/svg/InformativeIcon";
import TripCard from "../../../components/informativeCards/tripCard";

function DriverTripsHistory() {
  const [driverId, setDriverId] = useState();
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [tripSelected, setTripSelected] = useState()
  const { driver, getDriverProfile } = useReadDrivers();
  const { rides, getCompletedRidesByDriver } = useReadRides();

  useEffect(() => {
    const fetchDriverProfile = async () => {
      await getDriverProfile();
    }
    fetchDriverProfile()
  }, [])

  useEffect(() => {
    if (driver) {
      setDriverId(driver._id)
    }
  }, [driver])

  useEffect(() => {
    const fetchNextRides = async () => {
      if (driverId) {
        await getCompletedRidesByDriver(driverId, page);
        setIsLoading(false)
      }
    }
    fetchNextRides();
  }, [driverId, page])


  return (
    <section className="cardLayout">
      <div className="cardLayout__title">
        <Header name="Mi historial de viajes" />
      </div>
      <div className="cardLayout__container">
        <div className="cardLayout__column">
          <h2 className="cardLayout__subtitle">Consulte los detalles de los viajes que ha realizado</h2>
          <section className={`cardLayout__content`}>
            {isLoading && <LazyLoading />}
            {/* {!isLoading && (rides && rides.data.length > 0) && <TripsTable data={rides.data} />} */}
            {!isLoading && rides.data.length > 0 && (
              <TripsTable
                driver
                selected={setTripSelected}
                data={rides.data}
              />
            )}
            {!isLoading && (!rides || rides.length === 0) && (
              <div className="informativeContainer">
                <div>
                  <InformativeIcon />
                </div>
                <h2>Aún no tienes viajes asignados</h2>
                <p>No tienes viajes asignados por ahora. Si tienes algún problema, contáctate con un administrador.</p>
              </div>
            )}
          </section>
        </div>
        <div className="cardLayout__card">
          {tripSelected && (
            <TripCard
              isDriver
              onClose={() => setTripSelected(null)}
              data={tripSelected}
            />
          )}
        </div>
      </div>
    </section>
  );
}

export default DriverTripsHistory;
