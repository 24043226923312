import { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";

import { useReadMap } from "../../hooks/useMaps";
import { io } from "socket.io-client";

mapboxgl.accessToken =
  "pk.eyJ1Ijoicm9ja2V0ZW5naW5lZXIiLCJhIjoiY2xqZDQ3dXYwMXp1NTNkbWhsZHhxcmxobSJ9.aBB2AtB0TUv19-sSCJkThw";

const socketMessage = io("https://nosara-shuttle-api.onrender.com/ride-sharing", { transports: ["websocket"] });

function StartTripTouristMap({ routeCoordinates, tripState, setTripState, mapRoom }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const isMapLoaded = useRef(false);

  const markers = useRef([]);

  const { route, getRoute } = useReadMap();
  const [newRouteCoordinates, setNewRouteCoordinates] = useState({});

  // Connects to websocket
  useEffect(() => {
    const updateCoordinates = (res) => {
      console.log(res)
      const driverCoordinates = [res.position.longitude, res.position.latitude];
      setTripState(res.rideStatus);

      if (tripState === "In Route" || tripState === "Driver Waiting") {
        setNewRouteCoordinates({
          start: driverCoordinates,
          end: [...routeCoordinates.start],
        });
      }

      if (tripState === "Trip In Progress") {
        setNewRouteCoordinates({
          start: driverCoordinates,
          end: [...routeCoordinates.end],
        });
      }
    };

    socketMessage.on("connect", () => {
      console.log("Connected to Trip Socket");
    });

    socketMessage.emit("event_join", mapRoom, () => {
      console.log("Joining to event");
    });

    socketMessage.on("driver_move", updateCoordinates);
  }, [routeCoordinates, mapRoom, tripState]);

  // Update route from server
  useEffect(() => {
    if (newRouteCoordinates) {
      getRoute(newRouteCoordinates.start, newRouteCoordinates.end);
    }
  }, [newRouteCoordinates]);

  // Creating the map
  useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [-85.2, 10.3],
      zoom: 8.9,
    });

    map.current.on("styledata", () => {
      isMapLoaded.current = true;
    });

    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, []);

  // Make and update the route
  useEffect(() => {
    if (route && map.current) {
      if (map.current.getSource("route")) {
        map.current.getSource("route").setData(route);
      } else {
        map.current.addSource("route", {
          type: "geojson",
          data: route,
        });

        map.current.addLayer({
          id: "route",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#0971AB",
            "line-width": 4,
            "line-opacity": 1,
          },
        });
      }
    }
  }, [route, newRouteCoordinates, tripState]);

  // Make the markers
  useEffect(() => {
    if (map.current && routeCoordinates && isMapLoaded.current) {
      markers.current.forEach((marker) => marker.remove());
      markers.current = [];

      const paintPoint = (point, className) => {
        const markerElement = document.createElement("div");

        markerElement.className = className;

        const marker = new mapboxgl.Marker(markerElement).setLngLat([point[0], point[1]]).addTo(map.current);

        markers.current.push(marker);
      };

      if (newRouteCoordinates) {
        paintPoint(newRouteCoordinates.start, "marker-driver");
        paintPoint(newRouteCoordinates.end, "marker-tourist");
      }
    }
  }, [routeCoordinates, newRouteCoordinates, tripState]);

  return (
    <div className={`map`}>
      <div
        ref={mapContainer}
        className="map-container"
      ></div>
    </div>
  );
}

export default StartTripTouristMap;
