import { Routes, Route, Navigate } from "react-router-dom";

import Layout from './components/layout';
import RequireAuth from './components/requireAuth';
import PersistLogin from "./components/persistLogin"

import Login from "./pages/home/Login";
import CompleteProfile from './pages/home/CompleteProfile';
import Unauthorize from './components/unauthorize';
import Terms from "./pages/home/Terms";

import Reservations from './pages/admin/reservations/Reservations';
import AddReservation from './pages/admin/reservations/AddReservation';
import EditReservation from "./pages/admin/reservations/EditReservation";

import Tourist from "./pages/admin/tourist/Tourist";
import AboutTourist from './pages/admin/tourist/AboutTourist';
import AddTourist from "./pages/admin/tourist/AddTourist";

import Manager from './pages/admin/manager/Manager';
import AboutManager from './pages/admin/manager/AboutManager';
import AddManager from './pages/admin/manager/AddManager';

import Points from './pages/admin/points/Points';
import AboutPoint from "./pages/admin/points/AboutPoint";
import AddPoints from './pages/admin/points/AddPoints';

import Driver from "./pages/admin/transport/Driver";
import AboutDriver from "./pages/admin/transport/AboutDriver";
import AddDriver from "./pages/admin/transport/AddDriver";

import Vehicles from "./pages/admin/transport/Vehicles";
import AboutVehicle from "./pages/admin/transport/AboutVehicle";
import AddVehicles from "./pages/admin/transport/AddVehicles";

import Cover from "./pages/admin/content/Cover";

import News from './pages/user/News';

import UserProfile from './pages/user/profile/UserProfile';

import UserReservations from './pages/user/reservations/UserReservations';
import UserReservationDetail from "./pages/user/reservations/UserReservationDetail";
import UserReservationRoute from "./pages/user/reservations/UserReservationRoute";

import DriverTrips from "./pages/driver/trips/DriverTrips";
import DriverTripRoute from "./pages/driver/trips/DriverTripRoute";

import DriverTripsHistory from "./pages/driver/history/DriverTripsHistory";

import DriverProfile from "./pages/driver/profile/DriverProfile";
import Articles from "./pages/admin/content/Articles";
import AddCover from "./pages/admin/content/AddCover";
import AddArticle from "./pages/admin/content/AddArticle";
import AboutReservation from "./pages/admin/reservations/AboutReservation";
import HistoryTrip from "./pages/driver/history/HistoryTrip";
import DriverStartTrip from "./pages/driver/trips/DriverStartTrip";
import PrivacyPolicy from "./pages/home/PrivacyPolicy";
import Delete from "./pages/home/Delete";

function App() {

  return (
    <Routes>
      <Route
        path="/"
        element={<Layout />}
      >
        <Route
          path="/"
          element={<Login />}
        ></Route>
        <Route
          path="unauthorized"
          element={<Unauthorize />}
        ></Route>
        <Route
          path="register"
          element={<CompleteProfile />}
        ></Route>
        <Route
          path="/terms-and-conditions"
          element={<Terms />}
        ></Route>
        <Route
          path="/privacy-policy"
          element={<PrivacyPolicy />}
        ></Route>
        <Route
          path="/delete"
          element={<Delete />}
        ></Route>

        <Route element={<PersistLogin />}>
          <Route path="/admin">
            <Route element={<RequireAuth allowedRoles={["Super", "Admin"]} />}>
              <Route
                path="reservations"
                element={<Reservations />}
              ></Route>
              <Route
                path="reservations/:id"
                element={<AboutReservation />}
              ></Route>
              <Route
                path="reservations/:id/add"
                element={<AddReservation />}
              ></Route>
              <Route
                path="reservations/:id/edit"
                element={<EditReservation />}
              ></Route>

              <Route
                path="transport"
                element={
                  <Navigate
                    to="driver"
                    replace={true}
                  />
                }
              />
              <Route
                path="transport/driver"
                element={<Driver />}
              />
              <Route
                path="transport/driver/:id"
                element={<AboutDriver />}
              />
              <Route
                path="transport/driver/add"
                element={<AddDriver />}
              />

              <Route
                path="transport/vehicles"
                element={<Vehicles />}
              />
              <Route
                path="transport/vehicles/:id"
                element={<AboutVehicle />}
              />
              <Route
                path="transport/vehicles/add"
                element={<AddVehicles />}
              />
            </Route>

            <Route element={<RequireAuth allowedRoles={["Super"]} />}>
              <Route
                path="manager"
                element={<Manager />}
              />
              <Route
                path="manager/:id"
                element={<AboutManager />}
              />
              <Route
                path="manager/add"
                element={<AddManager />}
              />

              <Route
                path="points"
                element={<Points />}
              />
              <Route
                path="points/:id"
                element={<AboutPoint />}
              />
              <Route
                path="points/add"
                element={<AddPoints />}
              />

              <Route
                path="content"
                element={
                  <Navigate
                    to="cover"
                    replace={true}
                  />
                }
              />
              <Route
                path="content/cover"
                element={<Cover />}
              />
              <Route
                path="content/cover/add"
                element={<AddCover />}
              />
              <Route
                path="content/articles"
                element={<Articles />}
              />
              <Route
                path="content/articles/add"
                element={<AddArticle />}
              />

              <Route
                path="tourist"
                element={<Tourist />}
              />
              <Route
                path="tourist/:id"
                element={<AboutTourist />}
              />
              <Route
                path="tourist/add"
                element={<AddTourist />}
              />
            </Route>

            <Route
              path="/admin"
              element={
                <Navigate
                  to="/admin/reservations"
                  replace={true}
                />
              }
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={["Tourist"]} />}>
            <Route path="user">
              <Route
                path="news"
                element={<News />}
              ></Route>

              <Route
                path="reservations"
                element={<UserReservations />}
              ></Route>
              <Route
                path="reservations/:id"
                element={<UserReservationDetail />}
              ></Route>
              <Route
                path="reservations/:id/start/:rideId"
                element={<UserReservationRoute />}
              ></Route>

              <Route
                path="profile"
                element={<UserProfile />}
              ></Route>

              <Route
                path="/user"
                element={
                  <Navigate
                    to="/user/news"
                    replace={true}
                  />
                }
              />
            </Route>
          </Route>

          <Route element={<RequireAuth allowedRoles={["Driver"]} />}>
            <Route path="driver">
              <Route
                path="history"
                element={<DriverTripsHistory />}
              ></Route>
              <Route
                path="history/:id"
                element={<HistoryTrip />}
              ></Route>

              <Route
                path="trips"
                element={<DriverTrips />}
              ></Route>
              <Route
                path="trips/:id"
                element={<DriverTripRoute />}
              ></Route>
              <Route
                path="trips/:id/start/:rideId"
                element={<DriverStartTrip />}
              ></Route>

              <Route
                path="profile"
                element={<DriverProfile />}
              ></Route>

              <Route
                path="/driver"
                element={
                  <Navigate
                    to="/driver/trips"
                    replace={true}
                  />
                }
              />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
