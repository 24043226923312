import { createPortal } from "react-dom";

const mountElement = document.getElementById("overlays")

const Overlays = ({ children }) => {
    return (
        createPortal(<>{children}</>, mountElement)
    )
}

export default Overlays;