import useAxiosPrivate from "./useAxiosPrivate";
import { useAuth } from "./useAuth";

const API_KEY = process.env.REACT_APP_API_KEY;

const useLogout = () => {
  const { setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const logout = async () => {
    setAuth({});
    try {
      await axiosPrivate.post(
        'auth/logout',
        {},
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'apikey': API_KEY
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  return logout;
}

export default useLogout;