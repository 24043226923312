import ReservationInfo from "../../reservation/ReservationInfo";

function AddReservationConfirm({ booking, driverSelected, vehicleSelected, admin }) {
  return (
    <ReservationInfo
      booking={booking}
      driverSelected={driverSelected}
      vehicleSelected={vehicleSelected}
      admin={admin}
    />
  );
}

export default AddReservationConfirm;
