import { useState, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useCreateContent } from "../../../hooks/useContent";
import { createUserStates, FormEvents, FormState } from "../../../states/createUser";

import userExample from "../../../assets/images/user-example.png";
import { ReactComponent as ProfilePicture } from "../../../assets/icons/Profile_Picture.svg";

import PopUp from "../../../components/modals/popUp";
import Overlays from "../../../components/modals/overlays";
import NewStepProcessBar from "../../../components/newStepProcessBar";
import LazyLoading from "../../../components/LazyLoading";
import Header from "../../../components/Header";
import GoBackButton from "../../../components/GoBackButton";
import CloudIcon from "../../../assets/svg/CloudIcon";

const AddCover = () => {
  const navigate = useNavigate();
  const [sitePictureName, setSitePictureName] = useState("");
  const [formData, setFormData] = useState(null);
  const [popUp, setPopUp] = useState({
    informative: false,
    warning: false,
  });

  const { createCover } = useCreateContent();
  const [state, dispatch] = useReducer(createUserStates, FormState.UserInformation);

  const {
    register,
    formState: { errors, isDirty, isValid },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    setFormData(data);
  };

  const updateState = () => {
    if (state === FormState.UserInformation) dispatch(FormEvents.CreateUser);
  };

  useEffect(() => {
    if (state === FormState.CreatingUser) {
      setPopUp({ ...popUp, informative: false });
      createCover(formData, dispatch);
    }
    if (state === FormState.UserImages) {
      navigate(-1);
    }
  }, [state, formData]);

  return (
    <section className="formLayout">
      <div className="formLayout__title">
        <Header name="Contenido" />
      </div>
      <div className="formLayout__container">
        <div className="formLayout__subtitle">
          <GoBackButton
            onClick={() => setPopUp({ ...popUp, warning: true })}
            title="Complete la Información de la Portada"
            subtitle="Rellene los campos que se le solicitan para la creación de la portada"
          />
          <NewStepProcessBar
            stepsNumber={2}
            actualStep={state + 1}
          />
          <p className="form__steps">
            <strong>Paso {state + 1}</strong> de 2
          </p>
        </div>
        <div className="formLayout__form">
          <div className="main_structure__content">
            {state === FormState.UserInformation && (
              <form
                className="main_structure__form form"
                id="cover-information"
                onSubmit={handleSubmit(onSubmit)}
              >
                <section>
                  <div className="form__input_container">
                    <label htmlFor="">Título de la portada</label>
                    <input
                      type="text"
                      className={errors.name?.message ? "error" : ""}
                      placeholder="Agregue el título"
                      {...register("captionImage", {
                        required: "Es requerido un nombre",
                      })}
                    />
                  </div>
                </section>
                <section>
                  <h3>Documentos requeridos</h3>
                  <div className="form__upload_container">
                    <ProfilePicture className="form__profile_picture" />
                    <div className="form__upload_files">
                      <p>Fotografía del sitio</p>
                      <div className={`${sitePictureName ? "form__drag_and_drop--active" : "form__drag_and_drop"}`}>
                        <CloudIcon />
                        <h4>{sitePictureName ? sitePictureName : "Arrastra y suelta el archivo aquí"}</h4>
                        <input
                          type="file"
                          id="sitePicture"
                          accept=".jpg, .png, .jpeg"
                          {...register("coverPicture", {
                            onChange: (e) => {
                              setSitePictureName(e.target.files[0]?.name);
                            },
                            required: "Es necesaria una imagen",
                          })}
                        />
                      </div>
                      <button
                        type="button"
                        className="form__desktop_button"
                        onClick={() => document.getElementById("sitePicture").click()}
                      >
                        Busca en tus archivos
                      </button>
                      <button
                        type="button"
                        className="action-button action-button--primary form__mobile_button"
                        onClick={() => document.getElementById("sitePicture").click()}
                      >
                        Busca en tus archivos
                      </button>
                      <ol>
                        <li>La fotografía debe ser clara y legible</li>
                        <li>
                          Debe ser una fotografía donde se identifique el tipo de sitio, comercio o actividad a realizar
                          en ese lugar
                        </li>
                      </ol>
                    </div>
                  </div>
                </section>
              </form>
            )}
            {state === FormState.CreatingUser && <LazyLoading />}
          </div>
        </div>
      </div>
      <div className="formLayout__buttons">
        {state === FormState.UserInformation && (
          <button
            className="action-button action-button--primary"
            type="submit"
            form="cover-information"
            onClick={() => setPopUp({ ...popUp, informative: true })}
            disabled={!isDirty || !isValid}
          >
            Continuar
          </button>
        )}
      </div>
      <Overlays>
        {popUp.warning && (
          <PopUp
            type={"warning"}
            title={"¿Desea salir?"}
            subtitle={"Si sales ahora, perderás todo el avance e información que has escrito hasta el momento."}
          >
            <button
              className="alert-button alert-button--secondary"
              onClick={() => navigate(-1)}
            >
              Sí
            </button>
            <button
              className="alert-button alert-button--primary"
              onClick={() => setPopUp({ ...popUp, warning: false })}
            >
              No
            </button>
          </PopUp>
        )}
        {popUp.informative && (
          <PopUp
            type={"informative"}
            title={"¿Está todo listo?"}
            subtitle={`Al presionar "Sí" se creará la nueva portada`}
          >
            <button
              className="action-button action-button--secondary"
              onClick={() => updateState()}
            >
              Sí
            </button>
            <button
              className="action-button action-button--primary"
              onClick={() => setPopUp({ ...popUp, informative: false })}
            >
              No
            </button>
          </PopUp>
        )}
      </Overlays>
    </section>

    //   </section>

    // </section>
  );
};

export default AddCover;
