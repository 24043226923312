import { useState } from "react";
import { useForm } from "react-hook-form";

import UploadArticlePicture from "../../uploadFile/uploadArticlePicture";

const EditArticlePicture = ({ data, setFormData }) => {
  const [articlePictureName, setArticlePictureName] = useState(null);

  const {
    register,
    getFieldState,
    handleSubmit,
  } = useForm();

  const isArticlePictureUploadValid = getFieldState("contentImage");

  const onSubmit = (imageData) => {
    setFormData(imageData.contentImage);
  };

  return (
    <>
      <h2 className="edit-popup__subtitle">Documentos requeridos</h2>
      <form
        className="edit-popup__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="edit-popup__form-wrapper">
          <UploadArticlePicture show articlePictureName={articlePictureName} setArticlePictureName={setArticlePictureName} register={register} />
        </div>
        <button
          type="submit"
          className="edit-popup__button action-button action-button--primary"
          disabled={!isArticlePictureUploadValid.isDirty}
        >
          Guardar cambios
        </button>
      </form>
    </>
  );
};

export default EditArticlePicture;
