import { useEffect } from "react";
import { useReadVehicles } from "../../../hooks/useVehicles";

import VehicleTable from "../../tables/VehicleTable";
import Paginator from "../../paginator/paginator";
import VehicleCard from "../../informativeCards/vehicleCard";

const MAX_RESULTS = 10;

const AddReservationVehicle = ({ vehicleSelected, setVehicleSelected }) => {
  const { vehicle, vehicles, getVehicles, getVehicleById } = useReadVehicles();

  function handleSearchVehicle(limit, page) {
    getVehicles(limit, page);
  }

  useEffect(() => {
    getVehicles(MAX_RESULTS, 1);
  }, []);

  useEffect(() => {
    if (vehicleSelected) {
      getVehicleById(vehicleSelected._id);
    }
  }, [vehicleSelected]);

  return (
    <div className="cardLayout__container">
      <div className="cardLayout__column">
        <div className="cardLayout__content">
          <VehicleTable
            toAddReservation
            selected={setVehicleSelected}
            data={vehicles?.data}
          />
        </div>
        {vehicles && (
          <Paginator
            maxPages={vehicles.totalPages}
            search={handleSearchVehicle}
            resultsShown={MAX_RESULTS}
            maxResults={vehicles.count}
            page={vehicles.page}
          />
        )}
      </div>
      <section className="cardLayout__card">
        {vehicleSelected && vehicle && (
          <VehicleCard
            vehicle={vehicle}
            setClose={setVehicleSelected}
            isDescriptive={false}
            isEditable={false}
          />
        )}
      </section>
    </div>
  );
};

export default AddReservationVehicle;
