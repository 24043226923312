import { useState, useEffect } from "react";
import { useUpdatePoints } from "../../../hooks/usePoints";

import EditPointInformation from "./EditPointInformation";
import EditPointPicture from "./EditPointPicture";
import EditPointMap from "./EditPointMap";
import LazyLoading from "../../../components/LazyLoading";
import GoBackButton from "../../GoBackButton";

const EditPoint = ({ point, pointId, close }) => {
  const [pointData, setPointData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [formImage, setFormImage] = useState(null);
  const [state, setState] = useState({ success: false, fail: false })
  const [category, setCategory] = useState({
    map: true,
    information: false,
    picture: false,
  });

  const { pointPicture, updatedPoint, updatePointError, updatePoint, updatePointImage } = useUpdatePoints();

  const handleCategory = (activeCategory) => {
    const initialValue = {
      map: false,
      information: false,
      picture: false,
    };
    setState({ success: false, fail: false })

    const updatedCategories = { ...initialValue };
    updatedCategories[activeCategory] = true;

    setCategory(updatedCategories);
  };

  useEffect(() => {
    if (point) {
      setPointData(point)
    }
  }, [point])

  useEffect(() => {
    if (updatedPoint) {
      setPointData(updatedPoint);
    }
  }, [updatedPoint])

  useEffect(() => {
    if (formData) {
      setIsLoading(true);
      updatePoint(formData, pointData._id);
    }
  }, [formData])

  useEffect(() => {
    if (formImage?.poiImage) {
      setIsLoading(true);
      updatePointImage(formImage.poiImage, pointData._id);
    }
  }, [formImage])

  useEffect(() => {
    if (updatedPoint || pointPicture) {
      setState({ success: true, fail: false })
      setIsLoading(false);
    } else if (updatePointError) {
      setState({ success: false, fail: true })
      setIsLoading(false);
    }
  }, [updatedPoint, pointPicture, updatePointError])

  return (
    <section className="edit-popup">
      <div className="edit-popup__box">
        <div className="edit-popup__container">
          <div className="edit-popup__header">
            <GoBackButton
              title="Edite la información del Punto"
              subtitle="Edite la información del Punto según sea necesario"
              onClick={close}
              isClose
            />
            {state.success && (
              <p className="edit-popup__status edit-popup__status--success">Cambios guardados con éxito</p>
            )}
            {state.fail && <p className="edit-popup__status edit-popup__status--error">Cambios no procesados</p>}
          </div>
          {!isLoading && (
            <>
              <div className="edit-popup__category">
                <button
                  className={`category-button ${category.map ? "category-button--active" : ""}`}
                  onClick={() => handleCategory("map")}
                >
                  Locación
                </button>
                <button
                  className={`category-button ${category.information ? "category-button--active" : ""}`}
                  onClick={() => handleCategory("information")}
                >
                  Información
                </button>
                <button
                  className={`category-button ${category.picture ? "category-button--active" : ""}`}
                  onClick={() => handleCategory("picture")}
                >
                  Documentos
                </button>
              </div>

              {(category.map && pointData) && <EditPointMap data={pointData} setFormData={setFormData} />}

              {(category.information && pointData) && <EditPointInformation data={pointData} setFormData={setFormData} />}

              {(category.picture && pointData) && <EditPointPicture data={pointData} setFormData={setFormImage} />}
            </>
          )}
          {isLoading && <LazyLoading />}
        </div>
      </div>
    </section>
  );
};

export default EditPoint;
