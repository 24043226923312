import React from 'react'

const CloudIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
    >
      <g clipPath="url(#clip0_1516_6175)">
        <path
          d="M27.8291 27.6978L21 20.8687L14.1709 27.6978"
          stroke="#7C7C7C"
          strokeWidth="3.41455"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 20.8687V36.2341"
          stroke="#7C7C7C"
          strokeWidth="3.41455"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.324 31.7781C36.9892 30.8703 38.3046 29.4338 39.0627 27.6954C39.8208 25.9569 39.9784 24.0155 39.5106 22.1776C39.0428 20.3396 37.9762 18.7098 36.4793 17.5453C34.9823 16.3808 33.1402 15.748 31.2436 15.7468H29.0924C28.5757 13.748 27.6125 11.8923 26.2753 10.3193C24.9382 8.74636 23.2618 7.49697 21.3723 6.66511C19.4828 5.83326 17.4293 5.44057 15.3661 5.51659C13.303 5.5926 11.284 6.13533 9.46077 7.10398C7.63759 8.07263 6.05772 9.44199 4.83994 11.1091C3.62216 12.7762 2.79815 14.6977 2.42988 16.7291C2.0616 18.7606 2.15864 20.849 2.71368 22.8375C3.26873 24.8261 4.26735 26.6629 5.63447 28.2099"
          stroke="#7C7C7C"
          strokeWidth="3.41455"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.8291 27.6978L21 20.8687L14.1709 27.6978"
          stroke="#7C7C7C"
          strokeWidth="3.41455"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1516_6175">
          <rect
            width="40.9746"
            height="40.9746"
            fill="white"
            transform="translate(0.512695 0.381348)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CloudIcon