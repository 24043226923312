import React, { useEffect, useState } from "react";
import TripRouteInformation from "../../../components/tripRoute/tripRouteInformation";
import { useNavigate, useParams } from "react-router-dom";
import LeftArrow from "../../../assets/svg/LeftArrow";
import Header from "../../../components/Header";
import GoBackButton from "../../../components/GoBackButton";
import ReservationInfo from "../../../components/reservation/ReservationInfo";
import { useReadBookings } from "../../../hooks/useBookings";
import { useReadVehicles } from "../../../hooks/useVehicles";
import { useReadDrivers } from "../../../hooks/useDrivers";
import { useReadUsers } from "../../../hooks/useUsers";
import LazyLoading from "../../../components/LazyLoading";

const HistoryTrip = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { booking, getBookingById } = useReadBookings();
  const { vehicle, getVehicleById } = useReadVehicles();
  const { driver, getDriverById } = useReadDrivers();
  const { user, getUserById } = useReadUsers();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getBookingById(id);
  }, []);

  useEffect(() => {
    const fetchInformation = async () => {
      if (booking) {
        await getVehicleById(booking.ride.vehicle);
        await getDriverById(booking.ride.driver);
        await getUserById(booking.ride.assignedBy);
        setIsLoading(false);
      }
    };
    fetchInformation();
  }, [booking]);

  return (
    <section className="formLayout">
      <section className="formLayout__title">
        <Header name="Mi historial de viajes" />
      </section>
      <section className="formLayout__container">
        <div className="formLayout__subtitle">
          <GoBackButton
            onClick={() => navigate(-1)}
            title="Detalles de su viaje anterior"
            subtitle="Consulte los detalles de su viaje realizado"
          />
        </div>
        <div className="formLayout__form">
          {isLoading && <LazyLoading />}
          {!isLoading && booking && (
            <ReservationInfo
              booking={booking}
              vehicleSelected={vehicle}
              driverSelected={driver}
              admin={user}
            />
          )}
        </div>
      </section>
    </section>
  );
};

export default HistoryTrip;
