import React from 'react'

const SendIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
    >
      <path
        d="M39.9781 2.48975L19.125 23.3428"
        stroke="#FAFDFF"
        stroke-width="3.79147"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M39.9782 2.48975L26.708 40.4044L19.1251 23.3428L2.06348 15.7599L39.9782 2.48975Z"
        stroke="#FAFDFF"
        stroke-width="3.79147"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default SendIcon