import React from "react";
import RouteLine from "../../assets/svg/RouteLine";
import ReservationRouteRow from "./ReservationRouteRow";

const ReservationRoute = ({ booking, route }) => {
  return (
    <section className="reservationRoute">
      <h3>Paradas Importantes</h3>
      <div className="reservationRoute__container">
        <RouteLine />
        <div className="reservationRoute__rows">
          <ReservationRouteRow
            pickUp
            route={route && route[0]}
            location={booking?.ticket?.pickUpLocation}
            time={booking?.ticket?.pickUpTime}
          />
          <ReservationRouteRow
            route={route && route[1]}
            location={booking?.ticket?.dropOffLocation}
            time={booking?.ticket?.dropOffTime}
          />
        </div>
      </div>
    </section>
  );
};

export default ReservationRoute;
