import React from "react";
import RouteMap from "../maps/routeMap";

const ReservationMap = ({routeCoordinates}) => {
  return (
    <section className="reservationMap">
      <h3>Recorrido Esperado</h3>
      <RouteMap
        routeCoordinates={routeCoordinates}
        isLocked={false}
      />
    </section>
  );
};

export default ReservationMap;
