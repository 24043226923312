import React from 'react'

const SuccessIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="110"
      height="110"
      viewBox="0 0 110 110"
      fill="none"
    >
      <path
        d="M100.833 50.7838V55.0004C100.828 64.884 97.6273 74.501 91.7094 82.4171C85.7916 90.3332 77.4733 96.1243 67.9954 98.9267C58.5174 101.729 48.3874 101.392 39.1163 97.9673C29.8452 94.5421 21.9296 88.2117 16.5502 79.9203C11.1708 71.6289 8.61567 61.8207 9.26598 51.9585C9.91629 42.0964 13.7372 32.7086 20.1587 25.1954C26.5803 17.6821 35.2586 12.4459 44.8992 10.2678C54.5398 8.08961 64.6262 9.08615 73.6542 13.1088"
        stroke="#23B574"
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.833 21.3334L55 67.2125L41.25 53.4625"
        stroke="#23B574"
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SuccessIcon