import React from 'react'

const ContentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M20 7.82874H4C2.89543 7.82874 2 8.72417 2 9.82874V20.8287C2 21.9333 2.89543 22.8287 4 22.8287H20C21.1046 22.8287 22 21.9333 22 20.8287V9.82874C22 8.72417 21.1046 7.82874 20 7.82874Z"
        stroke="#959595"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 2.82874L12 7.82874L7 2.82874"
        stroke="#959595"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ContentIcon