import LeftArrow from "../assets/svg/LeftArrow";
import XIcon from "../assets/svg/XIcon";

const GoBackButton = ({ title, subtitle, isClose, ...props }) => {
  return (
    <div className="goBackButton">
      <button
        className="goBackButton__arrow"
        {...props}
      >
        {!isClose ? <LeftArrow /> : <XIcon />}
      </button>
      <div className="goBackButton__info">
        <h3>{title}</h3>
        <span>{subtitle}</span>
      </div>
    </div>
  );
};

export default GoBackButton;
