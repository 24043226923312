import { useEffect, useState } from "react";

import routes from "../../../models/routes";
import ReservationMap from "../../reservation/ReservationMap";
import ReservationRoute from "../../reservation/ReservationRoute";
import ReservationTickets from "../../reservation/ReservationTickets";
import ReservationTrip from "../../reservation/ReservationTrip";
import ReservationUser from "../../reservation/ReservationUser";

function AddReservationInfo({ state, dispatch, formState, formEvents, booking }) {

  const [route, setRoute] = useState(null);
  const [routeCoordinates, setRouteCoordinates] = useState(null);

  useEffect(() => {
    if (booking) {
      const routeSites = booking?.ticket?.route;
      setRouteCoordinates(routes.find((route) => route.name === booking?.ticket?.route));
      setRoute(routeSites.split(" - "));
    }
  }, [booking]);

  return (
    <section className="reservationInfo">
      <div className="reservationInfo__firstRow">
        <ReservationMap routeCoordinates={routeCoordinates} />
        <section className="reservationInfo__firstRowColumn">
          <ReservationRoute
            booking={booking}
            route={route}
          />
          <div className="reservationInfo__twoFrOneFr">
            <ReservationTickets
              booking={booking}
              route={route}
            />
            <ReservationTrip booking={booking} />
          </div>
        </section>
      </div>
      <div className="reservationInfo__twoFrOneFr">
        <ReservationUser booking={booking} />
        <section className="reservationPrice">
          <h3>Costo del servicio</h3>
          <div className="reservationPrice__container">
            <h2>${booking?.paymentInfo?.priceInDollars}</h2>
            <span>+IVA</span>
          </div>
        </section>
      </div>
    </section>
  );
}

export default AddReservationInfo;
