import React from "react";

const ReservationRouteRow = ({ pickup, dropOff, route, location, time }) => {
  return (
    <div className="reservationRouteRow">
      <div className="reservationRouteRow__column">
        <h2>{route}</h2>
        <p>{pickup ? "Pick up" : "Drop off"} from {route}</p>
        <strong>Time</strong>
      </div>
      <div className="reservationRouteRow__column">
        <span />
        <p>{location}</p>
        <strong>{time}</strong>
      </div>
    </div>
  );
};

export default ReservationRouteRow;
