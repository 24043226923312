import { useState } from "react";
import axios from "axios";

const API_MAP = process.env.REACT_APP_API_MAP;
const API_SEARCH = process.env.REACT_APP_API_MAP_SEARCH;
const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_KEY;

export function useReadMap() {
  let isMounted = true;

  const [route, setRoute] = useState(null);
  const [duration, setDuration] = useState(0);
  const [initialCoordinates, setInitialCoordinates] = useState(null);
  const [endCoordinates, setEndCoordinates] = useState(null);
  const [routeError, setRouteError] = useState(null);

  const getRoute = async (start, end) => {
    setRouteError(false);
    try {
      const response = await axios.get(
        `${API_MAP}${start[0]},${start[1]};${end[0]},${end[1]}?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&&access_token=${MAPBOX_TOKEN}`,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (isMounted) {
        const route = response?.data.routes[0].geometry;
        const duration = convertSecondsToAHMS(response?.data.routes[0].duration);
        setDuration(duration);
        setRoute(route);
      }
    } catch (error) {
      console.log(error)
      if (isMounted) {
        setRouteError(error);
        setRoute(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    };

    return cleanUp;
  };

  const getPlaceCoordinates = async (place, isInitial) => {
    setRouteError(true);

    try {
      const response = await axios.get(
        `${API_SEARCH}${place}.json?country=cr&language=es%2Cen&access_token=${MAPBOX_TOKEN}`,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (isMounted) {
        const place = response?.data.features[0].geometry.coordinates;
        if (isInitial) {
          setInitialCoordinates(place);
        } else {
          setEndCoordinates(place);
        }
      }
    } catch (error) {
      if (isMounted) {
        setRouteError(error);
        if (isInitial) {
          setInitialCoordinates(null);
        } else {
          setEndCoordinates(null);
        }
      }
    }

    const cleanUp = () => {
      isMounted = false;
    };

    return cleanUp;
  };

  const convertSecondsToAHMS = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    return {
      hours,
      minutes,
    };
  }

  return {
    route,
    duration,
    initialCoordinates,
    endCoordinates,
    routeError,
    getRoute,
    getPlaceCoordinates,
  };
}