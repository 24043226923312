import NoUserImage from "../../assets/icons/user.png";

const ReservationManager = ({ user }) => {
  return (
    <section className="reservationProfile">
      <h3>Administrador que lo asignó</h3>
      <div className="reservationProfile__container">
        <img
          className="reservationProfile__userImage"
          src={user?.profilePicture ? user?.profilePicture : NoUserImage}
          alt="Imagen del cliente"
        />
        <div className="reservationProfile__info">
          <p className="reservationProfile__field">
            Nombre Completo
            <span>
              {user?.name} {user?.lastName}
            </span>
          </p>
          <p className="reservationProfile__field">
            Correo
            <span>{user?.email}</span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default ReservationManager;
