const contentTypeEs = [
    { label: "Hoteles", value: "hotels" },
    { label: "Restaurantes", value: "restaurants" },
    { label: "Por descubrir", value: "things-to-do" },
];

const contentTypeEn = [
    { label: "Hotels", value: "hotels" },
    { label: "Restaurants", value: "restaurants" },
    { label: "Things To Do", value: "things-to-do" },
];

const transformToStringContentType = (value, types) => {
    return types.find(item => item.value === value)?.label;
}

export { contentTypeEs, contentTypeEn, transformToStringContentType };