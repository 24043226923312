import React from 'react'

const InformativeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="110"
      height="110"
      viewBox="0 0 110 110"
      fill="none"
    >
      <path
        d="M55 30L55 60"
        stroke="#0971AB"
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55 73.6667H55.0458"
        stroke="#0971AB"
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="55.5"
        cy="54.5"
        r="46"
        stroke="#0971AB"
        strokeWidth="7"
      />
    </svg>
  );
}

export default InformativeIcon