import { useNavigate } from "react-router-dom";
import NoUserImage from "../../assets/icons/user.png";

const HistoryTable = ({ selected, data, driver }) => {
  const navigate = useNavigate();

  const isMobileView = window.innerWidth <= 810;

  const handleCard = (user) => {
    if (isMobileView) {
      navigate(user?._id);
    } else {
      selected(user);
    }
  };

  return (
    <section className="dataTable">
      <section className="dataTable__header">
        <p>Ruta</p>
        <p>Nombre</p>
        <p>Apellido</p>
      </section>
      <section className="dataTable__body">
        {data?.map((trip) => (
          <div
            key={driver ? trip.tourist._id : trip.driver?.user?._id}
            className="dataTable__row"
          >
            <img
              src={NoUserImage}
              alt=""
            />
            <div className="dataTable__rowItems">
              <p>{trip.booking.ticket.route}</p>
              <p>{driver ? trip.tourist.name : trip.driver?.user?.name}</p>
              <p>{driver ? trip.tourist.lastName : trip.driver?.user?.lastName}</p>
            </div>
            <div className="dataTable__rowItems--mobile">
              <p>
                {driver ? trip.tourist.name : trip.driver?.user?.name} {driver ? trip.tourist.lastName : trip.driver?.user?.lastName}
              </p>
              <p>{driver ? trip.tourist._id : trip.driver?.user?._id}</p>
              <p>{driver ? trip.tourist.phone : trip.driver?.user?.phone}</p>
            </div>
            <div className="dataTable__arrow">
              <svg
                width="18"
                height="25"
                viewBox="0 0 10 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.70557 15.4904L8.58643 8.60959L1.70557 1.72873"
                  stroke="#959595"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ))}
      </section>
    </section>
  );
};

export default HistoryTable;
