import React from 'react'

const CalendarIcon = () => {
  return (
    <svg
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2632 3.58655H3.26318C2.15861 3.58655 1.26318 4.48198 1.26318 5.58655V19.5865C1.26318 20.6911 2.15861 21.5865 3.26318 21.5865H17.2632C18.3678 21.5865 19.2632 20.6911 19.2632 19.5865V5.58655C19.2632 4.48198 18.3678 3.58655 17.2632 3.58655Z"
        stroke="#959595"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2632 1.58655V5.58655"
        stroke="#959595"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.26318 1.58655V5.58655"
        stroke="#959595"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.26318 9.58655H19.2632"
        stroke="#959595"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CalendarIcon