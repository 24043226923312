import React from 'react'

const TimeIcon = () => {
  return (
    <svg
      width="37"
      height="36"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4832 36.2412C29.0018 36.2412 36.7183 28.5248 36.7183 19.0061C36.7183 9.48742 29.0018 1.771 19.4832 1.771C9.96447 1.771 2.24805 9.48742 2.24805 19.0061C2.24805 28.5248 9.96447 36.2412 19.4832 36.2412Z"
        stroke="#086DA4"
        strokeWidth="3.44702"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4834 8.66504V19.0061L26.3774 22.4531"
        stroke="#086DA4"
        strokeWidth="3.44702"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default TimeIcon