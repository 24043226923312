const FormState = {
    BookingInformation: 0,
    SelectDriver: 1,
    SelectVehicle: 2,
    CheckBooking: 3,
    UploadingBooking: 4,
    Completed: 5
}

const FormEvents = {
    NextStep: 0,
    BackStep: 1,
    UploadingSuccess: 2,
    UploadingFail: 3,
}

const bookingStates = (state, event) => {
    switch (state) {
        case FormState.BookingInformation:
            if (event === FormEvents.NextStep) return FormState.SelectDriver;
            break;
        case FormState.SelectDriver:
            if (event === FormEvents.NextStep) return FormState.SelectVehicle;
            if (event === FormEvents.BackStep) return FormState.BookingInformation;
            break;
        case FormState.SelectVehicle:
            if (event === FormEvents.NextStep) return FormState.CheckBooking;
            if (event === FormEvents.BackStep) return FormState.SelectDriver;
            break;
        case FormState.CheckBooking:
            if (event === FormEvents.NextStep) return FormState.UploadingBooking;
            if (event === FormEvents.BackStep) return FormState.SelectVehicle;
            break;
        case FormState.UploadingBooking:
            if (event === FormEvents.UploadingSuccess) return FormState.Completed;
            if (event === FormEvents.UploadingFail) return FormState.BookingInformation;
            break;
        default:
            return state;
    }
};

export { FormState, FormEvents, bookingStates };