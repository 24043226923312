import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useReadBookings, useUpdateBookings } from "../../../hooks/useBookings";
import { useReadDrivers } from "../../../hooks/useDrivers";
import { useReadVehicles } from "../../../hooks/useVehicles";
import { useReadUsers } from "../../../hooks/useUsers";
import { useAuth } from "../../../hooks/useAuth";

import ReservationInfo from "../../../components/reservation/ReservationInfo";
import GoBackButton from "../../../components/GoBackButton";
import Header from "../../../components/Header";
import Overlays from "../../../components/modals/overlays";
import PopUp from "../../../components/modals/popUp";

const AboutReservation = () => {
  const navigate = useNavigate();
  const token = useAuth();

  const { id } = useParams();
  const { booking, getBookingById } = useReadBookings();
  const { updateBooking, updateBookingStatus } = useUpdateBookings();
  const { driver, getDriverById } = useReadDrivers();
  const { vehicle, getVehicleById } = useReadVehicles();
  const { user, getUserById } = useReadUsers();

  const [popUp, setPopUp] = useState(false);

  const cancelBooking = () => {
    updateBookingStatus(id, "Cancelled");
  };

  useEffect(() => {
    if (id) {
      getBookingById(id);
    }
  }, [id]);

  useEffect(() => {
    if (token) {
      getUserById(token.auth.user);
    }
  }, [token]);

  useEffect(() => {
    if (booking) {
      getDriverById(booking?.ride?.driver);
      getVehicleById(booking?.ride?.vehicle);
    }
  }, [booking]);

  useEffect(() => {
    if (updateBooking) {
      navigate(-1);
    }
  }, [updateBooking]);

  return (
    <section className="main_structure__container">
      <Header name="Reservaciones" />

      <section className="main_structure__column_wrapper">
        <section className="main_structure__column">
          <div className="main_structure__subtitle">
            <GoBackButton
              onClick={() => navigate(-1)}
              title="Información de la reserva"
              subtitle="Consulte una vista detallada de la información de la reserva"
            />

            {booking && booking?.status === "Scheduled" && (
              <div className="main_structure__headerButtons">
                <button
                  className="action-button action-button--secondary"
                  onClick={() => navigate("edit")}
                >
                  Editar
                </button>
                <button
                  className="alert-button alert-button--primary"
                  onClick={() => setPopUp(true)}
                >
                  Cancelar
                </button>
              </div>
            )}
          </div>

          <section className="main_structure__content">
            <ReservationInfo
              booking={booking}
              vehicleSelected={vehicle}
              driverSelected={driver}
              admin={user}
            />
          </section>
        </section>
      </section>

      <Overlays>
        {popUp && (
          <PopUp
            type={"warning"}
            title={"¿Desea cancelarla?"}
            subtitle={`Al seleccionar "Sí", se cancelará la reservación. Esta acción es irreversible. `}
          >
            <button
              className="alert-button alert-button--secondary"
              onClick={() => cancelBooking()}
            >
              Sí
            </button>
            <button
              className="alert-button alert-button--primary"
              onClick={() => setPopUp(false)}
            >
              No
            </button>
          </PopUp>
        )}
      </Overlays>
    </section>
  );
};

export default AboutReservation;
