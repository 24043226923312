import { Controller, useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthLogin, useAuth } from "../../hooks/useAuth";
import { languagesEn, languagesEs } from "../../models/languages";

import Multiselect from "multiselect-react-dropdown";
import { PhoneInput } from "react-international-phone";

import Select from "react-select";
import { gendersEs } from "../../models/genders";

function CompleteDataForm({ newUser, setUser, changeForm, error }) {
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const { userAuthData, userAuthDataLoading, userAuthDataError, signUp } = useAuthLogin();

  const [wasImageUpdate, setWasImageUpdate] = useState(false);
  const [image, setImage] = useState(null);
  const [languagesList, setLanguagesList] = useState(["English"]);
  const [imageData, setImageData] = useState(null);
  const [countryCode, setCountryCode] = useState("");

  const {
    control,
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = async (data) => {
    const user = {
      ...newUser,
      countryCode: countryCode ? countryCode.toUpperCase() : data.countryCode,
      phone: data.phone,
      languages: data.languages,
      gender: data.gender,
      role: "Tourist",
    };
    setUser(user);
    if (user.languages.length > 0) {
      await signUp(user, imageData, wasImageUpdate);
    }
  };

  const redirectTo = (role) => {
    switch (role) {
      case "Driver":
        navigate("/driver");
        break;

      case "Admin":
        navigate("/admin");
        break;

      case "Super":
        navigate("/admin");
        break;

      case "Tourist":
        console.log("Go to tourist");
        navigate("/user");
        break;

      default:
        navigate("/");
        break;
    }
  };

  const onChangeImage = (e) => {
    if (e.target.files[0]) {
      setImageData(e.target.files[0]);

      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImage(reader.result);
        setWasImageUpdate(true);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (!userAuthDataLoading && userAuthData) {
      const accessToken = userAuthData.data.access_token;
      const refreshToken = userAuthData.data.refresh_token;
      const role = userAuthData.decodedToken.role;
      const email = userAuthData.decodedToken.email;
      const user = userAuthData.decodedToken.sub;
      setAuth({ accessToken, refreshToken, role, user, email });
      redirectTo(role);
    }
  }, [userAuthDataLoading, userAuthData]);

  useEffect(() => {
    if (userAuthDataError) {
      changeForm(false);
      error(userAuthDataError);
    }
  }, [userAuthDataError]);

  useEffect(() => {
    if (newUser) {
      reset(newUser);
    }
  }, [reset, newUser]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="signUp__image">
        {image ? (
          <img
            src={image}
            alt="User"
          />
        ) : (
          <img
            src={require("../../assets/images/withoutImage.png")}
            alt="User"
          />
        )}
        <label style={{ backgroundColor: "transparent" }}>
          <input
            type="file"
            onChange={onChangeImage}
          />
        </label>
      </div>

      <div className="form__input_container">
        <label>Phone Number</label>
        <Controller
          render={({ field: { onChange } }) => (
            <PhoneInput
              inputStyle={errors.phone ? { borderColor: "#F55555" } : { borderColor: "#7C7C7C" }}
              defaultCountry="cr"
              onChange={(phone, country) => {
                setCountryCode(country.country.iso2);
                onChange(phone);
              }}
            />
          )}
          control={control}
          name="phone"
          rules={{ required: true, minLength: 5 }}
        />
        {errors.phone?.message && <p className="error">{errors.phone?.message}</p>}
      </div>
      <div className="form__input_container">
        <label>Genre</label>
        <Controller
          render={({ field: { onChange } }) => (
            <Select
              classNamePrefix="react-select"
              isClearable={false}
              options={gendersEs}
              placeholder="Select a gender"
              onChange={(value) => {
                onChange(value.value);
              }}
            />
          )}
          control={control}
          name="gender"
          rules={{ required: true }}
        />
      </div>
      <div className="form__input_container">
        <label>Languages</label>
        <Controller
          render={({ field: { onChange } }) => (
            <Select
              classNamePrefix="react-select"
              isClearable={true}
              isMulti={true}
              options={languagesEs}
              placeholder="Select a language"
              onChange={(data) => {
                onChange(data.map((id) => id.value));
              }}
            />
          )}
          control={control}
          name="languages"
          rules={{ required: true }}
        />
      </div>

      <button
        className="signUp__button action-button action-button--primary"
        type="submit"
      >
        <p>Let's start</p>
      </button>
    </form>
  );
}

export default CompleteDataForm;
