import { useEffect } from "react";
import { useReadDrivers } from "../../../hooks/useDrivers";

import Paginator from "../../paginator/paginator";
import DriverCard from "../../informativeCards/driverCard";
import DriverTable from "../../tables/DriverTable";

const MAX_RESULTS = 10;

const AddReservationDriver = ({ driverSelected, setDriverSelected }) => {
  const { drivers, getDrivers } = useReadDrivers();

  function handleSearchUser(limit, page) {
    getDrivers(limit, page);
  }

  useEffect(() => {
    handleSearchUser(MAX_RESULTS, 1);
  }, []);

  return (
    <div className="cardLayout__container">
      <div className="cardLayout__column">
        <div className="cardLayout__content">
          <DriverTable
          toAddReservation
            selected={setDriverSelected}
            data={drivers?.data}
          />
        </div>
        {drivers && (
          <Paginator
            maxPages={drivers.totalPages}
            search={handleSearchUser}
            resultsShown={MAX_RESULTS}
            maxResults={drivers.count}
            page={drivers.page}
          />
        )}
      </div>
      <section className="cardLayout__card">
        {driverSelected && (
          <DriverCard
            setClose={setDriverSelected}
            driver={driverSelected}
            isEditable={false}
            isDescriptive={false}
          />
        )}
      </section>
    </div>
  );
};

export default AddReservationDriver;
