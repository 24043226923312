import React from "react";

const ReservationTickets = ({ booking }) => {
  return (
    <div className="reservationTickets">
      <h3>Tickets</h3>
      <div className="reservationTickets__container">
        <div className="reservationTickets__column">
          <h4>Tipo</h4>
          <p>Adults</p>
          <p>Children</p>
          <p>Baby Seat</p>
          <p>Bags</p>
          <p>Surf Board</p>
        </div>
        <div className="reservationTickets__column">
          <h4>Cantidad</h4>
          <p>{booking?.passengersInfo?.adults}</p>
          <p>{booking?.passengersInfo?.kids}</p>
          <p>{booking?.luggageInfo?.babySeats}</p>
          <p>{booking?.luggageInfo?.bags}</p>
          <p>{booking?.luggageInfo?.surfboards}</p>
        </div>
      </div>
    </div>
  );
};

export default ReservationTickets;
