import React from 'react'

const RouteLine = () => {
  return (
    <svg
      viewBox="0 0 7 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="3.69751"
        cy="4.23755"
        r="2.24097"
        stroke="#4A4A4A"
        strokeWidth="2"
      />
      <circle
        cx="3.69751"
        cy="97.0549"
        r="2.24097"
        stroke="#4A4A4A"
        strokeWidth="2"
      />
      <path
        d="M3.69775 5.85107L3.69775 94.8454"
        stroke="#4A4A4A"
        strokeWidth="1.5"
        strokeDasharray="2 2"
      />
    </svg>
  );
}

export default RouteLine