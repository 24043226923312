import { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateUsers, useUpdateUsers } from "../../../hooks/useUsers";
import { useUpdateDrivers } from "../../../hooks/useDrivers";
import {
  createUserStates,
  FormEvents,
  FormState,
} from "../../../states/createUser";

import PopUp from "../../../components/modals/popUp";
import NewStepProcessBar from "../../../components/newStepProcessBar";
import AddDriverInformation from "../../../components/forms/AddDriver/AddDriverInformation";
import AddDriverDocuments from "../../../components/forms/AddDriver/AddDriverDocuments";
import Overlays from "../../../components/modals/overlays";
import LazyLoading from "../../../components/LazyLoading";
import Header from "../../../components/Header";
import GoBackButton from "../../../components/GoBackButton";

function AddDriver() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(null);
  const [validInformation, setValidInformation] = useState({
    isDirty: false,
    isValid: false,
  });
  const [popUp, setPopUp] = useState({
    informative: false,
    warning: false,
    positive: false,
  });

  const resetSections = {
    license: false,
    profilePicture: false,
  };

  const [sectionOpen, setSectionOpen] = useState(resetSections);

  const [state, dispatch] = useReducer(
    createUserStates,
    FormState.UserInformation
  );

  const { user, createUserError, createUser } = useCreateUsers();
  const { profilePicture, updateUserError, uploadProfilePicture } =
    useUpdateUsers();
  const { licensePicture, updateDriverError, uploadLicensePicture } =
    useUpdateDrivers();

  const updateState = () => {
    if (state === FormState.UserInformation) {
      dispatch(FormEvents.CreateUser);
    } else if (state === FormState.UserImages) {
      dispatch(FormEvents.UploadImages);
    }
  };

  const goBack = () => {
    if (sectionOpen.license || sectionOpen.profilePicture) {
      return setSectionOpen(resetSections);
    }

    setPopUp({ ...popUp, warning: true });
  };

  useEffect(() => {
    if (state === FormState.CreatingUser) {
      setPopUp({ ...popUp, informative: false });
      createUser(formData, dispatch);
    } else if (state === FormState.UploadingImages) {
      setPopUp({ ...popUp, informative: false });
      uploadProfilePicture(formData.profilePicture, user.id);
      uploadLicensePicture(formData.licensePicture, user.driverId);
    } else if (state === FormState.Completed) {
      setPopUp({ ...popUp, positive: true });
    }
  }, [state, formData]);

  useEffect(() => {
    if (updateDriverError) {
      dispatch(FormEvents.UploadImagesFail);
    } else if (updateUserError) {
      dispatch(FormEvents.CreateUserFail);
    }
  }, [updateDriverError, updateUserError]);

  useEffect(() => {
    if (licensePicture && profilePicture) {
      dispatch(FormEvents.UploadImagesSuccess);
    }
  }, [profilePicture, licensePicture]);

  useEffect(() => {
    if (user) {
      dispatch(FormEvents.CreateUserSuccess);
    }
  }, [user]);

  return (
    <section
      className="formLayout"
      style={popUp.warning || popUp.informative ? { overflow: "hidden" } : {}}
    >
      <div className="formLayout__title">
        <Header name="Transporte" />
      </div>
      <div className="formLayout__container">
        <div className="formLayout__subtitle">
          <GoBackButton
            onClick={goBack}
            title="Añada la información del conductor"
            subtitle="Rellene los campos con la información personal del conductor"
          />
          <NewStepProcessBar
            stepsNumber={FormState.Completed + 1}
            actualStep={state + 1}
          />
          <p className="form__steps">
            <strong>Paso {state + 1}</strong> de {FormState.Completed + 1}
          </p>
        </div>
        <div className="formLayout__form">
          {(state === FormState.UserInformation ||
            state === FormState.UserImages) && (
            <div action="" className="form">
              <AddDriverInformation
                state={state}
                formState={FormState}
                createUser={setFormData}
                validUser={setValidInformation}
              />
              <AddDriverDocuments
                state={state}
                formState={FormState}
                validImages={setValidInformation}
                uploadPictures={setFormData}
                sectionOpen={sectionOpen}
                setSectionOpen={setSectionOpen}
              />
            </div>
          )}
          {(state === FormState.CreatingUser ||
            state === FormState.UploadingImages) && <LazyLoading />}
        </div>
      </div>
      {(state === FormState.UserInformation ||
        state === FormState.UserImages) &&
        !sectionOpen.license &&
        !sectionOpen.profilePicture && (
          <div className="formLayout__buttons">
            <button
              className="action-button action-button--primary"
              form={
                state === FormState.UserInformation
                  ? "driver-information"
                  : "driver-pictures"
              }
              type="submit"
              onClick={() => setPopUp({ ...popUp, informative: true })}
              disabled={!validInformation.isDirty || !validInformation.isValid}
            >
              Continuar
            </button>
          </div>
        )}
      <Overlays>
        {popUp.warning && (
          <PopUp
            type={"warning"}
            title={"¿Desea salir?"}
            subtitle={
              "Si sales ahora, perderás todo avance e información que has escrito hasta el momento"
            }
          >
            <button
              className="alert-button alert-button--secondary"
              onClick={() => navigate(-1)}
            >
              Sí
            </button>
            <button
              className="alert-button alert-button--primary"
              onClick={() => setPopUp({ ...popUp, warning: false })}
            >
              No
            </button>
          </PopUp>
        )}
        {popUp.informative && (
          <PopUp
            type={"informative"}
            title={"¿Está todo listo?"}
            subtitle={
              state === FormState.UserInformation
                ? 'Al presionar "Sí" se creará el nuevo perfil del conductor'
                : 'Al presionar "Sí" se cargarán las imágenes al perfil del conductor'
            }
          >
            <button
              className="action-button action-button--secondary"
              onClick={() => updateState()}
            >
              Sí
            </button>
            <button
              className="action-button action-button--primary"
              onClick={() => setPopUp({ ...popUp, informative: false })}
            >
              No
            </button>
          </PopUp>
        )}
        {popUp.positive && (
          <PopUp
            type={"positive"}
            title={"¡El conductor se ha creado correctamente!"}
            subtitle={`Al presionar "Aceptar" volverá a la vista principal`}
          >
            <button
              className="action-button action-button--primary"
              onClick={() => navigate(-1)}
            >
              Sí
            </button>
          </PopUp>
        )}
      </Overlays>
    </section>
  );
}

export default AddDriver;
