function StatusButton({ buttonActive, setButtonActive }) {
  return (
    <section className="category-button__container">
      <button className={`category-button ${buttonActive === 'Pending for Assignment' ? 'category-button--active' : ''}`} onClick={() => setButtonActive('Pending for Assignment')}><p>Sin asignar</p></button>
      <button className={`category-button ${buttonActive === 'Scheduled' ? 'category-button--active' : ''}`} onClick={() => setButtonActive('Scheduled')}><p>Asignados</p></button>
      <button className={`category-button ${buttonActive === 'Completed' ? 'category-button--active' : ''}`} onClick={() => setButtonActive('Completed')}><p>Completados</p></button>
      <button className={`category-button ${buttonActive === 'Cancelled' ? 'category-button--active' : ''}`} onClick={() => setButtonActive('Cancelled')}><p>Cancelados</p></button>
    </section>
  );
}

export default StatusButton