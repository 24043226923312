import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { languagesEs, transformToStringLanguages } from "../../models/languages";
import { gendersEs, transformToStringGender } from "../../models/genders";

import Overlays from "../modals/overlays";
import EditPopup from "../modals/editPopup";
import PopUp from "../modals/popUp";

function DriverCard({ driver, setClose, isDescriptive }) {
  const navigate = useNavigate();

  const [popUp, setPopup] = useState({
    edit: false,
    warning: false,
    informative: false,
  });

  const handleEditPopup = () => {
    if (popUp.edit) {
      setPopup((prevState) => ({
        ...prevState,
        edit: false,
      }));
      return;
    }

    setPopup({
      ...popUp,
      edit: true,
    });
  };

  const deleteData = () => {
    setPopup({ ...popUp, warning: false });
  };

  return (
    <section className="newInformativeCard">
      {isDescriptive &&
        <button
          className="close_button newInformativeCard__close"
          onClick={() => setClose(null)}
        >
          <span></span>
          <span></span>
        </button>
      }
      <div className="newInformativeCard__profile">
        <img
          src={driver?.user?.profilePicture ? driver?.user?.profilePicture : require("../../assets/icons/user.png")}
          alt="Imagen del conductor"
        />
        <p className="newInformativeCard__id">
          ID Conductor
          <strong>{driver?._id || "ID no disponible"}</strong>
        </p>
      </div>
      <section className="newInformativeCard__container">
        <p className="newInformativeCard__row">
          Nombre
          <span>{driver.user.name} {driver.user.lastName}</span>
        </p>
        <p className="newInformativeCard__row">
          Correo
          <span>{driver.user.email}</span>
        </p>
        <p className="newInformativeCard__row">
          Género
          <span>{transformToStringGender(driver.user.gender, gendersEs)}</span>
        </p>
        <p className="newInformativeCard__row">
          Teléfono
          <span>{driver.user.phone}</span>
        </p>
        <p className="newInformativeCard__row">
          Idiomas
          <span>{transformToStringLanguages(driver.user.languages, languagesEs)}</span>
        </p>
      </section>
      <div className="newInformativeCard__buttons">
        {isDescriptive &&
          <button
            onClick={() => navigate(driver._id)}
            className="action-button action-button--primary"
          >
            <p>Más información</p>
          </button>
        }
      </div>

      <Overlays>
        {popUp.edit && (
          <EditPopup
            user={driver}
            type={"Driver"}
            close={handleEditPopup}
          />
        )}
        {popUp.warning && (
          <PopUp
            type={"warning"}
            title={"¿Desea eliminarlo?"}
            subtitle={"Esta acción es irreversible"}
          >
            <button
              className="alert-button alert-button--secondary"
              onClick={deleteData}
            >
              Sí
            </button>
            <button
              className="alert-button alert-button--primary"
              onClick={() => { setPopup({ ...popUp, warning: false }) }}
            >
              No
            </button>
          </PopUp>
        )}
      </Overlays>
    </section>
  );
}

export default DriverCard;
