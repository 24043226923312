import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../hooks/useRefreshToken";
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from "../hooks/useAuth";

import Loading from "./loading";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isVerify, setIsVerify] = useState(false);

  const refresh = useRefreshToken();
  const { auth, persist } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname;

  const redirectTo = (role) => {
    switch (role) {
      case 'Driver':
        navigate(from ? from : '/driver', { state: { from: location }, replace: true });
        break;

      case 'Admin':
        navigate(from ? from : '/admin', { state: { from: location }, replace: true });
        break;

      case 'Super':
        navigate(from ? from : '/admin', { state: { from: location }, replace: true });
        break;

      case 'Tourist':
        navigate(from ? from : '/user', { replace: true });
        break;

      default:
        navigate(from ? from : '/', { replace: true });
        break;
    }
  };

  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        setIsVerify(true);
        await refresh();
      }
      catch (error) {
        setIsVerify(false)
        console.error(error);
      }
      finally {
        isMounted && setIsLoading(false);
      }
    }

    !auth.accessToken ? verifyRefreshToken() : setIsLoading(false);

    return () => isMounted = false;
  }, []);

  useEffect(() => {
    if (isVerify) {
      let accessToken = auth?.accessToken;
      let refreshToken = auth?.refreshToken;
      if (accessToken) {
        let access = { accessToken: accessToken, refreshToken: refreshToken };
        localStorage.setItem('nosara-persist', JSON.stringify(access));
        redirectTo(auth.role);
      }
    }
  }, [isVerify]);

  return (
    <>
      {!persist
        ? <Outlet />
        : isLoading
          ? <Loading />
          : <Outlet />
      }
    </>
  )
}

export default PersistLogin;