import { useEffect, useState } from "react";
import { languagesEs, transformToStringLanguages } from "../../models/languages";

import routes from "../../models/routes";
import ReservationMap from "./ReservationMap";
import ReservationDriver from "./ReservationDriver";
import ReservationVehicle from "./ReservationVehicle";
import ReservationRoute from "./ReservationRoute";
import ReservationTickets from "./ReservationTickets";
import ReservationManager from "./ReservationManager";
import ReservationUser from "./ReservationUser";
import ReservationTrip from "./ReservationTrip";

function ReservationInfo({ booking, driverSelected, vehicleSelected, admin }) {
  const [route, setRoute] = useState(null);
  const [routeCoordinates, setRouteCoordinates] = useState(null);
  const [driverLanguages, setDriverLanguages] = useState(null);

  useEffect(() => {
    if (booking) {
      const routeSites = booking?.ticket?.route;
      setRouteCoordinates(routes.find((route) => route.name === booking?.ticket?.route));
      setRoute(routeSites.split(" - "));
    }
  }, [booking]);

  useEffect(() => {
    if (driverSelected) {
      setDriverLanguages(transformToStringLanguages(driverSelected.user.languages, languagesEs));
    }
  }, [driverSelected]);

  return (
    <section className="reservationInfo">
      <div className="reservationInfo__firstRow">
        <ReservationMap routeCoordinates={routeCoordinates} />
        <section className="reservationInfo__transport">
          <ReservationDriver
            driverSelected={driverSelected}
            driverLanguages={driverLanguages}
          />
          <ReservationVehicle vehicleSelected={vehicleSelected} />
        </section>
      </div>
      <div className="reservationInfo__twoFrOneFr">
        <ReservationRoute
          booking={booking}
          route={route}
        />
        <ReservationTickets booking={booking} />
      </div>

      <div className="reservationInfo__twoFrOneFr">
        <ReservationUser booking={booking} />
        <ReservationTrip booking={booking} />
      </div>

      <div className="reservationInfo__twoFrOneFr">
        <ReservationManager user={admin} />
        <section className="reservationPrice">
          <h3>Costo del servicio</h3>
          <div className="reservationPrice__container">
            <h2>${booking?.paymentInfo?.priceInDollars}</h2>
            <span>+IVA</span>
          </div>
        </section>
      </div>
    </section>
  );
}

export default ReservationInfo;
