import { useState, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { useCreatePoint, useUpdatePoints } from "../../../hooks/usePoints";
import {
  createPointStates,
  FormEvents,
  FormState,
} from "../../../states/pointStates";

import NewStepProcessBar from "../../../components/newStepProcessBar";
import Overlays from "../../../components/modals/overlays";
import PopUp from "../../../components/modals/popUp";
import AddPointsMap from "../../../components/forms/AddPoints/AddPointsMap";
import AddPointsInformation from "../../../components/forms/AddPoints/AddPointsInformation";
import AddPointsPicture from "../../../components/forms/AddPoints/AddPointsPicture";
import LazyLoading from "../../../components/LazyLoading";
import Header from "../../../components/Header";
import GoBackButton from "../../../components/GoBackButton";

const goBackText = {
  titles: [
    "Cree el punto de interés en el mapa",
    "Complete la información del Punto de Interés",
    "Creando punto de interés",
    "Añada una imagen para el punto de interés",
    "Creando punto de interés",
  ],
  subtitles: [
    "Haz click izquierdo en el lugar del mapa donde será el punto de interés",
    "Rellene los campos con la información del nuevo punto de interés según sea necesario",
    "Espere mientras se crea el Punto de Interés",
    "Esta imagen se mostrará a los usuarios. Puede editarla más adelante.",
    "Esto podría tardar unos minutos...",
  ],
};

function AddPoints() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(null);
  const [pointLocation, setPointLocation] = useState(null);
  const [validInformation, setValidInformation] = useState({
    isDirty: false,
    isValid: false,
  });
  const [popUp, setPopUp] = useState({
    informative: false,
    warning: false,
    positive: false,
  });

  const [state, dispatch] = useReducer(
    createPointStates,
    FormState.PointLocation
  );

  const { point, createPointError, createPoint } = useCreatePoint();
  const { pointPicture, updatePointError, updatePointImage } =
    useUpdatePoints();

  const updateState = () => {
    if (state === FormState.PointLocation) {
      dispatch(FormEvents.SelectPoint);
    } else if (state === FormState.PointInformation) {
      dispatch(FormEvents.CreatePoint);
    } else if (state === FormState.PointImage) {
      dispatch(FormEvents.UploadImage);
    }
  };

  useEffect(() => {
    if (state === FormState.CreatingPoint) {
      setPopUp({ ...popUp, informative: false });
      let point = { ...formData, location: pointLocation };
      createPoint(point);
    } else if (state === FormState.UploadingImage) {
      setPopUp({ ...popUp, informative: false });
      updatePointImage(formData, point._id);
    } else if (state === FormState.Completed) {
      setPopUp({ ...popUp, positive: true });
    }
  }, [state, formData]);

  useEffect(() => {
    if (updatePointError) {
      dispatch(FormEvents.UploadImageFail);
    }
  }, [updatePointError]);

  useEffect(() => {
    if (createPointError) {
      dispatch(FormEvents.CreatePointFail);
    }
  }, [createPointError]);

  useEffect(() => {
    if (pointPicture) {
      dispatch(FormEvents.UploadImageSuccess);
      setPopUp({ ...popUp, positive: true });
    }
  }, [pointPicture]);

  useEffect(() => {
    if (point) {
      dispatch(FormEvents.CreatePointSuccess);
    }
  }, [point]);

  return (
    <section className="formLayout">
      <div className="formLayout__title">
        <Header name="Puntos de Interés" />
      </div>
      <div className="formLayout__container">
        <div className="formLayout__subtitle">
          <GoBackButton
            onClick={() => {
              setPopUp({ ...popUp, warning: true });
            }}
            title="Complete la Información del Punto de interés"
            subtitle="Rellene la información del nuevo punto según se le solicita"
          />
          <NewStepProcessBar
            stepsNumber={FormState.Completed}
            actualStep={state + 1}
          />
          <p className="form__steps">
            <strong>Paso {state + 1}</strong> de 6
          </p>
        </div>
        <div className="formLayout__form">
          <AddPointsMap
            state={state}
            formState={FormState}
            setPointLocation={setPointLocation}
          />
          <AddPointsInformation
            state={state}
            formState={FormState}
            createPoint={setFormData}
            validPoint={setValidInformation}
          />
          <AddPointsPicture
            state={state}
            formState={FormState}
            createUser={setFormData}
            validImages={setValidInformation}
            uploadPictures={setFormData}
          />

          {(state === FormState.CreatingPoint ||
            state === FormState.UploadingImage) && <LazyLoading />}
        </div>
        <div className="formLayout__buttons">
          {state === FormState.PointLocation && (
            <button
              className="action-button action-button--primary"
              onClick={() => updateState()}
              disabled={!pointLocation}
            >
              Continuar
            </button>
          )}
          {(state === FormState.PointInformation ||
            state === FormState.PointImage) && (
            <button
              className="action-button action-button--primary"
              form={
                state === FormState.PointInformation
                  ? "point-information"
                  : "poi-picture"
              }
              onClick={() => setPopUp({ ...popUp, informative: true })}
              disabled={!validInformation.isDirty || !validInformation.isValid}
            >
              Continuar
            </button>
          )}
        </div>
      </div>
      <Overlays>
        {popUp.warning && (
          <PopUp
            type={"warning"}
            title={"¿Desea salir?"}
            subtitle={
              "Si sales ahora, perderás todo avance e información que has escrito hasta el momento."
            }
          >
            <button
              className="alert-button alert-button--secondary"
              onClick={() => navigate(-1)}
            >
              Sí
            </button>
            <button
              className="alert-button alert-button--primary"
              onClick={() => setPopUp({ ...popUp, warning: false })}
            >
              No
            </button>
          </PopUp>
        )}
        {popUp.informative && (
          <PopUp
            type={"informative"}
            title={"¿Está todo listo?"}
            subtitle={`Al presionar "Sí" se creará el nuevo punto de interés`}
          >
            <button
              className="action-button action-button--secondary"
              onClick={() => updateState()}
            >
              Sí
            </button>
            <button
              className="action-button action-button--primary"
              onClick={() => setPopUp({ ...popUp, informative: false })}
            >
              No
            </button>
          </PopUp>
        )}
        {popUp.positive && (
          <PopUp
            type={"positive"}
            title={"¡El punto se ha creado correctamente!"}
            subtitle={`Al presionar "Aceptar" volverá a la vista principal`}
          >
            <button
              className="action-button action-button--primary"
              onClick={() => navigate(-1)}
            >
              Aceptar
            </button>
          </PopUp>
        )}
      </Overlays>
    </section>
  );
}

export default AddPoints;
