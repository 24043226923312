import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useReadRideStats, useReadRides } from "../../../hooks/useRides";
import { useReadUsers } from "../../../hooks/useUsers";

import Overlays from "../../../components/modals/overlays";
import PopUp from "../../../components/modals/popUp";
import Chat from "../../../components/chat";
import ChatIcon from "../../../assets/svg/ChatIcon";
import StartTripInformationDriver from "../../../components/startTrip/StartTripInformationDriver";
import GoBackButton from "../../../components/GoBackButton";
import routes from "../../../models/routes";
import StartTripDriverMap from "../../../components/startTrip/StartTripDriverMap";

import { io } from "socket.io-client";

const socketMessage = io("https://nosara-shuttle-api.onrender.com/ride-sharing", { transports: ["websocket"] });

const DriverStartTrip = () => {
  const navigate = useNavigate();
  const { rideId } = useParams();
  const { ride, getRideById } = useReadRides();
  const { updateRide } = useReadRideStats();
  const { user, getUserById } = useReadUsers();

  const [popUp, setPopup] = useState({
    warning: false,
    informative: false,
    isDriverArriving: false,
    touristNotified: false,
    isTripFinished: false,
  });
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [routeCoordinates, setRouteCoordinates] = useState();
  const [isPassengerPicked, setIsPassengerPicked] = useState(false);
  const [tripState, setTripState] = useState("");

  const handleUpdateRide = () => {
    if (tripState === "In Route") {
      updateRide(rideId, { status: "Driver Waiting" });
      setPopup((prevPopUp) => ({ ...prevPopUp, isDriverArriving: false }));
      setPopup((prevPopUp) => ({ ...prevPopUp, touristNotified: true }));
      setTripState("Driver Waiting");
    }

    if (tripState === "Driver Waiting") {
      updateRide(rideId, { status: "Trip In Progress" });
      setPopup((prevPopUp) => ({ ...prevPopUp, isPassengerOnShuttle: false }));
      setTripState("Trip In Progress");
    }

    if (tripState === "Trip In Progress") {
      socketMessage.emit("driver_update", { status: "Completed" })
      updateRide(rideId, { status: "Completed" });
      setPopup((prevPopUp) => ({ ...prevPopUp, isTripFinished: false }));

      setTimeout(() => {
        navigate(-2)
      }, 3000)
    }
  };

  const cancelRide = () => {
    if (popUp.warning) {
      updateRide(rideId, { status: "Cancelled" });
      setPopup((prevPopUp) => ({ ...prevPopUp, warning: false }));
      setTripState("Cancelled");
      navigate(-2)
    }

    setPopup({ ...popUp, warning: true });
  };

  const goNext = () => {
    if (tripState === "In Route" && !popUp.isDriverArriving) {
      return setPopup({ ...popUp, isDriverArriving: true });
    }

    if (tripState === "Trip In Progress" && !popUp.isTripFinished) {
      return setPopup({ ...popUp, isTripFinished: true });
    }
  };

  useEffect(() => {
    getRideById(rideId);
  }, []);

  useEffect(() => {
    if (ride) {
      console.log(ride);
      setTripState(ride.status);
      getUserById(ride.tourist);
    }
  }, [ride]);

  useEffect(() => {
    if (ride) {
      const routeSites = ride.booking?.ticket?.route;
      setRouteCoordinates(routes.find((route) => route.name === routeSites));
    }
  }, [ride]);

  return (
    <section className="startTrip">
      <div className="startTrip__container">
        <div className="startTrip__navbar">
          <GoBackButton
            title={"Inicio del viaje"}
            subtitle={"Hemos notificado al turista que ya ha iniciado el viaje"}
            onClick={() => navigate(-2)}
          />
          <button
            aria-label={`Chat con ${`${user?.name} ${user?.lastName}`}`}
            className="startTrip__buttonOnNavbar action-button action-button--primary"
            onClick={() => setIsChatOpen(true)}
          >
            <ChatIcon />
          </button>
        </div>
        {user && (
          <button
            aria-label={`Chat con ${`${user?.name} ${user?.lastName}`}`}
            className="startTrip__buttonOnMap action-button action-button--primary"
            onClick={() => setIsChatOpen(true)}
          >
            <ChatIcon />
          </button>
        )}
        {ride && (
          <StartTripDriverMap
            rideId={rideId}
            routeCoordinates={routeCoordinates}
            mapRoom={ride?.mapRoom}
            tripState={tripState}
            setTripState={setTripState}
          />
        )}
      </div>
      {ride && user && (
        <StartTripInformationDriver
          isPassengerPicked={isPassengerPicked}
          user={user}
          ticket={ride.booking.ticket}
          setIsChatOpen={setIsChatOpen}
          tripState={tripState}
          goNext={goNext}
          cancelRide={cancelRide}
        />
      )}
      <Overlays>
        {popUp.warning && (
          <PopUp
            type={"warning"}
            title={"¿Deseas cancelar el viaje?"}
            subtitle={`Al presionar sí, se guardará este viaje como cancelado y no se podrá volver a iniciar`}
          >
            <button
              className="alert-button alert-button--secondary"
              onClick={cancelRide}
            >
              Sí
            </button>
            <button
              className="alert-button alert-button--primary"
              onClick={() => setPopup({ ...popUp, warning: false })}
            >
              No
            </button>
          </PopUp>
        )}
        {popUp.isDriverArriving && (
          <PopUp
            type={"informative"}
            title={"¿Has llegado al destino?"}
            subtitle={`Al presionar "Sí" le notificaremos al turista que has llegado al destino`}
          >
            <button
              className="action-button action-button--secondary"
              onClick={handleUpdateRide}
            >
              Sí
            </button>
            <button
              className="action-button action-button--primary"
              onClick={() => setPopup({ ...popUp, isPassengerOnShuttle: false })}
            >
              No
            </button>
          </PopUp>
        )}
        {popUp.touristNotified && (
          <PopUp
            type={"informative"}
            title={"Esperando la confirmación del turista"}
            subtitle={`Le hemos notificado al turista que has llegado al destino`}
          >
            <button
              className="action-button action-button--secondary"
              onClick={() => setPopup((prevPopUp) => ({ ...prevPopUp, touristNotified: false }))}
            >
              Aceptar
            </button>
          </PopUp>
        )}
        {popUp.isTripFinished && (
          <PopUp
            type={"informative"}
            title={"¿Los pasajeros ya llegaron a su destino?"}
            subtitle={`Selecciona la opción "Sí" si los turistas ya llegaron a su destino`}
          >
            <button
              className="action-button action-button--secondary"
              onClick={handleUpdateRide}
            >
              Sí
            </button>
            <button
              className="action-button action-button--primary"
              onClick={() => setPopup({ ...popUp, isTripFinished: false })}
            >
              No
            </button>
          </PopUp>
        )}
        {user && ride && (
          <Chat
            room={ride.mapRoom}
            user={user}
            isChatOpen={isChatOpen}
            setIsChatOpen={setIsChatOpen}
            role={"Driver"}
          />
        )}
        {window.innerWidth > 810 && (
          <PopUp
            type={"warning"}
            title={"No puedes iniciar el viaje desde una computadora"}
            subtitle={`Esta vista solo está disponible en dispositivos móviles. Accede de nuevo desde un teléfono celular`}
          >
            <button
              className="alert-button alert-button--secondary"
              onClick={() => navigate(-1)}
            >
              Regresar a la pantalla de viajes
            </button>
          </PopUp>
        )}
      </Overlays>
    </section>
  );
};

export default DriverStartTrip;
