import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { PhoneInput } from "react-international-phone";

const EditPointInformation = ({ data, setFormData }) => {

  const { register, reset, control, formState: { errors, isValid }, handleSubmit } = useForm();

  const onSubmit = (point) => {
    const newPoint = {
      name: point.name,
      description: point.description,
      info: {
        phone: point.phone,
        website: point.website,
      }
    }
    setFormData(newPoint);
  }

  useEffect(() => {
    if (data) {
      const newData = {
        name: data.name,
        description: data.description,
        phone: data.info.phone,
        website: data.info.website,
      }
      reset(newData);
    }
  }, [reset, data])

  return (
    <>
      <h2 className="edit-popup__subtitle">Información del Punto de Interés</h2>
      <form
        className="edit-popup__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="edit-popup__form-wrapper">
          <div className="form__two_columns">
            <div className="form__input_container">
              <label htmlFor="">Nombre</label>
              <input
                type="text"
                placeholder="Escribe su nombre"
                {...register("name", {
                  required: "Es necesario un nombre",
                })}
              />
            </div>
            <div className="form__input_container">
              <label htmlFor="">Teléfono</label>
              <Controller
                render={({ field: { onChange } }) => (
                  <PhoneInput
                    inputStyle={errors.phone ? { borderColor: "#F55555" } : { borderColor: "#7C7C7C" }}
                    defaultCountry="cr"
                    value={data?.info?.phone}
                    onChange={(phone, country) => {
                      onChange(phone);
                    }}
                  />
                )}
                control={control}
                name="phone"
                rules={{ required: true, minLength: 5 }}
              />
            </div>
          </div>
          <div className="form__input_container">
            <label htmlFor="">Web del sitio</label>
            <input
              type="tex"
              placeholder="Escribe su web"
              {...register("website", {
                required: "Es necesaria una web",
              })}
            />
          </div>
          <div className="form__input_container">
            <label htmlFor="">Información del sitio</label>
            <textarea
              type="text"
              placeholder="Escribe la descripción"
              {...register("description", {
                required: "Es necesario una descripción",
              })}
            />
          </div>
        </div>
        <button
          type="submit"
          className="edit-popup__button action-button action-button--primary"
          disabled={!isValid}
        >
          Guardar cambios
        </button>
      </form>
    </>
  );
}

export default EditPointInformation