import { useState } from "react";
import useAxiosPrivate from "./useAxiosPrivate";

export function useReadUsers() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [users, setUsers] = useState(null);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  const getUsers = async (role, limit, page, secondRole, lastName) => {
    let params = new URLSearchParams();

    if (role) params.append('role', role);
    if (secondRole) params.append('role', secondRole);
    if (limit) params.append('limit', limit);
    if (page) params.append('page', page);
    if (lastName) params.append('lastName', lastName);

    const url = `user?${params.toString()}`;

    try {
      const response = await axiosPrivate.get(
        url
        ,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      );
      if (isMounted) {
        setUsers(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
        setUsers(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  const getUserById = async (userId) => {
    setUser(null);

    try {
      const response = await axiosPrivate.get(
        `user/${userId}`,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      );
      if (isMounted) {
        setUser(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
        setUser(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  return { user, users, error, getUsers, getUserById };
}

export function useUpdateUsers() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [updatedUser, setUpdatedUser] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [updateUserError, setUpdateUserError] = useState(null);

  const updateUser = async (user, userId) => {
    setUpdateUserError(null);
    setUpdatedUser(null);

    try {
      const response = await axiosPrivate.patch(
        `user/${userId}/update`,
        user,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      );
      if (isMounted) {
        setUpdatedUser(response?.data)
      }
    } catch (error) {
      if (isMounted) {
        setUpdateUserError(error);
      }
      console.log(error)
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  const uploadProfilePicture = async (imageData, userId) => {
    const formData = new FormData();
    formData.append('image', imageData[0]);

    try {
      const response = await axiosPrivate.patch(
        `user/${userId}/upload`,
        formData
      );
      if (isMounted) {
        setProfilePicture(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setUpdateUserError(error);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  return { updatedUser, profilePicture, updateUserError, updateUser, uploadProfilePicture };
}

export function useCreateUsers() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [user, setUser] = useState(null);
  const [createUserError, setUserError] = useState(null);

  const createUser = async (user) => {
    setUser(null)
    setUserError(false);

    try {
      const response = await axiosPrivate.post(
        'user/create',
        user,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      );
      if (isMounted) {
        setUser(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setUserError(error);
        setUser(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  return { user, createUserError, createUser };
}

export function useDeleteUser() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [userDeleted, setUserDeleted] = useState(null)
  const [deleteUserError, setError] = useState(null);

  const deleteUser = async (userId) => {
    setUserDeleted(null);

    try {
      const response = await axiosPrivate.delete(
        `user/${userId}/delete`,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      );
      if (isMounted) {
        setUserDeleted(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  const deleteAccount = async () => {
    try {
      const response = await axiosPrivate.delete(
        `user/delete`,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      );
      if (isMounted) {
        setUserDeleted(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  }

  return { userDeleted, deleteUserError, deleteUser, deleteAccount };
}