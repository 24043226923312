export const PageSelector = ({ currentPage, maxPages, pageNumber, selectPage }) => {
    const atStartOrEnd = pageNumber === 1 || pageNumber === maxPages;
    const inShortRange = pageNumber >= currentPage - 1 && pageNumber <= currentPage + 2;
    const inMediumRange = pageNumber >= currentPage - 5 && pageNumber <= currentPage + 5;
    const onCurrentPage = pageNumber === currentPage;
  
    let classNames = "hidden";
    if (onCurrentPage) {
      classNames = "page-selector-container selected";
    } else if (inShortRange || atStartOrEnd) {
      classNames = "page-selector-container";
    } else if (inMediumRange) {
      classNames = "page-selector-container minimized";
    }
  
    return <div className={classNames} onClick={() => selectPage(pageNumber)}>{pageNumber}</div>;
};