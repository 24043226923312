import React from 'react'

const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 800 800"
      fill="none"
    >
      <path
        d="M494.957 400.017L792.088 102.905C802.637 92.3541 802.637 75.2802 792.088 64.7328L735.302 7.92586C730.245 2.87176 723.381 0.026123 716.218 0.026123C709.052 0.026123 702.189 2.87176 697.132 7.92586L400.001 305.06L102.87 7.92586C97.8127 2.87176 90.9494 0.026123 83.7841 0.026123C76.6205 0.026123 69.7556 2.87176 64.6997 7.92586L7.91368 64.7328C-2.63724 75.2802 -2.63724 92.3541 7.91368 102.905L305.044 400.018L7.94319 697.095C-2.60252 707.646 -2.60252 724.72 7.94319 735.267L64.7327 792.074C69.7868 797.128 76.6518 799.974 83.8171 799.974C90.9824 799.974 97.8456 797.128 102.905 792.074L399.999 494.975L697.095 792.074C702.153 797.128 709.016 799.974 716.181 799.974C723.348 799.974 730.212 797.128 735.266 792.074L792.055 735.267C802.601 724.72 802.601 707.646 792.055 697.095L494.957 400.017Z"
        fill="white"
      />
    </svg>
  );
}

export default CloseIcon