import { useReadDrivers } from "../../../hooks/useDrivers";
import { useEffect } from "react";
import { languagesEs, transformToStringLanguages } from "../../../models/languages";
import { gendersEs, transformToStringGender } from "../../../models/genders";

import userExample from "../../../assets/icons/user.png";
import Header from "../../../components/Header";
import TripStats from "../../../components/tripStats";

function DriverProfile() {
  const { driver, getDriverProfile } = useReadDrivers();

  useEffect(() => {
    getDriverProfile();
  }, []);

  return (
    <section className="userProfile">
      <div className="userProfile__title">
        <Header name="Mi perfil" />
      </div>
      <section className="userProfile__body">
        <div className="userProfile__image">
          <img
            src={driver?.user?.profilePicture ? driver?.user?.profilePicture : userExample}
            alt="Foto del conductor"
          />
          <p className="userProfile__id">
            ID Conductor
            <strong>{driver?._id}</strong>
          </p>
        </div>
        <div className="userProfile__info">
          <div className="two-columns">
            <div className="two-rows">
              <p className="userProfile__info_row">
                Nombre Completo
                <span>{`${driver?.user?.name} ${driver?.user?.lastName}`}</span>
              </p>
              <p className="userProfile__info_row">
                Género
                <span>{driver && transformToStringGender(driver?.user?.gender, gendersEs)}</span>
              </p>
            </div>
            <div className="two-rows">
              <p className="userProfile__info_row">
                Teléfono
                <span>{driver?.user?.phone}</span>
              </p>
              <p className="userProfile__info_row">
                Correo
                <span>{driver?.user?.email}</span>
              </p>
            </div>
            <p className="userProfile__info_row">
              Idiomas
              <span>{driver && transformToStringLanguages(driver?.user?.languages, languagesEs)}</span>
            </p>
          </div>
        </div>
        {driver && <TripStats driverId={driver._id} />}
      </section>
    </section>
  );
}

export default DriverProfile;
