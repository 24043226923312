import { useState, useEffect } from "react";
import { useUpdateUsers } from "../../../hooks/useUsers";

import EditManagerInformation from "./EditManagerInformation";
import EditManagerAccount from "./EditManagerAccount";
import EditManagerPicture from "./EditManagerPicture";
import LazyLoading from "../../../components/LazyLoading";
import GoBackButton from "../../GoBackButton";

const EditManager = ({ manager, close }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [formImage, setFormImage] = useState(null);
  const [state, setState] = useState({ success: false, fail: false })
  const [category, setCategory] = useState({
    information: true,
    account: false,
    documents: false,
  });

  const { updatedUser, profilePicture, updateUserError, updateUser, uploadProfilePicture } = useUpdateUsers();

  const handleCategory = (activeCategory) => {
    const initialValue = {
      information: false,
      account: false,
      documents: false,
    };
    setState({ success: false, fail: false })

    const updatedCategories = { ...initialValue };
    updatedCategories[activeCategory] = true;

    setCategory(updatedCategories);
  };

  useEffect(() => {
    if (manager) {
      setUser(manager)
    }
  }, [manager])

  useEffect(() => {
    if (updatedUser) {
      setUser(updatedUser)
    }
  }, [updatedUser])

  useEffect(() => {
    if (formData) {
      setIsLoading(true);
      updateUser(formData, manager._id);
    }
  }, [formData])

  useEffect(() => {
    if (formImage) {
      setIsLoading(true);
      uploadProfilePicture(formImage, manager._id);
    }
  }, [formImage])

  useEffect(() => {
    if (updatedUser || profilePicture) {
      setState({ success: true, fail: false })
      setIsLoading(false);
    } else if (updateUserError) {
      setState({ success: false, fail: true })
      setIsLoading(false);
    }
  }, [updatedUser, profilePicture, updateUserError])

  return (
    <section className="edit-popup">
      <div className="edit-popup__box">
        <div className="edit-popup__container">
          <div className="edit-popup__header">
            <GoBackButton
              title="Edite la información del Administrador"
              subtitle="Edite la información del Administrador según sea necesario"
              onClick={close}
              isClose
            />
            {state.success && (
              <p className="edit-popup__status edit-popup__status--success">Cambios guardados con éxito</p>
            )}
            {state.fail && <p className="edit-popup__status edit-popup__status--error">Cambios no procesados</p>}
          </div>
          {!isLoading && (
            <>
              <div className="edit-popup__category">
                <button
                  className={`category-button ${category.information ? "category-button--active" : ""}`}
                  onClick={() => handleCategory("information")}
                >
                  Información
                </button>
                <button
                  className={`category-button ${category.account ? "category-button--active" : ""}`}
                  onClick={() => handleCategory("account")}
                >
                  Cuenta
                </button>
                <button
                  className={`category-button ${category.documents ? "category-button--active" : ""}`}
                  onClick={() => handleCategory("documents")}
                >
                  Documentos
                </button>
              </div>
              {(category.information && user) && (
                <EditManagerInformation
                  data={user}
                  setFormData={setFormData} />
              )}
              {(category.account && user) && (
                <EditManagerAccount
                  data={user}
                  setFormData={setFormData} />
              )}
              {(category.documents && user) && (
                <EditManagerPicture
                  data={user}
                  setFormData={setFormImage} />
              )}
            </>
          )}
          {isLoading && <LazyLoading />}
        </div>
      </div>
    </section>
  );
};

export default EditManager;
