import ChatIcon from "../../assets/svg/ChatIcon";

const MeetWith = ({ user, setIsChatOpen }) => {


  return (
    <section className="meetWith">
      <h4>Reúnete con:</h4>
      <div className="meetWith__container">
        <button
          aria-label={`Chat con ${`${user?.name} ${user?.lastName}`}`}
          className="action-button action-button--primary"
          onClick={() => setIsChatOpen(true)}
        >
          <ChatIcon />
        </button>
        <div className="meetWith__user">
          <img
            src={user?.profilePicture ? user?.profilePicture : require("../../assets/icons/user.png")}
            alt="Imagen del cliente"
          />
          <div className="meetWith__userName">
            <strong>{`${user?.name} ${user?.lastName}`}</strong>
            <p>{user?.phone}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MeetWith;
