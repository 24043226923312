import { useState, useEffect } from "react";
import { useUpdateUsers } from "../../../hooks/useUsers";
import { useUpdateDrivers } from "../../../hooks/useDrivers";

import EditDriverInformation from "./EditDriverInformation";
import EditDriverAccount from "./EditDriverAccount";
import EditDriverDocuments from "./EditDriverDocuments";
import LazyLoading from "../../../components/LazyLoading";
import GoBackButton from "../../GoBackButton";

const EditDriver = ({ driver, driverId, close }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [formImage, setFormImage] = useState(null);
  const [state, setState] = useState({ success: false, fail: false })
  const [category, setCategory] = useState({ information: true, account: false, documents: false });

  const { updatedUser, profilePicture, updateUserError, updateUser, uploadProfilePicture } = useUpdateUsers();
  const { licensePicture, updateDriverError, uploadLicensePicture } = useUpdateDrivers();

  const handleCategory = (activeCategory) => {
    const initialValue = { information: false, account: false, documents: false };
    setState({ success: false, fail: false })

    const updatedCategories = { ...initialValue };
    updatedCategories[activeCategory] = true;

    setCategory(updatedCategories);
  };

  useEffect(() => {
    if (driver) {
      setUser(driver)
    }
  }, [driver])

  useEffect(() => {
    if (updatedUser) {
      setUser(updatedUser)
    }
  }, [updatedUser])

  useEffect(() => {
    if (formData) {
      setIsLoading(true);
      updateUser(formData, user._id);
    }
  }, [formData])

  useEffect(() => {
    if (formImage?.profilePicture) {
      setIsLoading(true);
      uploadProfilePicture(formImage.profilePicture, user._id);
    }

    if (formImage?.licensePicture) {
      setIsLoading(true);
      uploadLicensePicture(formImage.licensePicture, driverId);
    }
  }, [formImage])

  useEffect(() => {
    if (updatedUser || profilePicture || licensePicture) {
      setState({ success: true, fail: false })
      setIsLoading(false);
    } else if (updateUserError || updateDriverError) {
      setState({ success: false, fail: true })
      setIsLoading(false);
    }
  }, [updatedUser, profilePicture, licensePicture, updateUserError, updateDriverError])

  return (
    <section className="edit-popup">
      <div className="edit-popup__box">
        <div className="edit-popup__container">
          <div className="edit-popup__header">
            <GoBackButton
              title="Edite la información del Conductor"
              subtitle="Edite la información del Conductor según sea necesario"
              onClick={close}
              isClose
            />
            {state.success && (
              <p className="edit-popup__status edit-popup__status--success">Cambios guardados con éxito</p>
            )}
            {state.fail && <p className="edit-popup__status edit-popup__status--error">Cambios no procesados</p>}
          </div>
          {!isLoading && (
            <>
              <div className="edit-popup__category">
                <button
                  className={`category-button ${category.information ? "category-button--active" : ""}`}
                  onClick={() => handleCategory("information")}
                >
                  Información
                </button>
                <button
                  className={`category-button ${category.account ? "category-button--active" : ""}`}
                  onClick={() => handleCategory("account")}
                >
                  Cuenta
                </button>
                <button
                  className={`category-button ${category.documents ? "category-button--active" : ""}`}
                  onClick={() => handleCategory("documents")}
                >
                  Documentos
                </button>
              </div>
              {(category.information && user) && (
                <EditDriverInformation
                  data={user}
                  setFormData={setFormData}
                />
              )}

              {(category.account && user) && (
                <EditDriverAccount
                  data={user}
                  setFormData={setFormData}
                />
              )}

              {(category.documents && user) && <EditDriverDocuments data={user} setFormData={setFormImage} />}
            </>
          )}
          {isLoading && <LazyLoading />}
        </div>
      </div>
    </section>
  );
};

export default EditDriver;
