import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUpdateRides } from "../../../hooks/useRides";
import { useReadBookings } from "../../../hooks/useBookings";
import { useReadVehicles } from "../../../hooks/useVehicles";
import { useReadDrivers } from "../../../hooks/useDrivers";
import { useReadUsers } from "../../../hooks/useUsers";
import Overlays from "../../../components/modals/overlays";
import PopUp from "../../../components/modals/popUp";
import Header from "../../../components/Header";
import GoBackButton from "../../../components/GoBackButton";
import ReservationInfo from "../../../components/reservation/ReservationInfo";
import LazyLoading from "../../../components/LazyLoading";

function DriverTripRoute() {
  const navigate = useNavigate();

  const { id } = useParams();
  const { booking, getBookingById } = useReadBookings();
  const { vehicle, getVehicleById } = useReadVehicles();
  const { driver, getDriverById } = useReadDrivers();
  const { user, getUserById } = useReadUsers();
  const { rideUpdated, startRide } = useUpdateRides();

  const [popUp, setPopup] = useState({
    informative: false,
    warning: false,
  });
  const [isLoading, setIsLoading] = useState();

  const goNext = () => {
    if (popUp.informative) {
      startRide(booking.ride._id);
      setPopup({ ...popUp, informative: false });
    } else {
      setPopup({ ...popUp, informative: true });
    }
  };

  useEffect(() => {
    if (id) {
      getBookingById(id);
    }
  }, [id]);

  useEffect(() => {
    if (booking) {
      if (
        booking?.ride?.status !== "Pending" &&
        booking?.ride?.status !== "Completed" &&
        booking?.ride?.status !== "Cancelled"
      ) {
        navigate(`start/${booking.ride._id}`);
      } else {
        getVehicleById(booking.ride.vehicle);
        getDriverById(booking.ride.driver);
        getUserById(booking.ride.assignedBy);
        setIsLoading(false);
      }
    }
  }, [booking]);

  useEffect(() => {
    if (rideUpdated) {
      navigate(`start/${booking.ride._id}`);
    }
  }, [rideUpdated]);

  return (
    <section className="formLayout">
      <section className="formLayout__title">
        <Header name="Viajes Asignados" />
      </section>
      <section className="formLayout__container">
        <div className="formLayout__subtitle">
          <GoBackButton
            onClick={() => {
              navigate(-1);
            }}
            title="Consulte los detalles del viaje"
            subtitle="Verifique los detalles del viaje antes de comenzarlo"
          />
        </div>
        <div className="formLayout__form">
          {isLoading && <LazyLoading />}
          {!isLoading && booking && (
            <ReservationInfo
              vehicleSelected={vehicle}
              driverSelected={driver}
              booking={booking}
              admin={user}
            />
          )}
        </div>
      </section>
      <section className="formLayout__buttons">
        <button
          className="action-button action-button--primary"
          onClick={goNext}
        >
          Empezar el viaje
        </button>
      </section>

      <Overlays>
        {popUp.informative && (
          <PopUp
            type={"informative"}
            title={"¿Deseas empezar?"}
            subtitle={`Al presionar "Sí" se comenzará el viaje, y hasta que no se complete, no se podrá salir de este`}
          >
            <button
              className="action-button action-button--secondary"
              onClick={goNext}
            >
              Sí
            </button>
            <button
              className="action-button action-button--primary"
              onClick={() => setPopup({ ...popUp, informative: false })}
            >
              No
            </button>
          </PopUp>
        )}
      </Overlays>
    </section>
  );
}

export default DriverTripRoute;
