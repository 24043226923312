import { useNavigate } from "react-router-dom";
import NoUserImage from "../../assets/icons/user.png";

const PointTable = ({ selected, data }) => {
  const navigate = useNavigate();

  const isMobileView = window.innerWidth <= 810;

  const handleCard = (user) => {
    if (isMobileView) {
      navigate(user?._id);
    } else {
      selected(user);
    }
  };

  return (
    <section className="dataTable">
      <section className="dataTable__header">
        <p>Nombre</p>
        <p>Tipo</p>
        <p>Teléfono</p>
      </section>
      <section className="dataTable__body">
        {data?.data?.map((point, i) => (
          <div
            key={point._id}
            className="dataTable__row"
            onClick={() => handleCard(point)}
          >
            <img
              src={point.image ? point.image : NoUserImage}
              alt="Imagen del punto de interés"
            />
            <div className="dataTable__rowItems">
              <p>{point?.name}</p>
              <p>{point?.type}</p>
              <p>{point?.info?.phone}</p>
            </div>
            <div className="dataTable__rowItems--mobile">
              <p>
                {point?.name}
              </p>
              <p>{point?.type}</p>
              <p>{point?.info?.phone}</p>
            </div>
            <div className="dataTable__arrow">
              <svg
                width="18"
                height="25"
                viewBox="0 0 10 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.70557 15.4904L8.58643 8.60959L1.70557 1.72873"
                  stroke="#959595"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ))}
      </section>
    </section>
  );
};

export default PointTable;
