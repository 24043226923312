import { useState } from "react";
import useAxiosPrivate from "./useAxiosPrivate";

export function useReadRideStats() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [totalTrips, setTotalTrips] = useState(null);
  const [driverTenure, setDriverTenure] = useState(null);
  const [tripsByDate, setTripsByDate] = useState(null);
  const [tripsDetailByDate, setTripsDetailByDate] = useState(null);

  const [tripsDetailByDateLoading, setTripsDetailByDateLoading] =
    useState(null);
  const [rideStatsError, setError] = useState(null);

  const getTotalTrips = async (driverId) => {

    try {
      const response = await axiosPrivate.get(
        `ride-stats/total-trips/${driverId}`,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8"
          },
        }
      );
      if (isMounted) {
        setTotalTrips(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
        setTotalTrips(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    };

    return cleanUp;
  };

  const getDriverTenure = async (driverId) => {

    try {
      const response = await axiosPrivate.get(
        `ride-stats/driver-tenure/${driverId}`,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8"
          },
        }
      );
      if (isMounted) {
        setDriverTenure(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
        setDriverTenure(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    };

    return cleanUp;
  };

  const getTripsByDate = async (driverId, date) => {

    try {
      const response = await axiosPrivate.get(
        `ride-stats/trips-by-date/${driverId}?from=${date.from}&to=${date.to}`,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8"
          },
        }
      );
      if (isMounted) {
        setTripsByDate(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
        setTripsByDate(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    };

    return cleanUp;
  };

  const getTripsDetailByDate = async (driverId, date) => {
    setTripsDetailByDateLoading(true);

    try {
      const response = await axiosPrivate.get(
        `ride-stats/trips-detail-by-date/${driverId}?date=${date}`,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8"
          },
        }
      );
      if (isMounted) {
        setTripsDetailByDate(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
        setTripsDetailByDate(null);
      }
    } finally {
      isMounted && setTripsDetailByDateLoading(false);
    }

    const cleanUp = () => {
      isMounted = false;
    };

    return cleanUp;
  };

  return {
    totalTrips,
    tripsDetailByDate,
    driverTenure,
    tripsByDate,
    tripsDetailByDateLoading,
    rideStatsError,
    getDriverTenure,
    getTotalTrips,
    getTripsByDate,
    getTripsDetailByDate,
  };
}
