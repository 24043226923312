import GoBackButton from "../GoBackButton";
import EditArticle from "../forms/EditArticle/EditArticle";
import EditCover from "../forms/EditCover/EditCover";
import EditDriver from "../forms/EditDriver/EditDriver";
import EditManager from "../forms/EditManager/EditManager";
import EditPoint from "../forms/EditPoint/EditPoint";
import EditTourist from "../forms/EditTourist/EditTourist";
import EditVehicle from "../forms/EditVehicle/EditVehicle";

const EditPopup = ({ type, user, close }) => {

  return (
    <section className="edit-popup">
      <div className="edit-popup__box">
        <div className="edit-popup__container">
          {type === "driver" && <EditDriver data={user}></EditDriver>}
          {type === "manager" && <EditManager user={user}></EditManager>}
          {type === "vehicle" && <EditVehicle data={user} close={close}></EditVehicle>}
          {type === "tourist" && <EditTourist data={user} close={close}></EditTourist>}
          {type === "point" && <EditPoint user={user}></EditPoint>}
          {type === "cover" && <EditCover close={close} />}
          {type === "article" && <EditArticle data={user} close={close} />}
        </div>
      </div>
    </section>
  );
};

export default EditPopup;
