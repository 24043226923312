import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { PhoneInput } from "react-international-phone";
import { placesEs } from "../../../models/places";

import Select from "react-select";

const AddPointsInformation = ({ state, formState, createPoint, validPoint, }) => {
  const [formType, setFormType] = useState(null);

  const {
    register,
    control,
    formState: { errors, isDirty, isValid },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    const newPoint = {
      name: data.name,
      description: data.description,
      type: data.type,
      info: {
        phone: data.phone,
        website: data.website,
        price: data.price,
        capacity: data.capacity
      },

    }
    createPoint(newPoint);
  };

  useEffect(() => {
    validPoint({ isDirty, isValid });
  }, [isDirty, isValid]);

  return (
    <form
      id="point-information"
      onSubmit={handleSubmit(onSubmit)}
    >
      <section
        style={state === formState.PointInformation ? {} : { display: "none" }}
      >
        <h3>Información General</h3>
        <div className="form__input_container">
          <label htmlFor="">Seleccione que tipo de sitio es</label>
          <Controller
            render={({ field: { onChange } }) => (
              <Select
                classNamePrefix="react-select"
                isClearable={false}
                options={placesEs}
                placeholder="Seleccione el tipo de sitio"
                onChange={(value) => {
                  setFormType(value.value)
                  onChange(value.value);
                }}
              />
            )}
            control={control}
            name="type"
            rules={{ required: true }}
          />
        </div>
        <div className="form__two_columns">
          <div className="form__input_container">
            <label htmlFor="">Nombre del sitio</label>
            <input
              type="text"
              placeholder="Escribe su nombre"
              {...register("name", {
                required: "Es necesario un nombre",
              })}
            />
          </div>
          <div className="form__input_container">
            <label htmlFor="">Web del sitio</label>
            <input
              type="tex"
              placeholder="Escribe su web"
              {...register("website", {
                required: "Es necesaria una web",
              })}
            />
          </div>
        </div>
        <div className="form__two_columns">
          <div className="form__input_container">
            <label htmlFor="">Teléfono</label>
            <Controller
              render={({ field: { onChange } }) => (
                <PhoneInput
                  inputStyle={errors.phone ? { borderColor: "#F55555" } : { borderColor: "#7C7C7C" }}
                  defaultCountry="cr"
                  onChange={(phone, country) => {
                    onChange(phone);
                  }}
                />
              )}
              control={control}
              name="phone"
              rules={{ required: true, minLength: 5 }}
            ></Controller>
          </div>
          {formType === "Real Estate" &&
            <div className="form__input_container">
              <label htmlFor="">Precio</label>
              <input
                type="number"
                placeholder="Escribe el precio"
                {...register("price")}
              />
            </div>
          }
          {formType === "Transportation" &&
            <div className="form__input_container">
              <label htmlFor="">Capacidad</label>
              <input
                type="number"
                placeholder="Escribe el precio"
                {...register("capacity")}
              />
            </div>
          }
        </div>
        <div className="form__input_container">
          <label htmlFor="">Información del sitio</label>
          <textarea
            type="text"
            placeholder="Escribe la descripción"
            {...register("description", {
              required: "Es necesario una descripción",
            })}
          />
        </div>
      </section>
    </form>
  );
};

export default AddPointsInformation;
