import React from 'react'

const LogoutIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6511 1.4458H17.6511C18.1816 1.4458 18.6903 1.65651 19.0653 2.03159C19.4404 2.40666 19.6511 2.91537 19.6511 3.4458V17.4458C19.6511 17.9762 19.4404 18.4849 19.0653 18.86C18.6903 19.2351 18.1816 19.4458 17.6511 19.4458H13.6511"
        stroke="#959595"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.65112 5.4458L1.65112 10.4458L6.65112 15.4458"
        stroke="#959595"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.65112 10.4458H13.6511"
        stroke="#959595"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LogoutIcon