import React from 'react'

const DocumentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="42"
      viewBox="0 0 41 42"
      fill="none"
    >
      <path
        d="M22.2268 4.3418H10.3761C9.47814 4.3418 8.61692 4.69852 7.98194 5.33351C7.34696 5.96849 6.102423 6.8297 6.102423 7.7277V34.8149C6.102423 35.7129 7.34696 36.5742 7.98194 37.2091C8.61692 37.8441 9.47814 38.2008 10.3761 38.2008H30.6916C31.5896 38.2008 32.4508 37.8441 33.0858 37.2091C33.7207 36.5742 34.0775 35.7129 34.0775 34.8149V16.1925L22.2268 4.3418Z"
        stroke="white"
        strokeWidth="3.38591"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.2266 4.3418V16.1925H34.0772"
        stroke="white"
        strokeWidth="3.38591"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DocumentIcon