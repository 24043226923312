import VanImage from "../../assets/images/van-logo.png";

const ReservationTrip = ({ booking }) => {
  return (
    <div className="reservationTrip">
      <h3>Viaje solicitado</h3>
      <div className="reservationTrip__container">
        <img
          src={VanImage}
          alt="Vehículo"
        />
        <div className="reservationTrip__info">
          <p className="reservationTrip__type">{`${booking?.ticket?.mode} Van`}</p>
          <p className="reservationTrip__type">{booking?.ticket?.type}</p>
        </div>
      </div>
    </div>

    //       <section className="tripInformation_content__section">
    //         <h3>Viaje Solicitado</h3>
    //         <div className="tripInformation__vehicle">
    //           <img
    //             src={require("../assets/images/van-logo.png")}
    //             alt="Vehicle icon"
    //           />
    //           <div>
    //             <p>{`${booking?.ticket?.mode} Van`}</p>
    //           </div>
    //           <div>
    //             <p>{booking?.ticket?.type}</p>
    //           </div>
    //         </div>
    //       </section>
  );
};

export default ReservationTrip;
