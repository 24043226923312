import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import { useReadRideStats, useReadRides } from "../../../hooks/useRides";
import { useReadUsers } from "../../../hooks/useUsers";
import GoBackButton from "../../../components/GoBackButton";
import ChatIcon from "../../../assets/svg/ChatIcon";
import StartTripInformationUser from "../../../components/startTrip/StartTripInformationUser";
import Overlays from "../../../components/modals/overlays";
import PopUp from "../../../components/modals/popUp";
import Chat from "../../../components/chat";
import routes from "../../../models/routes";
import StartTripTouristMap from "../../../components/startTrip/StartTripTouristMap";

const socketMessage = io("https://nosara-shuttle-api.onrender.com/ride-sharing", { transports: ["websocket"] });

function UserReservationRoute() {
  const navigate = useNavigate();
  const { rideId } = useParams();
  const { ride, getRideById } = useReadRides();
  const { user, getUserById } = useReadUsers();
  const { updateRide } = useReadRideStats();

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [routeCoordinates, setRouteCoordinates] = useState();
  const [tripState, setTripState] = useState("");
  const [popUp, setPopup] = useState({
    driverStartedTrip: true,
    driverArrived: false,
    isTripFinished: false,
  });

  const confirmRide = () => {
    updateRide(rideId, { status: "Trip In Progress" });
  };

  useEffect(() => {
    getRideById(rideId);
  }, [rideId]);

  useEffect(() => {
    if (ride) {
      const routeSites = ride.booking?.ticket?.route;
      setRouteCoordinates(routes.find((route) => route.name === routeSites));

      getUserById(ride.driver.user._id);
      setTripState(ride.status);

      socketMessage.on("connect", () => {
        console.log("Connect to Message Socket");
      });

      socketMessage.emit("event_join", ride.mapRoom);
    }
  }, [ride]);

  useEffect(() => {
    if (tripState === "Driver Waiting") {
      setPopup((prevPopup) => ({ ...prevPopup, driverArrived: true }));
    }

    if (tripState === "Completed") {
      navigate(-2);
    }
  }, [tripState]);

  return (
    <section className="startTrip">
      <div className="startTrip__container">
        <div className="startTrip__navbar">
          <GoBackButton
            title={"El viaje ha iniciado"}
            subtitle={"El conductor ha comenzado el viaje"}
          />
          <button
            aria-label={`Chat con ${"Alexandro Cabrera"}`}
            className="startTrip__buttonOnNavbar action-button action-button--primary"
            onClick={() => setIsChatOpen(true)}
          >
            <ChatIcon />
          </button>
        </div>
        <button
          aria-label={`Chat con ${"Alexandro Cabrera"}`}
          className="startTrip__buttonOnMap action-button action-button--primary"
          onClick={() => setIsChatOpen(true)}
        >
          <ChatIcon />
        </button>
        {ride && (
          <StartTripTouristMap
            routeCoordinates={routeCoordinates}
            mapRoom={ride.mapRoom}
            tripState={tripState}
            setTripState={setTripState}
          />
        )}
      </div>
      {ride && (
        <StartTripInformationUser
          confirmRide={confirmRide}
          driver={user}
          ticket={ride.booking.ticket}
          setIsChatOpen={setIsChatOpen}
          tripState={tripState}
          setTripState={setTripState}
        />
      )}
      <Overlays>
        {popUp.driverStartedTrip && (
          <PopUp
            type={"informative"}
            title={"El conductor empezó el viaje"}
            subtitle={`El conductor empezó el viaje a la primera parada establecida.`}
          >
            <button
              className="action-button action-button--primary"
              onClick={() => setPopup({ ...popUp, driverStartedTrip: false })}
            >
              Aceptar
            </button>
          </PopUp>
        )}
        {popUp.driverArrived && (
          <PopUp
            type={"informative"}
            title={"El conductor ha llegado al destino"}
            subtitle={`Confirma que entraste al vehículo para comenzar el viaje`}
          >
            <button
              className="action-button action-button--primary"
              onClick={() => setPopup({ ...popUp, driverArrived: false })}
            >
              Aceptar
            </button>
          </PopUp>
        )}
        {isChatOpen && (
          <section className="tripRoute_chat__container">
            <Chat
              room={ride.mapRoom}
              user={user}
              isChatOpen={isChatOpen}
              setIsChatOpen={setIsChatOpen}
              role={"User"}
            />
          </section>
        )}
      </Overlays>
    </section>
  );
}

export default UserReservationRoute;
