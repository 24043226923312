import { useNavigate } from "react-router-dom";
import NoUserImage from "../../assets/icons/user.png";

const ReservationTable = ({ reservationCategory, selected, data }) => {
  const navigate = useNavigate();

  const isMobileView = window.innerWidth <= 810;

  const handleCard = (reservation) => {
    if (isMobileView && reservationCategory === "Pending for Assignment") {
      navigate(`${reservation?._id}/add`);
    } else if (isMobileView && reservationCategory !== "Pending for Assignment") {
      navigate(`${reservation?._id}`);
    } else {
      selected(reservation);
    }
  };

  return (
    <section className="dataTable">
      <section className="dataTable__header four-columns">
        <p>Booking</p>
        <p>Nombre</p>
        <p>Ruta</p>
        <p>Tipo de viaje</p>
      </section>
      <section className="dataTable__body">
        {data?.map((booking, i) => (
          <div
            key={booking._id}
            className="dataTable__row"
            onClick={() => handleCard(booking)}
          >
            <img
              src={NoUserImage}
              alt=""
            />
            <div className="dataTable__rowItems four-columns">
              <p>{booking.bookingNumber}</p>
              <p>{booking.clientInfo.name}</p>
              <p>{booking.ticket.route}</p>
              <p>{booking.ticket.type}</p>
            </div>
            <div className="dataTable__rowItems--mobile">
              <p>{booking.bookingNumber}</p>
              <p>{booking.clientInfo.name}</p>
              <p>{booking.ticket.type}</p>
            </div>
            <div className="dataTable__arrow">
              <svg
                width="18"
                height="25"
                viewBox="0 0 10 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.70557 15.4904L8.58643 8.60959L1.70557 1.72873"
                  stroke="#959595"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ))}
      </section>
    </section>
  );
};

export default ReservationTable;
