import React from "react";

const CategoryButton = ({ isActive, name, ...props }) => {
  return (
    <button
      className={`category-button ${isActive ? "category-button--active" : ""}`}
      {...props}
    >
      {name}
    </button>
  );
};

export default CategoryButton;
