import { useEffect, useState } from "react";


function NewsCard({ filter, news }) {

  const goToLink = (link) => {
    window.open(link, "_blank");
  };

  const [cardListHotels, setCardListHotels] = useState(null);
  const [cardListRestaurants, setCardListRestaurants] = useState(null);
  const [cardListThings, setCardListThings] = useState(null);

  useEffect(() => {
    let tempCardListHotels = news?.filter((card) => card.category === "hotels");
    let tempCardListRestaurants = news?.filter(card => card.category === "restaurants");
    let tempCardListThings = news?.filter((card) => card.category === "things-to-do");

    setCardListHotels(tempCardListHotels);
    setCardListRestaurants(tempCardListRestaurants);
    setCardListThings(tempCardListThings);
  }, [filter, news])

  return (
    <section className="news">
      <div className="news__container">
        {(filter === "" || filter === "hotels") && cardListHotels?.length > 0 && (
          <section className="news__cardSection">
            <h4>Hotels</h4>
            <div className="news__cards">
              {cardListHotels?.map((card, i) => (
                <div
                  key={i}
                  className="card"
                  onClick={() => goToLink(card.contentUrl)}
                >
                  <div className="card__container">
                    <div className="card__header">
                      <img
                        src={card.imageUrl}
                        alt=""
                      />
                    </div>
                    <div className="card__body">
                      <strong className="card__title">{card.title}</strong>
                      <p>{card.description}</p>
                    </div>
                    <div className="card__footer">
                      <div className="two-columns">
                        <button className={"action-button action-button--primary"} onClick={() => goToLink(card.contentUrl)}>
                          <p>Read now</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        )}

        {(filter === "" || filter === "restaurants") && cardListRestaurants?.length > 0 && (
          <section className="news__cardSection">
            <h4>Restaurants</h4>
            <div className="news__cards">
              {cardListRestaurants?.map((card, i) => (
                <div
                  key={i}
                  className="card"
                  onClick={() => goToLink(card.contentUrl)}
                >
                  <div className="card__container">
                    <div className="card__header">
                      <img
                        src={card.imageUrl}
                        alt=""
                      />
                    </div>
                    <div className="card__body">
                      <strong className="card__title">{card.title}</strong>
                      <p>{card.description}</p>
                    </div>
                    <div className="card__footer">
                      <div className="two-columns">
                        <button className={"action-button action-button--primary"} onClick={() => goToLink(card.contentUrl)}>
                          <p>Read now</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        )}

        {(filter === "" || filter === "things-to-do") && cardListThings?.length > 0 && (
          <section className="news__cardSection">
            <h4>Things to do</h4>
            <div className="news__cards">
              {cardListThings?.map((card, i) => (
                <div
                  key={i}
                  className="card"
                  onClick={() => goToLink(card.contentUrl)}
                >
                  <div className="card__container">
                    <div className="card__header">
                      <img
                        src={card.imageUrl}
                        alt=""
                      />
                    </div>
                    <div className="card__body">
                      <strong className="card__title">{card.title}</strong>
                      <p>{card.description}</p>
                    </div>
                    <div className="card__footer">
                      <div className="two-columns">
                        <button className={"action-button action-button--primary"} onClick={() => goToLink(card.contentUrl)}>
                          <p>Read now</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        )}
      </div>
    </section>
  );
}

export default NewsCard;
