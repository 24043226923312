import React from 'react'

const CarIcon = () => {
  return (
    <svg
      width="43"
      height="36"
      viewBox="0 0 46 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.2794 28.34H18.6501C18.5273 28.34 18.413 28.2207 18.3522 28.0264L16.8992 23.4663C16.7667 23.049 16.9321 22.5278 17.1971 22.5278H28.7325C28.9975 22.5278 29.1641 23.049 29.0304 23.4663L27.5773 28.0264C27.5153 28.2207 27.4023 28.34 27.2794 28.34Z"
        stroke="#086DA4"
        strokeWidth="2.45315"
        strokeMiterlimit="10"
      />
      <path
        d="M33.6619 18.038C33.6619 18.038 25.6468 17.8065 22.9746 17.8079C20.3024 17.8093 12.5094 18.038 12.5094 18.038C11.3686 18.038 10.4997 17.016 10.6813 15.8883L11.63 9.9893C11.775 9.09023 12.5499 8.42987 13.458 8.42987C13.458 8.42987 20.5938 8.0468 22.9746 8.04252C25.571 8.03823 33.3523 8.42987 33.3523 8.42987C34.3375 8.42987 35.1503 9.20172 35.2012 10.1866L35.5122 16.0855C35.5684 17.1475 34.7243 18.038 33.6633 18.038H33.6619Z"
        stroke="#086DA4"
        strokeWidth="2.45315"
        strokeMiterlimit="10"
      />
      <path
        d="M32.481 32.3136V35.4311C32.481 35.8784 32.844 36.2415 33.2914 36.2415H36.9091C37.3565 36.2415 37.7196 35.8784 37.7196 35.4311V30.707"
        stroke="#086DA4"
        strokeWidth="2.45315"
        strokeMiterlimit="10"
      />
      <path
        d="M8.20898 30.707V35.4311C8.20898 35.8784 8.57204 36.2415 9.01943 36.2415H12.6371C13.0845 36.2415 13.4476 35.8784 13.4476 35.4311V32.3136"
        stroke="#086DA4"
        strokeWidth="2.45315"
        strokeMiterlimit="10"
      />
      <path
        d="M4.61287 14.2349H2.32184C1.72449 14.2349 1.24023 14.7191 1.24023 15.3165V18.7769C1.24023 19.3742 1.72449 19.8585 2.32184 19.8585H4.61287C5.21023 19.8585 5.69448 19.3742 5.69448 18.7769V15.3165C5.69448 14.7191 5.21023 14.2349 4.61287 14.2349Z"
        stroke="#086DA4"
        strokeWidth="2.45315"
        strokeMiterlimit="10"
      />
      <path
        d="M43.606 14.2349H41.315C40.7177 14.2349 40.2334 14.7191 40.2334 15.3165V18.7769C40.2334 19.3742 40.7177 19.8585 41.315 19.8585H43.606C44.2034 19.8585 44.6876 19.3742 44.6876 18.7769V15.3165C44.6876 14.7191 44.2034 14.2349 43.606 14.2349Z"
        stroke="#086DA4"
        strokeWidth="2.45315"
        strokeMiterlimit="10"
      />
      <path
        d="M14.4311 23.1106H11.3512C11.3512 23.1106 10.2653 23.0245 10.4307 24.3075C10.5961 25.5905 11.0897 27.2135 11.0897 27.2135C11.0897 27.2135 11.4448 27.5978 12.4905 27.6839C13.5361 27.77 15.7491 27.982 15.7491 27.982C15.7491 27.982 16.5966 27.5116 16.2671 26.7432C15.9376 25.9747 14.9721 23.6229 14.9721 23.6229C14.9721 23.6229 14.8311 23.1106 14.4311 23.1106Z"
        stroke="#086DA4"
        strokeWidth="2.45315"
        strokeMiterlimit="10"
      />
      <path
        d="M31.4976 23.1106H34.5775C34.5775 23.1106 35.6633 23.0245 35.4979 24.3075C35.3325 25.5905 34.8389 27.2135 34.8389 27.2135C34.8389 27.2135 34.4838 27.5978 33.4381 27.6839C32.3912 27.77 30.1795 27.982 30.1795 27.982C30.1795 27.982 29.332 27.5116 29.6615 26.7432C29.991 25.9747 30.9565 23.6229 30.9565 23.6229C30.9565 23.6229 31.0975 23.1106 31.4976 23.1106Z"
        stroke="#086DA4"
        strokeWidth="2.45315"
        strokeMiterlimit="10"
      />
      <path
        d="M22.9644 32.3136C22.9644 32.3136 33.5931 32.3136 35.8071 32.3136C38.0212 32.3136 38.7402 27.3152 38.7402 27.3152C38.7402 27.3152 38.2285 9.08227 37.4681 5.03576C36.8406 1.70249 34.4807 1.7711 34.4807 1.7711H22.9658H11.4509C11.4509 1.7711 9.08959 1.70249 8.46353 5.03576C7.70169 9.0837 7.19141 27.3152 7.19141 27.3152C7.19141 27.3152 7.91037 32.3136 10.1244 32.3136C12.3385 32.3136 22.9672 32.3136 22.9672 32.3136H22.9644Z"
        stroke="#086DA4"
        strokeWidth="2.45315"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default CarIcon