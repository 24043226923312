import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useReadDrivers } from "../../../hooks/useDrivers";

import Search from "../../../components/search";
import DriverTable from "../../../components/tables/DriverTable";
import AddButton from "../../../components/addButton";
import Paginator from "../../../components/paginator/paginator";
import Header from "../../../components/Header";
import CategoryButton from "../../../components/CategoryButton";
import DriverCard from "../../../components/informativeCards/driverCard";

const MAX_RESULTS = 10;
const BUTTON_NAME = "Añadir conductor";
const BUTTON_ROUTE = "add";

function Driver() {
  const { drivers, getDrivers } = useReadDrivers();

  const [driverSelected, setDriverSelected] = useState(null);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  function handleSearchUser(limit, page) {
    getDrivers(limit, page, search);
  }

  useEffect(() => {
    getDrivers(MAX_RESULTS, 1, search);
  }, [search]);

  return (
    <section className="cardLayout">
      <div className="cardLayout__title">
        <Header name="Transporte" />
      </div>
      <section className="cardLayout__container">
        <div className="cardLayout__column">
          <div className="cardLayout__categories">
            <CategoryButton isActive={true} name="Conductores" />
            <CategoryButton
              name="Vehículos"
              onClick={() => navigate("/admin/transport/vehicles")}
            />
            <AddButton
              name={BUTTON_NAME}
              route={BUTTON_ROUTE}
              className="add-button"
            />
          </div>
          <h2 className="cardLayout__subtitle">
            Añada, consulte o elimine un conductor
          </h2>
          <Search
            search={setSearch}
            calendar={false}
            typeOfSearch={"apellido"}
          />
          <div className="cardLayout__content">
            <DriverTable selected={setDriverSelected} data={drivers?.data} />
          </div>
          {drivers && (
            <Paginator
              maxPages={drivers.totalPages}
              search={handleSearchUser}
              resultsShown={MAX_RESULTS}
              maxResults={drivers.count}
              page={drivers.page}
            />
          )}
        </div>
        <section className="cardLayout__card">
          {driverSelected && (
            <DriverCard
              setClose={setDriverSelected}
              driver={driverSelected}
              isEditable={false}
              isDescriptive={true}
            />
          )}
        </section>
      </section>
    </section>
  );
}

export default Driver;
