import { useEffect, useReducer, useState } from "react";
import { Form, useNavigate } from "react-router-dom";
import { useCreateUsers } from "../../../hooks/useUsers";
import { createUserStates, FormEvents, FormState } from "../../../states/createUser";

import PopUp from "../../../components/modals/popUp";
import NewStepProcessBar from "../../../components/newStepProcessBar";
import AddTouristInformation from "../../../components/forms/AddTourist/AddTouristInformation";
import Overlays from "../../../components/modals/overlays";
import LazyLoading from "../../../components/LazyLoading";
import Header from "../../../components/Header";
import GoBackButton from "../../../components/GoBackButton";

function AddTourist() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(null);
  const [validInformation, setValidInformation] = useState({
    isDirty: false,
    isValid: false,
  });
  const [popUp, setPopUp] = useState({
    informative: false,
    warning: false,
    positive: false,
  });

  const [state, dispatch] = useReducer(createUserStates, FormState.UserInformation);

  const { user, createUserError, createUser } = useCreateUsers();

  const updateState = () => {
    if (state === FormState.UserInformation) {
      dispatch(FormEvents.CreateUser);
    }
  };

  useEffect(() => {
    if (state === FormState.CreatingUser) {
      setPopUp({ ...popUp, informative: false });
      createUser(formData);
    }
  }, [state, formData]);

  useEffect(() => {
    if (createUserError) {
      dispatch(FormEvents.CreateUserFail);
    }
  }, [createUserError]);

  useEffect(() => {
    if (user) {
      dispatch(FormEvents.CreateUserSuccess);
      setPopUp((prevState) => ({
        ...prevState,
        positive: true,
      }));
    }
  }, [user]);

  return (
    <section
      className="formLayout"
      style={popUp.warning || popUp.informative ? { overflow: "hidden" } : {}}
    >
      <div className="formLayout__title">
        <Header name="Turistas" />
      </div>
      <div className="formLayout__container">
        <div className="formLayout__subtitle">
          <GoBackButton
            onClick={() => {
              setPopUp({ ...popUp, warning: true });
            }}
            title="Añada la información del turista"
            subtitle="Rellene los campos con la información personal del turista"
          />
          <NewStepProcessBar
            stepsNumber={3}
            actualStep={state + 1}
          />
          <p className="form__steps">
            <strong>Paso {state + 1}</strong> de 3
          </p>
        </div>
        <div className="formLayout__form">
          <AddTouristInformation
            state={state}
            formState={FormState}
            createUser={setFormData}
            validUser={setValidInformation}
          />
          {state === FormState.CreatingUser && <LazyLoading />}
        </div>
        {(state !== FormState.CreatingUser && state !== FormState.UserImages) && (
          <div className="formLayout__buttons">
            <button
              className="action-button action-button--primary"
              form={"tourist-information"}
              type="submit"
              onClick={() => setPopUp({ ...popUp, informative: true })}
              disabled={!validInformation.isDirty || !validInformation.isValid}
            >
              Continuar
            </button>
          </div>
        )}
      </div>

      <Overlays>
        {popUp.warning && (
          <PopUp
            type={"warning"}
            title={"¿Desea salir?"}
            subtitle={"Si sales ahora, perderás todo avance e información que has escrito hasta el momento."}
          >
            <button
              className="alert-button alert-button--secondary"
              onClick={() => navigate(-1)}
            >
              Sí
            </button>
            <button
              className="alert-button alert-button--primary"
              onClick={() => setPopUp({ ...popUp, warning: false })}
            >
              No
            </button>
          </PopUp>
        )}
        {popUp.informative && (
          <PopUp
            type={"informative"}
            title={"¿Está todo listo?"}
            subtitle={`Al presionar "Sí" se creará el nuevo perfil del turista`}
          >
            <button
              className="action-button action-button--secondary"
              onClick={() => updateState()}
            >
              Sí
            </button>
            <button
              className="action-button action-button--primary"
              onClick={() => setPopUp({ ...popUp, informative: false })}
            >
              No
            </button>
          </PopUp>
        )}
        {popUp.positive && (
          <PopUp
            type={"positive"}
            title={"¡El turista se ha creado exitosamente!"}
            subtitle={`Al presionar "Aceptar" volverá a la vista principal`}
          >
            <button
              className="action-button action-button--primary"
              onClick={() => navigate(-1)}
            >
              Aceptar
            </button>
          </PopUp>
        )}
      </Overlays>
    </section>
  );
}

export default AddTourist;
