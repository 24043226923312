import { useState, useEffect } from "react";
import { useUpdateContent } from "../../../hooks/useContent";

import EditArticleInformation from "./EditArticleInformation";
import EditArticlePicture from "./EditArticlePicture";
import LazyLoading from "../../../components/LazyLoading";
import GoBackButton from "../../GoBackButton";

const EditArticle = ({ data, close }) => {
  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [formImage, setFormImage] = useState(null);
  const [state, setState] = useState({ success: false, fail: false })
  const [category, setCategory] = useState({
    information: true,
    picture: false,
  });

  const { contentImage, updatedContent, updateContentError, updateArticle, uploadArticleImage } = useUpdateContent();

  const handleCategory = (activeCategory) => {
    const initialValue = {
      information: false,
      picture: false,
    };
    setState({ success: false, fail: false })

    const updatedCategories = { ...initialValue };
    updatedCategories[activeCategory] = true;

    setCategory(updatedCategories);
  };

  useEffect(() => {
    if (data) {
      setArticle(data)
    }
  }, [data])

  useEffect(() => {
    if (updatedContent) {
      setArticle(updatedContent)
    }
  }, [updatedContent])

  useEffect(() => {
    if (formData) {
      setIsLoading(true);
      updateArticle(formData, article._id);
    }
  }, [formData])

  useEffect(() => {
    if (formImage) {
      setIsLoading(true);
      uploadArticleImage(formImage, article._id);
    }
  }, [formImage])

  useEffect(() => {
    if (updatedContent || contentImage) {
      setState({ success: true, fail: false })
      setIsLoading(false);
    } else if (updateContentError) {
      setState({ success: false, fail: true })
      setIsLoading(false);
    }
  }, [updatedContent, contentImage, updateContentError])

  return (
    <section className="edit-popup">
      <div className="edit-popup__box">
        <div className="edit-popup__container">
          <div className="edit-popup__header">
            <GoBackButton
              title="Edite la información de la noticia"
              subtitle="Edite la información de la noticia según sea necesario"
              onClick={close}
              isClose
            />
            {state.success && (
              <p className="edit-popup__status edit-popup__status--success">Cambios guardados con éxito</p>
            )}
            {state.fail && <p className="edit-popup__status edit-popup__status--error">Cambios no procesados</p>}
          </div>
          {!isLoading && (
            <>
              <div className="edit-popup__category">
                <button
                  className={`category-button ${category.information ? "category-button--active" : ""}`}
                  onClick={() => handleCategory("information")}
                >
                  Información
                </button>
                <button
                  className={`category-button ${category.picture ? "category-button--active" : ""}`}
                  onClick={() => handleCategory("picture")}
                >
                  Imagen
                </button>
              </div>

              {(category.information && article) && (
                <EditArticleInformation
                  data={article}
                  setFormData={setFormData}
                />
              )}
              {(category.picture && article) && (
                <EditArticlePicture
                  data={article}
                  setFormData={setFormImage}
                />
              )}
            </>
          )}
          {isLoading && <LazyLoading />}
        </div>
      </div>
    </section>
  );
};

export default EditArticle