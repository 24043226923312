import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useReadContent, useDeleteContent } from "../../../hooks/useContent";

import AddButton from "../../../components/addButton";
import Card from "../../../components/Card";
import LazyLoading from "../../../components/LazyLoading";
import Header from "../../../components/Header";
import CategoryButton from "../../../components/CategoryButton";

function Articles() {
  const { images, getArticles } = useReadContent();
  const { deleteImageError, deleteImageLoading, deleteArticle } =
    useDeleteContent();
  const navigate = useNavigate();

  const buttonName = "Agregar Noticia";

  useEffect(() => {
    getArticles();
  }, []);

  useEffect(() => {
    if (!deleteImageError && !deleteImageLoading) {
      getArticles();
    }
  }, [deleteImageError, deleteImageLoading]);

  return (
    <section className="contentLayout">
      <section className="contentLayout__container">
        <div className="contentLayout__title">
          <Header name="Contenido" />
        </div>
        <div className="contentLayout__buttons">
          <CategoryButton
            name="Portadas"
            onClick={() => navigate("/admin/content/cover")}
          />
          <CategoryButton name="Noticias" isActive={true} />
          <AddButton name={buttonName} route={"add"} className="add-button" />
        </div>
        <h2 className="contentLayout__subtitle">
          Añada, Edite o Elimine una Noticia
        </h2>
        <div className="contentLayout__grid">
          {images &&
            !deleteImageLoading &&
            images.map((image, index) => (
              <Card
                key={index}
                type={"article"}
                data={image}
                getArticles={getArticles}
                deleteCard={deleteArticle}
              ></Card>
            ))}
        </div>
        {deleteImageLoading && <LazyLoading />}
      </section>
    </section>
  );
}

export default Articles;
