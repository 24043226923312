const StartTripInformationRow = ({ time, site, dropOff, isActive }) => {
  return (
    <div className={`startTripInformationRow ${isActive ? "startTripInformationRow--active" : ""}`}>
      <div className="startTripInformationRow__time">
        <strong>{time}</strong>
      </div>
      <div className="startTripInformationRow__destiny">
        <div className="startTripInformation__users">
        </div>
        <div className="startTripInformationRow__place">
          <p>{site}</p>
          <p>{!dropOff ? "Pickup at" : "Dropoff at"}</p>
        </div>
      </div>
    </div>
  );
};

export default StartTripInformationRow;
