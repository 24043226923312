import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { tractions, transformToStringTractions } from "../../models/tractions";

import Overlays from "../modals/overlays";
import EditPopup from "../modals/editPopup";
import PopUp from "../modals/popUp";

function VehicleCard({ vehicle, setClose, isDescriptive }) {
  const navigate = useNavigate();

  const [popUp, setPopup] = useState({
    edit: false,
    warning: false,
    informative: false,
  });

  const handleEditPopup = () => {
    if (popUp.edit) {
      setPopup((prevState) => ({
        ...prevState,
        edit: false,
      }));
      return;
    }

    setPopup({
      ...popUp,
      edit: true,
    });
  };

  const deleteData = () => {
    setPopup({ ...popUp, warning: false });
  };

  return (
    <section className="newInformativeCard">
      {isDescriptive &&
        <button
          className="close_button newInformativeCard__close"
          onClick={() => setClose(null)}
        >
          <span></span>
          <span></span>
        </button>
      }
      <div className="newInformativeCard__profile">
        <img
          src={require("../../assets/images/vanlogo.png")}
          alt="Imagen del vehículo"
        />
        <p className="newInformativeCard__id">
          Placa
          <strong>{vehicle?.plate || "Placa no disponible"}</strong>
        </p>
      </div>
      <section className="newInformativeCard__container">
        <p className="newInformativeCard__row">
          Modelo
          <span>{vehicle.model}</span>
        </p>
        <p className="newInformativeCard__row">
          Tracción
          <span>{transformToStringTractions(vehicle.traction, tractions)}</span>
        </p>
        <p className="newInformativeCard__row">
          Capacidad de Personas
          <span>{vehicle.passengerCapacity}</span>
        </p>
        <p className="newInformativeCard__row">
          Tablas de Surf
          <span>{vehicle.surfboardCapacity}</span>
        </p>
        <p className="newInformativeCard__row">
          Maletas
          <span>{vehicle.trunkCapacity}</span>
        </p>
      </section>
      <div className="newInformativeCard__buttons">
        {isDescriptive &&
          <button
            onClick={() => navigate(vehicle._id)}
            className="action-button action-button--primary"
          >
            <p>Más información</p>
          </button>
        }
      </div>

      <Overlays>
        {popUp.edit && (
          <EditPopup
            user={vehicle}
            type={"Driver"}
            close={handleEditPopup}
          />
        )}
        {popUp.warning && (
          <PopUp
            type={"warning"}
            title={"¿Desea eliminarlo?"}
            subtitle={"Esta acción es irreversible"}
          >
            <button
              className="alert-button alert-button--secondary"
              onClick={deleteData}
            >
              Sí
            </button>
            <button
              className="alert-button alert-button--primary"
              onClick={() => { setPopup({ ...popUp, warning: false }) }}
            >
              No
            </button>
          </PopUp>
        )}
      </Overlays>
    </section>
  );
}

export default VehicleCard;
