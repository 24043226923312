import NoUserImage from "../../assets/icons/user.png";

const ReservationDriver = ({ driverSelected, driverLanguages }) => {
  return (
    <section className="reservationProfile">
      <h3>Conductor Asignado</h3>
      <div className="reservationProfile__container">
        <img
          className="reservationProfile__userImage"
          src={driverSelected?.profilePicture ? driverSelected?.profilePicture : NoUserImage}
          alt="Imagen del Conductor"
        />
        <div className="reservationProfile__info">
          <div className="reservationProfile__twoColumns">
            <p className="reservationProfile__field">
              Nombre Completo
              <span>{`${driverSelected?.user?.name} ${driverSelected?.user?.lastName}`}</span>
            </p>
            <p className="reservationProfile__field">
              Teléfono
              <span>{driverSelected?.user?.phone}</span>
            </p>
          </div>
          <p className="reservationProfile__field">
            Idiomas
            <span>{driverLanguages}</span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default ReservationDriver;
