import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { languagesEs, transformToStringLanguages } from "../../models/languages";
import { rolesEs, transformToStringRole } from "../../models/roles";

import Overlays from "../modals/overlays";
import EditPopup from "../modals/editPopup";
import PopUp from "../modals/popUp";

function PointCard({ point, setClose, isDescriptive }) {
  const navigate = useNavigate();

  const [popUp, setPopup] = useState({
    edit: false,
    warning: false,
    informative: false,
  });

  const handleEditPopup = () => {
    if (popUp.edit) {
      setPopup((prevState) => ({
        ...prevState,
        edit: false,
      }));
      return;
    }

    setPopup({
      ...popUp,
      edit: true,
    });
  };

  const deleteData = () => {
    setPopup({ ...popUp, warning: false });
  };

  return (
    <section className="newInformativeCard">
      {isDescriptive &&
        <button
          className="close_button newInformativeCard__close"
          onClick={() => setClose(null)}
        >
          <span></span>
          <span></span>
        </button>
      }
      <div className="newInformativeCard__profile">
        <img
          src={point.image ? point.image : require("../../assets/icons/user.png")}
          alt="Imagen del vehículo"
        />
        <p className="newInformativeCard__id">
          ID del Punto
          <strong>{point?._id || "Id no disponible"}</strong>
        </p>
      </div>
      <section className="newInformativeCard__container">
        <p className="newInformativeCard__row">
          Nombre
          <span>{point?.name}</span>
        </p>
        <p className="newInformativeCard__row">
          Tipo
          <span>{point?.type}</span>
        </p>
        <p className="newInformativeCard__row">
          Descripción
          <span>{point?.description}</span>
        </p>
        <p className="newInformativeCard__row">
          Teléfono
          <span>{point?.info?.phone}</span>
        </p>
        <p className="newInformativeCard__row">
          Sitio Web
          <span>{point?.info?.website}</span>
        </p>
      </section>
      <div className="newInformativeCard__buttons">
        {isDescriptive &&
          <button
            onClick={() => navigate(point._id)}
            className="action-button action-button--primary"
          >
            <p>Más información</p>
          </button>
        }
      </div>

      <Overlays>
        {popUp.edit && (
          <EditPopup
            user={point}
            type={"Driver"}
            close={handleEditPopup}
          />
        )}
        {popUp.warning && (
          <PopUp
            type={"warning"}
            title={"¿Desea eliminarlo?"}
            subtitle={"Esta acción es irreversible"}
          >
            <button
              className="alert-button alert-button--secondary"
              onClick={deleteData}
            >
              Sí
            </button>
            <button
              className="alert-button alert-button--primary"
              onClick={() => { setPopup({ ...popUp, warning: false }) }}
            >
              No
            </button>
          </PopUp>
        )}
      </Overlays>
    </section>
  );
}

export default PointCard;
