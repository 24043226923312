import InformativeIcon from "../../assets/svg/InformativeIcon";
import SuccessIcon from "../../assets/svg/SuccessIcon";
import WarningIcon from "../../assets/svg/WarningIcon";

function PopUp({ type, title, subtitle, children }) {
  return (
    <div className={`popup`}>
      <div className="popup__container">
        {type === "positive" && <SuccessIcon />}
        {type === "warning" && <WarningIcon />}
        {type === "informative" && <InformativeIcon />}
        <div className="popup__text">
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>
        <div className="popup__buttons">{children}</div>
      </div>
    </div>
  );
}

export default PopUp;
