import "react-day-picker/dist/style.css";
import { useState, useRef } from "react";

import { es } from "date-fns/locale";
import { format } from "date-fns";
import FocusTrap from "focus-trap-react";
import { DayPicker } from "react-day-picker";
import { usePopper } from "react-popper";
import FilterIcon from "../assets/svg/FilterIcon";
import SearchIcon from "../assets/svg/SearchIcon";
import CalendarIcon from "../assets/svg/CalendarIcon";

function Search({ search, calendar, filter, typeOfSearch, setDateRange }) {
  const myDate = new Date();
  const defaultSelected = myDate;
  const defaultInput = format(new Date(), "yyyy-MM-dd");

  const [selected, setSelected] = useState(defaultSelected);
  const [inputValue, setInputValue] = useState(defaultInput);
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const popperRef = useRef(null);
  const buttonRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);

  const popper = usePopper(popperRef.current, popperElement, {
    placement: "bottom-start",
  });

  const handleButtonClick = () => {
    if (isPopperOpen) {
      setIsPopperOpen(false);
    } else {
      setIsPopperOpen(true);
    }
  };

  const onchangeText = (e) => {
    e.preventDefault();
    search(e.target.value);
  };

  const handleDaySelect = (date) => {
    setSelected(date);
    try {
      if (date) {
        const dateFormat = format(date, "yyyy-MM-dd");
        setInputValue(dateFormat);
        setDateRange(dateFormat);
      }
    } catch (error) { }
  };

  return (
    <div className="search_container">
      {filter && (
        <button className="search_filter">
          <FilterIcon />
        </button>
      )}
      <div className="search_input__container">
        <SearchIcon />
        <input
          onChange={onchangeText}
          type="text"
          className="input-with-icon"
          placeholder={`Búsqueda por ${typeOfSearch}`}
        />
      </div>
      {calendar ? (
        <div className="search_button__container">
          <button
            className="search_button"
            ref={buttonRef}
            type="button"
            aria-label="Pick a date"
            onClick={handleButtonClick}
          >
            <CalendarIcon />
            <p className="fontBold">Fecha</p>
            <p>{inputValue}</p>
          </button>
          {isPopperOpen && (
            <FocusTrap
              active
              focusTrapOptions={{
                initialFocus: false,
                allowOutsideClick: true,
                clickOutsideDeactivates: true,
                fallbackFocus: buttonRef.current,
              }}
            >
              <div
                tabIndex={-1}
                className="dialog-sheet__search"
                {...popper.attributes.popper}
                ref={setPopperElement}
                role="dialog"
                aria-label="DayPicker calendar"
              >
                <DayPicker
                  initialFocus={isPopperOpen}
                  mode="single"
                  locale={es}
                  defaultMonth={myDate}
                  selected={selected}
                  onSelect={handleDaySelect}
                />
              </div>
            </FocusTrap>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Search;
