import { useState } from "react";
import useAxiosPrivate from "./useAxiosPrivate";

export function useReadPoints() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [points, setPoints] = useState(null);
  const [point, setPoint] = useState(null);
  const [pointError, setPointError] = useState(null);

  const getPoints = async (limit, page, name) => {
    let params = new URLSearchParams();

    if (limit) params.append('limit', limit);
    if (page) params.append('page', page);
    if (name) params.append('name', name);

    const url = `points-of-interest?${params.toString()}`;

    try {
      const response = await axiosPrivate.get(
        url,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      );
      if (isMounted) {
        setPoints(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setPointError(error);
        setPoints(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  const getPointById = async (pointId) => {
    setPoint(null);
    setPointError(null)

    try {
      const response = await axiosPrivate.get(
        `points-of-interest/${pointId}`,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      );
      if (isMounted) {
        setPoint(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setPointError(error);
        setPoint(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  return { points, point, pointError, getPoints, getPointById };
}

export function useUpdatePoints() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [updatedPoint, setUpdatedPoint] = useState(null);
  const [pointPicture, setPointPicture] = useState(null);
  const [updatePointError, setError] = useState(null);

  const updatePoint = async (point, pointId) => {
    setError(null);
    setUpdatedPoint(null);

    try {
      const response = await axiosPrivate.patch(
        `points-of-interest/${pointId}/update`,
        point,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      );
      if (isMounted) {
        setUpdatedPoint(response?.data)
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
        setUpdatedPoint(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  const updatePointImage = async (imageData, pointId) => {
    const formData = new FormData();
    formData.append('poiImage', imageData[0]);

    try {
      const response = await axiosPrivate.patch(
        `points-of-interest/${pointId}/upload-image`,
        formData,
      );
      if (isMounted) {
        setPointPicture(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
        setPointPicture(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  return { updatedPoint, pointPicture, updatePointError, updatePoint, updatePointImage };
}

export function useCreatePoint() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [point, setPoint] = useState(null);
  const [createPointError, setError] = useState(null);

  const createPoint = async (point, imageData) => {
    setPoint(null);
    setError(null);

    try {
      const response = await axiosPrivate.post(
        'points-of-interest/create',
        point,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      );
      if (isMounted) {
        setPoint(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  return { point, createPointError, createPoint };
}

export function useDeletePoint() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [deletedPoint, setDeletedPoint] = useState(null);
  const [deletePointError, setDeletePointError] = useState(null);

  const deletePoint = async (userId) => {
    setDeletedPoint(null);
    setDeletePointError(null);

    try {
      const response = await axiosPrivate.delete(
        `points-of-interest/${userId}/delete`,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      );
      if (isMounted) {
        setDeletedPoint(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setDeletePointError(error);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  return { deletedPoint, deletePointError, deletePoint };
}