import { useEffect, useState } from "react";
import { useReadContent } from "../../hooks/useContent";

import NewsCard from "../../components/newsCard";
import Header from "../../components/Header";
import CategoryButton from "../../components/CategoryButton";

function News() {
  const [filter, setFilter] = useState("");

  const { images, getArticles } = useReadContent();

  useEffect(() => {
    getArticles(filter);
  }, [filter]);

  return (
    <section className="contentLayout">
      <section className="contentLayout__container">
        <div className="contentLayout__title">
          <Header name="News" />
        </div>
        <div className="contentLayout__buttons">
          <CategoryButton
            name="All"
            isActive={filter === ""}
            onClick={() => setFilter("")}
          />
          <CategoryButton
            name="Hotels"
            isActive={filter === "hotels"}
            onClick={() => setFilter("hotels")}
          />
          <CategoryButton
            name="Restaurants"
            isActive={filter === "restaurants"}
            onClick={() => setFilter("restaurants")}
          />
          <CategoryButton
            name="Things to do"
            isActive={filter === "things-to-do"}
            onClick={() => setFilter("things-to-do")}
          />
        </div>
        <h2 className="contentLayout__subtitle">
          Check the latest announcements of Nosara
        </h2>
        <div className="contentLayout__content">
          <NewsCard filter={filter} news={images} />
        </div>
      </section>
    </section>
  );
}

export default News;
