import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import DocumentIcon from "../../../assets/svg/DocumentIcon";
import DocumentImage from "../../../assets/svg/DocumentImage";
import CloudIcon from "../../../assets/svg/CloudIcon";
import ProfilePictureDemo1 from "../../../assets/svg/ProfilePicturesDemo/ProfilePictureDemo1";
import ProfilePictureDemo2 from "../../../assets/svg/ProfilePicturesDemo/ProfilePictureDemo2";
import ProfilePictureDemo3 from "../../../assets/svg/ProfilePicturesDemo/ProfilePictureDemo3";
import UploadProfilePicture from "../../uploadFile/uploadProfilePicture";

const AddManagerPicture = ({ state, formState, validImages, uploadPictures }) => {
  const [profilePictureName, setProfilePictureName] = useState("");
  const [documentsCompleted, setDocumentsCompleted] = useState(0);

  const {
    register,
    getFieldState,
    formState: { isDirty, isValid },
    handleSubmit,
  } = useForm();

  const isProfileUploadValid = getFieldState("profilePicture");

  const onSubmit = (data) => {
    uploadPictures(data.profilePicture);
  };

  useEffect(() => {
    if (isProfileUploadValid.isDirty) {
      setDocumentsCompleted(1);
      return;
    }
  }, [isProfileUploadValid]);

  useEffect(() => {
    validImages({ isDirty, isValid });
  }, [isDirty, isValid, validImages]);

  return (
    <form
      id="manager-picture"
      onSubmit={handleSubmit(onSubmit)}
      style={state === formState.UserImages ? {} : { display: "none" }}
    >
      {!isProfileUploadValid.invalid && (
        <>
          <div className={`form__document_progress ${documentsCompleted > 0 ? "form__document_progress--active" : ""}`}>
            <p>
              <strong>Documento {documentsCompleted}</strong> de {1}
            </p>
            <div className="form__document_progress--bar">
              <span style={{ width: `${documentsCompleted ? 100 : 0}%` }}></span>
            </div>
          </div>
          <h3>Documentos requeridos</h3>
        </>
      )}

      <UploadProfilePicture
        show
        profilePictureName={profilePictureName}
        setProfilePictureName={setProfilePictureName}
        register={register}
      />
    </form>
  );
};

export default AddManagerPicture;
