import { useState, useEffect } from "react";
import ClickableMap from "../../maps/clickableMap";

const EditPointMap = ({ data, setFormData }) => {

  const [pointLocation, setPointLocation] = useState(null);

  useEffect(() => {
    if (data) {
      setPointLocation(data.location);
    }
  }, [data])

  return (
    <>
      <h2 className="edit-popup__subtitle">Haz click en el mapa de la nueva ubicación del Punto de Interés</h2>
      <form className="edit-popup__form">
        <div className="edit-popup__form-wrapper">
          <div className="form__input_container map">
            <ClickableMap
              pointLocation={data?.location}
              setPointLocation={setPointLocation}
              isTouchable={true}
            />
          </div>
        </div>
        <button
          type="button"
          className="edit-popup__button action-button action-button--primary"
          onClick={() => { setFormData({ location: pointLocation }) }}
        >
          Guardar cambios
        </button>
      </form>
    </>
  );
};

export default EditPointMap;
