import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../models/routes";
import RouteMap from "../maps/routeMap";

const TripCard = ({ onClose, data, isDriver }) => {
  const navigate = useNavigate();
  const [routeCoordinates, setRouteCoordinates] = useState(null);

  useEffect(() => {
    if (data.booking.ticket.route) {
      setRouteCoordinates(routes.find((route) => route.name === data.booking.ticket.route));
    }
  }, [data.booking.ticket.route]);

  return (
    <section className="newReservationCard">
      <button
        className="close_button newReservationCard__close"
        onClick={() => onClose(null)}
      >
        <span></span>
        <span></span>
      </button>
      <div className="newReservationCard__map">
        <RouteMap
          routeCoordinates={routeCoordinates}
          isTouchable={false}
        />
        <p className="newReservationCard__id">
          ID Viaje
          <strong>{data.booking._id}</strong>
        </p>
      </div>
      <section className="newReservationCard__container">
        {isDriver && (
          <p className="newReservationCard__row">
            Turista que lo solicitó
            <span>
              {data.tourist.name} {data.tourist.lastName}
            </span>
          </p>
        )}
        {!isDriver && (
          <p className="newReservationCard__row">
            Conductor Asignado
            <span>
              {data.driver.user.name} {data.driver.user.lastName}
            </span>
          </p>
        )}
        <p className="newReservationCard__row">
          Hora
          <span>
            {data.booking.ticket.pickUpTime} - {data.booking.ticket.dropOffTime}
          </span>
        </p>
        <p className="newReservationCard__row">
          Ruta
          <span>{data.booking.ticket.route}</span>
        </p>
        <p className="newReservationCard__row">
          Fecha
          <span>{new Date(data.booking.ticket.pickUpDate).toLocaleDateString("es-ES")}</span>
        </p>
      </section>
      <div className="newInformativeCard__buttons">
        {data.status === "Pending for Assignment" ? (
          <button
            className="action-button action-button--primary"
            onClick={() => navigate(`${data._id}/add`)}
          >
            Asignar el viaje
          </button>
        ) : (
          <button
            className="action-button action-button--primary"
            onClick={() => navigate(data.booking._id)}
          >
            Más información
          </button>
        )}
      </div>
    </section>
  );
};

export default TripCard;
