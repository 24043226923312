import CloudIcon from "../../assets/svg/CloudIcon";
import DocumentImage from "../../assets/svg/DocumentImage";

const UploadMarchamo = ({ show, registrationName, setRegistrationName, register }) => {
  return (
    <section className={`uploadFile ${show ? "uploadFile--show" : ""}`}>
      <div className="uploadFile__firstColumn">
        <div className="uploadFile__mainImage">
          <DocumentImage />
        </div>
      </div>
      <div className="uploadFile__secondColumn">
        <div className="uploadFile__input">
          <p>Marchamo</p>
          <div className={`uploadFile__dragAndDrop ${registrationName ? "uploadFile__dragAndDrop--active" : ""}`}>
            <CloudIcon />
            <h4>{registrationName ? registrationName : "Arrastra y suelta el archivo aquí"}</h4>
            <input
              type="file"
              id="vehicleRegistrationImage"
              accept=".jpg, .png, .jpeg"
              {...register("vehicleRegistrationImage", {
                onChange: (e) => {
                  setRegistrationName(e.target.files[0]?.name);
                },
                required: "Es necesaria una imagen",
              })}
            />
          </div>
          <button
            className="action-button action-button--primary uploadFile__button"
            type="button"
            onClick={() => document.getElementById("vehicleRegistrationImage").click()}
          >
            Busca en tus archivos
          </button>
        </div>
        <div className="uploadFile__info">
          <ol>
            <li>La fotografía debe ser clara y legible</li>
            <li>Debe ser un documento vigente</li>
            <li>Para vehículos con placas temporales, adjuntar el documento AGV</li>
          </ol>
          <p>
            Al adjuntarse el documento se declara que tiene todos los documentos requeridos y vigentes por la regulación
            costarricense para el ejercicio de las actividades incluyendo, pero sin limitarse al Marchamo, Certificado
            de verificación del estado mecánico (Dekra) o cualquier equivalente.
          </p>
        </div>
      </div>
    </section>
  );
};

export default UploadMarchamo;
