import React from 'react'

const PointsIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 21 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.651 10.9711C19.651 17.9711 10.651 23.9711 10.651 23.9711C10.651 23.9711 1.651 17.9711 1.651 10.9711C1.651 8.58412 2.59921 6.29494 4.28704 4.60711C5.97487 2.91928 8.26405 1.97107 10.651 1.97107C13.0379 1.97107 15.3271 2.91928 17.015 4.60711C18.7028 6.29494 19.651 8.58412 19.651 10.9711Z"
        stroke="#959595"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.651 13.9711C12.3079 13.9711 13.651 12.6279 13.651 10.9711C13.651 9.31422 12.3079 7.97107 10.651 7.97107C8.99415 7.97107 7.651 9.31422 7.651 10.9711C7.651 12.6279 8.99415 13.9711 10.651 13.9711Z"
        stroke="#959595"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PointsIcon