import { useNavigate } from 'react-router-dom';

function AddButton({ name, route, setState, reservation, primary }) {
  const navigate = useNavigate()

  function goTo() {
    if (!reservation) {
      if (route) {
        navigate(route);
      }
      else {
        setState(true);
      }
    } else {
      window.open(route, "_blank");
    }
  }

  return (
    <button className={`add-button action-button ${primary ? "action-button--primary" : "action-button--secondary"}`} onClick={goTo}>
      <p>{name}</p>
    </button>
  );
}

export default AddButton