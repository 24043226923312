import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { contentTypeEs } from "../../../models/contentTypes";

import Select from 'react-select';

const EditArticleInformation = ({ data, setFormData }) => {
  const {
    register,
    reset,
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm();

  const onSubmit = (article) => {
    const newArticleData = {
      title: article.title,
      contentUrl: article.contentUrl,
      category: article.category,
      description: article.description
    };

    setFormData(newArticleData);
  };

  const findValue = (value, array) => {
    return array.find(item => item.value === value);
  };

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [reset, data]);

  return (
    <>
      <h2 className="edit-popup__subtitle">Información Personal</h2>
      <form
        className="edit-popup__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="edit-popup__form-wrapper">
          <div className="form__two_columns">
            <div className="form__input_container">
              <label htmlFor="">Título</label>
              <input
                className={errors.tittle?.message ? "error" : ""}
                type="text"
                placeholder="Ingrese un título"
                {...register("title", {
                  required: "Es requerido un título",
                })}
              />
            </div>
            <div className="form__input_container">
              <label htmlFor="">Enlace</label>
              <input
                className={errors.contentUrl?.message ? "error" : ""}
                type="url"
                placeholder="Ingrese un enlace"
                {...register("contentUrl", {
                  required: "Es requerido un enlace",
                })}
              />
            </div>
          </div>
          <div className="form__input_container">
            <label htmlFor="">Descripción</label>
            <textarea
              className={errors.tittle?.message ? "error" : ""}
              type="text"
              placeholder="Ingrese un título"
              {...register("description", {
                required: "Es requerido un título",
              })}
            />
          </div>
          <div className="form__input_container">
            <label htmlFor="">Categoría</label>
            <Controller
              render={({ field: { onChange } }) => (
                <Select
                  classNamePrefix="react-select"
                  isClearable={false}
                  options={contentTypeEs}
                  defaultValue={findValue(data?.category, contentTypeEs)}
                  placeholder="Seleccione una  categoría"
                  onChange={(value) => {
                    onChange(value.value);
                  }}
                />
              )}
              control={control}
              name="category"
              rules={{ required: true }}
            />
          </div>
        </div>
        <button
          type="submit"
          className="edit-popup__button action-button action-button--primary"
          disabled={!isValid}
        >
          Guardar cambios
        </button>
      </form>
    </>
  );
};

export default EditArticleInformation;
