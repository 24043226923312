import userExample from "../../assets/images/user-example.png";

import { NavLink, useNavigate, useLocation } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import CloseIcon from "../../assets/svg/CloseIcon";
import ReservationIcon from "../../assets/svg/NavIcons/ReservationIcon";
import TouristIcon from "../../assets/svg/NavIcons/TouristIcon";
import AdminIcon from "../../assets/svg/NavIcons/AdminIcon";
import TransportIcon from "../../assets/svg/NavIcons/TransportIcon";
import PointsIcon from "../../assets/svg/NavIcons/PointsIcon";
import ContentIcon from "../../assets/svg/NavIcons/ContentIcon";
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useReadUsers } from "../../hooks/useUsers";

function Navbar({ onClose, user, setAuth }) {
  const logout = useLogout();
  const navigate = useNavigate();
  const { auth } = useAuth();

  const { user: userData, getUserById } = useReadUsers();

  const routeToGo = () => {
    switch (user) {
      case "Super":
        navigate("/admin");
        break;

      case "Admin":
        navigate("/admin");
        break;

      case "Tourist":
        navigate("/user");
        break;

      case "Driver":
        navigate("/driver");
        break;

      default:
        navigate("/");
        break;
    }
  };

  useEffect(() => {
    getUserById(auth.user);
  }, []);

  return (
    <nav className="navbar_container">
      <section>
        <div className="navbar_item">
          <button
            className="close_button navbar_close_button"
            onClick={() => onClose(false)}
          >
            <CloseIcon />
          </button>
          <div className="withoutPadding">
            <img
              onClick={routeToGo}
              className="navbar_logo__big"
              src={require("../../assets/icons/NosaraLogo.png")}
              alt="Nosara Logo"
            ></img>
          </div>
          <div className="navbar_profile">
            <img
              src={userData ? userData.profilePicture : userExample}
              alt="Tu perfil"
            />
            <p className="navbar_profile__name">
              {userData ? `${userData.name} ${userData.lastName}` : "Nombre no disponible"}
            </p>
            <p className="navbar_profile__role">Perfil de Administrador</p>
          </div>
        </div>
      </section>
      <div className="navbar_routesToGo">
        {(user === "Super" || user === "Admin") && (
          <section>
            <div className="navbar_item">
              <div className="navbar_button__container">
                <NavLink
                  to="admin/reservations"
                  onClick={() => onClose(false)}
                  className={({ isActive }) => `navbar_button ${isActive ? "navbarMenuActive" : ""}`}
                >
                  <ReservationIcon />
                  <p>Reservaciones</p>
                </NavLink>
              </div>
            </div>
          </section>
        )}
        {user === "Super" && (
          <>
            <section>
              <div className="navbar_item">
                <div className="navbar_button__container">
                  <NavLink
                    to="admin/tourist"
                    onClick={() => onClose(false)}
                    className={({ isActive }) => `navbar_button ${isActive ? "navbarMenuActive" : ""}`}
                  >
                    <TouristIcon />
                    <p>Turistas</p>
                  </NavLink>
                </div>
              </div>

              <div className="navbar_item">
                <div className="navbar_button__container">
                  <NavLink
                    to="admin/manager"
                    onClick={() => onClose(false)}
                    className={({ isActive }) => `navbar_button ${isActive ? "navbarMenuActive" : ""}`}
                  >
                    <AdminIcon />
                    <p>Administrador</p>
                  </NavLink>
                </div>
              </div>

              <div className="navbar_item">
                <div className="navbar_button__container">
                  <NavLink
                    to="admin/transport"
                    onClick={() => onClose(false)}
                    className={({ isActive }) => `navbar_button ${isActive ? "navbarTransportActive" : ""}`}
                  >
                    <TransportIcon />
                    <p>Transporte</p>
                  </NavLink>
                </div>
              </div>
            </section>

            <section>
              <div className="navbar_item">
                <div className="navbar_button__container">
                  <NavLink
                    to="admin/points"
                    onClick={() => onClose(false)}
                    className={({ isActive }) => `navbar_button ${isActive ? "navbarMenuActive" : ""}`}
                  >
                    <PointsIcon />
                    <p>Puntos de interés</p>
                  </NavLink>
                </div>
              </div>
            </section>
          </>
        )}

        {user === "Super" && (
          <section>
            <div className="navbar_item">
              <div className="navbar_button__container">
                <NavLink
                  to="admin/content"
                  onClick={() => onClose(false)}
                  className={({ isActive }) => `navbar_button ${isActive ? "navbarMenuActive" : ""}`}
                >
                  <ContentIcon />
                  <p>Contenido</p>
                </NavLink>
              </div>
            </div>
          </section>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
