import TripDriverRow from "../tripDriverRow";
import TripTouristRow from "../tripTouristRow";

const TripsTable = ({ data, selected, driver }) => {
  return (
    <section className="dataTable">
      <section className="dataTable__header">
        <p>Ruta</p>
        <p>Nombre</p>
        <p>Apellido</p>
      </section>
      <section className="dataTable__body">
        {driver && data?.map((trip) => (
          <TripDriverRow
            driver={driver}
            trip={trip}
            key={trip._id}
            selected={selected}
            onClose={() => selected(null)}
          />
        ))}
        {!driver && data?.map((trip) => (
          <TripTouristRow
            trip={trip}
            key={trip._id}
            selected={selected}
            onClose={() => selected(null)}
          />
        ))}
      </section>
    </section>
  );
};

export default TripsTable;
