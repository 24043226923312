import React from 'react'

const LazyLoading = () => {
  return (
    <section
      className="form__lazy_loading"
    >
      <div className="form__loader">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="94"
            height="94"
            viewBox="0 0 94 94"
            fill="none"
          >
            <path
              d="M0.0478058 -2.28882e-05C0.0478058 12.2723 2.46501 24.4244 7.16141 35.7625C11.8578 47.1006 18.7414 57.4027 27.4193 66.0805C36.0971 74.7583 46.3991 81.6419 57.7372 86.3383C69.0753 91.0347 81.2275 93.4519 93.4997 93.4519L93.4997 74.7615C83.6819 74.7615 73.9602 72.8278 64.8897 69.0706C55.8192 65.3135 47.5776 59.8066 40.6353 52.8644C33.6931 45.9221 28.1862 37.6805 24.4291 28.61C20.672 19.5395 18.7382 9.8178 18.7382 -2.28882e-05H0.0478058Z"
              fill="#0971AB"
            />
          </svg>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="151"
            height="150"
            viewBox="0 0 151 150"
            fill="none"
          >
            <path
              d="M150.5 150C150.5 130.302 146.62 110.796 139.082 92.5975C131.544 74.3986 120.495 57.8628 106.566 43.934C92.6372 30.0052 76.1013 18.9563 57.9025 11.4181C39.7037 3.87986 20.1983 -8.61039e-07 0.5 0V30C16.2586 30 31.8629 33.1039 46.422 39.1345C60.9811 45.165 74.2098 54.0042 85.3528 65.1472C96.4958 76.2902 105.335 89.5189 111.366 104.078C117.396 118.637 120.5 134.241 120.5 150H150.5Z"
              fill="#0971AB"
            />
          </svg>
        </div>
      </div>
    </section>
  );
}

export default LazyLoading