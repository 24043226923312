
import { languagesEs } from "../models/languages";

export function transformToStringList(languages) {
  const labels = languages.map(lang => languagesEs.find(item => item.value === lang)?.label);
  if (!Array.isArray(labels)) {
    return '';
  }

  return labels.join(', ');
}

export function userCard(user) {
  if (user) {
    const userStructure = {
      img: user.profilePicture,
      id: user._id,
      data: [
        {
          item1: {
            name: 'Nombre',
            data: `${user.name} ${user.lastName}`
          },
          item2: {
          }
        },
        {
          item1: {
            name: 'Correo',
            data: user.email
          },
          item2: {}
        },
        {
          item1: {
            name: 'Género',
            data: user.gender
          },
          item2: {}
        },
        {
          item1: {
            name: 'Teléfono',
            data: user.phone
          },
          item2: {}
        },
        {
          item1: {
            name: 'Idiomas',
            data: transformToStringList(user.languages)
          },
          item2: {}
        },
      ]
    };

    return userStructure;
  }

  return null;
}

export function driverCard(driver) {
  if (driver) {

    const userStructure = {
      img: driver.user.profilePicture,
      id: driver.user._id,
      data: [
        {
          item1: {
            name: 'Nombre',
            data: driver.user.name
          },
          item2: {
            name: 'Apellido',
            data: driver.user.lastName
          }
        },
        {
          item1: {
            name: 'Correo',
            data: driver.user.email
          },
          item2: {}
        },
        {
          item1: {
            name: 'Género',
            data: driver.user.gender
          },
          item2: {}
        },
        {
          item1: {
            name: 'Idiomas',
            data: transformToStringList(driver.user.languages)
          },
          item2: {}
        },
      ]
    };

    return userStructure;
  }

  return null;
}

export function driverAssignCard(driver) {
  if (driver) {

    const userStructure = {
      img: driver.user.profilePicture,
      id: driver._id,
      data: [
        {
          item1: {
            name: 'Nombre',
            data: driver.user.name
          },
          item2: {
            name: 'Apellido',
            data: driver.user.lastName
          }
        },
        {
          item1: {
            name: 'Correo',
            data: driver.user.email
          },
          item2: {}
        },
        {
          item1: {
            name: 'Género',
            data: driver.user.gender
          },
          item2: {}
        },
        {
          item1: {
            name: 'Idiomas',
            data: transformToStringList(driver.user.languages)
          },
          item2: {}
        },
      ]
    };

    return userStructure;
  }

  return null;
}

export function driverClientCard(data) {
  if (data) {

    const userStructure = {
      img: data.driver.user.profilePicture,
      id: data._id,
      data: [
        {
          item1: {
            name: 'Name',
            data: data.driver.user.name
          },
          item2: {
            name: 'Last Name',
            data: data.driver.user.lastName
          }
        },
        {
          item1: {
            name: 'Vehicle',
            data: data.driver.vehicle?.model
          },
          item2: {
            name: 'Plate',
            data: data.driver.vehicle?.plate
          }
        },
        {
          item1: {
            name: 'Languages',
            data: transformToStringList(data.driver.user.languages)
          },
          item2: {}
        },
      ]
    };

    return userStructure;
  }

  return null;
}

export function driverStatsCard(driver) {
  if (driver) {
    let gender;
    switch (driver.user.gender) {
      case 'Male':
        gender = 'Hombre';
        break
      case 'Female':
        gender = 'Mujer';
        break
      default:
        gender = 'Otro';
        break
    }

    const userStructure = {
      img: (driver.user.profilePicture ? driver.user.profilePicture : null),
      id: driver._id,
      data: [
        {
          item1: {
            name: 'Nombre',
            data: driver.user.name
          },
          item2: {
            name: 'Apellido',
            data: driver.user.lastName
          }
        },
        {
          item1: {
            name: 'Correo',
            data: driver.user.email
          },
          item2: {}
        },
        {
          item1: {
            name: 'Género',
            data: gender
          },
          item2: {}
        },
        {
          item1: {
            name: 'Idiomas',
            data: transformToStringList(driver.user.languages)
          },
          item2: {}
        },
      ]
    };

    return userStructure;
  }

  return null;
}

export function touristReservationsCard(data, id) {
  if (data) {

    const userStructure = {
      img: data?.profilePicture,
      id: id,

      data: [
        {
          item1: {
            name: 'Nombre',
            data: data.name
          },
          item2: {
            name: 'Apellido',
            data: data.lastName
          }
        },
        {
          item1: {
            name: 'Correo',
            data: data.email
          },
          item2: {}
        },
        {
          item1: {
            name: 'Teléfono',
            data: data.phone
          },
          item2: {
            name: 'Género',
            data: data.gender
          }
        },
        {
          item1: {
            name: 'Idiomas',
            data: transformToStringList(data.languages)
          },
          item2: {}
        },
      ]
    };

    return userStructure;
  }

  return null;
}

export function tableHeaderNames() {
  const header = [
    'Rol',
    'Nombre',
    'Apellido',
    'Teléfono'
  ]

  return header;
}

export function tableHeaderReservations() {
  const header = [
    'Booking',
    'Nombre',
    'Ruta',
    'Tipo de viaje'
  ]

  return header;
}

export function tableHeaderStatsNames() {
  const header = [
    'Ruta',
    'Nombre',
    'Apellido',
    'Viaje'
  ]

  return header;
}

export function createUserModel(data, languages) {
  const user = {
    'email': data?.email,
    'password': data?.password,
    'name': data?.name,
    'lastName': data?.lastName,
    'profilePicture': '',
    'phone': data?.phone,
    'gender': data?.gender,
    'accountProvider': 'Nosara',
    'role': data?.role,
    'languages': languages
  }

  return user;
}

export function statsModel(stats) {
  if (stats) {
    const statsStructure = {
      activeIndex: 0,
      data: stats?.map((day) => (
        {
          name: day.quantity,
          uv: day.quantity * 1000,
          amt: day.date
        }
      ))
    }

    return statsStructure;
  }
}

export function statsUsersModel(stats) {
  if (stats) {
    console.log(stats)
    const statsStructure = stats?.map((data) => (
      {
        profilePicture: data.user.profilePicture,
        route: data.route,
        _id: data.user._id,
        name: data.user.name,
        lastName: data.user.lastName,
        tripType: data.tripType
      }
    ))

    return statsStructure;
  }
}