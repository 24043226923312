import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useReadRideStats } from "../../../hooks/useRides";
import { useReadPoints, useDeletePoint } from "../../../hooks/usePoints";

import GoBackButton from "../../../components/GoBackButton";
import Header from "../../../components/Header";
import userExample from "../../../assets/icons/user.png";
import TripStats from "../../../components/tripStats";
import BarChar from "../../../components/barChart";
import Overlays from "../../../components/modals/overlays";
import PopUp from "../../../components/modals/popUp";
import EditPoint from "../../../components/forms/EditPoint/EditPoint";
import PointCard from "../../../components/informativeCards/pointCard";
import ClickableMap from "../../../components/maps/clickableMap";

const AboutPoint = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const { point, getPointById } = useReadPoints();
  const { deletedPoint, deletePoint } = useDeletePoint();
  const {
    driverTenure,
    totalTrips,
    tripsByDate,
    tripsDetailByDate,
    getTripsByDate,
    getTripsDetailByDate,
    getDriverTenure,
    getTotalTrips,
  } = useReadRideStats();

  const [datesStructure, setDatesStructure] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [popUp, setPopUp] = useState({
    warning: false,
    edit: false,
    positive: false,
  });

  const handleEditPopup = () => {
    setPopUp((prevState) => ({
      ...prevState,
      edit: false,
    }));
    getPointById(id);
  };

  useEffect(() => {
    if (id) {
      getPointById(id);
    }
  }, [id]);

  useEffect(() => {
    if (deletedPoint) {
      setPopUp((prevState) => ({
        ...prevState,
        positive: true,
      }));
    }
  }, [deletedPoint]);

  return (
    <section className="cardLayout">
      <div className="cardLayout__title">
        <Header name="Puntos de interés" />
      </div>
      <section className="cardLayout__container">
        <div className="cardLayout__column">
          <div className="cardLayout__subtitleWithActionButtons">
            <GoBackButton
              onClick={() => navigate(-1)}
              title="Consulte los detalles del punto de interés"
              subtitle="Verifique el punto de interés en el mapa"
            />
            {point && (
              <div className="main_structure__headerButtons">
                <button
                  className="action-button action-button--secondary"
                  onClick={() =>
                    setPopUp((prevState) => ({ ...prevState, edit: true }))
                  }
                >
                  Editar
                </button>
                <button
                  className="alert-button alert-button--primary"
                  onClick={() =>
                    setPopUp((prevState) => ({ ...prevState, warning: true }))
                  }
                >
                  Eliminar
                </button>
              </div>
            )}
          </div>
          <div className="cardLayout__content">
            {point && (
              <ClickableMap
                pointLocation={point.location}
                isTouchable={false}
              />
            )}
          </div>
        </div>
        <div className="cardLayout__card">
          {point && <PointCard point={point} isDescriptive={false} />}
        </div>
      </section>

      <Overlays>
        {popUp.edit && (
          <EditPoint
            point={point}
            pointId={point._id}
            close={handleEditPopup}
          />
        )}
        {popUp.warning && (
          <PopUp
            type={"warning"}
            title={"¿Desea eliminarlo?"}
            subtitle={"Esta acción es irreversible"}
          >
            <button
              className="alert-button alert-button--secondary"
              onClick={() => deletePoint(id)}
            >
              Sí
            </button>
            <button
              className="alert-button alert-button--primary"
              onClick={() => {
                setPopUp({ ...popUp, warning: false });
              }}
            >
              No
            </button>
          </PopUp>
        )}
        {popUp.positive && (
          <PopUp
            type={"positive"}
            title={"¡El Punto se ha borrado exitosamente!"}
            subtitle={`Al presionar "Aceptar" volverá a la vista principal`}
          >
            <button
              className="action-button action-button--primary"
              onClick={() => navigate(-1)}
            >
              Aceptar
            </button>
          </PopUp>
        )}
      </Overlays>
    </section>
  );
};

export default AboutPoint;
