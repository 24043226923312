import { useState } from "react";
import useAxiosPrivate from "./useAxiosPrivate";

import { FormEvents } from "../states/createUser";

export function useReadVehicles() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [vehicles, setVehicles] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [vehiclesError, setError] = useState(null);

  const getVehicles = async (limit, page, plate) => {
    let params = new URLSearchParams();

    if (limit) params.append('limit', limit);
    if (page) params.append('page', page);
    if (plate) params.append('plate', plate);

    const url = `vehicle?${params.toString()}`;

    try {
      const response = await axiosPrivate.get(
        url,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      );
      if (isMounted) {
        setVehicles(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
        setVehicles(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  const getVehicleById = async (userId) => {
    setVehicle(null);

    try {
      const response = await axiosPrivate.get(
        `vehicle/${userId}`,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      );
      if (isMounted) {
        setVehicle(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
        setVehicle(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  return { vehicles, vehicle, vehiclesError, getVehicles, getVehicleById };
}

export function useUpdateVehicles() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [updatedVehicle, setUpdatedVehicle] = useState(null);
  const [registrationImage, setRegistrationImage] = useState(null);
  const [updateVehicleError, setError] = useState(null);

  const updateVehicle = async (vehicle, vehicleId) => {
    setError(null);

    try {
      const response = await axiosPrivate.patch(
        `vehicle/${vehicleId}/update`,
        vehicle,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      );
      if (isMounted) {
        setUpdatedVehicle(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  const uploadRegistrationImage = async (imageData, vehicleId) => {
    const formData = new FormData();
    formData.append('vehicleRegistrationImage', imageData[0]);
    setError(false);
    setRegistrationImage(null);

    try {
      const response = await axiosPrivate.patch(
        `vehicle/${vehicleId}/upload-registration`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      if (isMounted) {
        setRegistrationImage(response?.data)
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  return { updatedVehicle, registrationImage, updateVehicleError, updateVehicle, uploadRegistrationImage };
}

export function useCreateVehicles() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [vehicle, setVehicle] = useState(null);
  const [createVehicleError, setError] = useState(null);

  const createVehicle = async (vehicle, dispatch) => {
    setError(false);

    try {
      const response = await axiosPrivate.post(
        'vehicle/create',
        vehicle,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      );
      if (isMounted) {
        setVehicle(response?.data);
        dispatch(FormEvents.CreateUserSuccess);
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
        dispatch(FormEvents.CreateUserFail);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  return { vehicle, createVehicleError, createVehicle };
}

export function useDeleteVehicle() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [vehicleDeleted, setVehicleDeleted] = useState(false);
  const [deleteVehicleError, setError] = useState(null);

  const deleteVehicle = async (vehicleId) => {
    setVehicleDeleted(null);

    try {
      const response = await axiosPrivate.delete(
        `vehicle/${vehicleId}/delete`,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      );
      if (isMounted) {
        setVehicleDeleted(response?.data)
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  return { vehicleDeleted, deleteVehicleError, deleteVehicle };
}