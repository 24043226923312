import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import CloudIcon from "../../../assets/svg/CloudIcon";
import ProfilePicture from "../../../assets/svg/ProfilePicture";
import UploadSitePicture from "../../uploadFile/uploadSitePicture";

const AddPointsPicture = ({ state, formState, validImages, uploadPictures }) => {
  const [pointPictureName, setPointPictureName] = useState("");
  const [documentsCompleted, setDocumentsCompleted] = useState(0);

  const {
    register,
    getFieldState,
    formState: { isDirty, isValid },
    handleSubmit,
  } = useForm();

  const isImageUploadValid = getFieldState("poiImage");

  const onSubmit = (data) => {
    uploadPictures(data.poiImage);
  };

  useEffect(() => {
    if (isImageUploadValid.isDirty) {
      setDocumentsCompleted(1);
      return;
    }
  }, [isImageUploadValid]);

  useEffect(() => {
    validImages({ isDirty, isValid });
  }, [isDirty, isValid, validImages]);

  return (
    <form
      id="poi-picture"
      onSubmit={handleSubmit(onSubmit)}
      className="form form__upload"
      style={state === formState.PointImage ? {} : { display: "none" }}>
      <h3>Documentos requeridos</h3>
      <UploadSitePicture 
        show
        pointPictureName={pointPictureName}
        setPointPictureName={setPointPictureName}
        register={register}
      />

      {/* <div className="form__upload_container">
        <ProfilePicture className="form__profile_picture" />
        <div className="form__upload_files">
          <p>Fotografía del sitio</p>
          <div className={`${pointPictureName ? "form__drag_and_drop--active" : "form__drag_and_drop"}`}>
            <CloudIcon />
            <h4>{pointPictureName ? pointPictureName : "Arrastra y suelta el archivo aquí"}</h4>
            <input
              type="file"
              id="poiImage"
              accept=".jpg,.png,.jpeg"
              {...register("poiImage", {
                onChange: (e) => {
                  setPointPictureName(e.target.files[0]?.name);
                },
                required: "Es necesaria una imagen",
              })}
            />
          </div>
          <button
            type="button"
            className="form__desktop_button"
            onClick={() => document.getElementById("poiImage").click()}
          >
            Busca en tus archivos
          </button>
          <button
            type="button"
            className="action-button action-button--primary form__mobile_button"
            onClick={() => document.getElementById("poiImage").click()}
          >
            Busca en tus archivos
          </button>
          <ol>
            <li>La fotografía debe ser clara y legible</li>
            <li>
              Debe ser una fotografía donde se identifique el tipo de sitio, comercio o actividad a realizar en ese
              lugar
            </li>
          </ol>
        </div>
      </div> */}
    </form>
  );
};

export default AddPointsPicture;
