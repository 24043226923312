import BarChar from "../barChart";
import CarIcon from "../../assets/svg/CarIcon";
import TimeIcon from "../../assets/svg/TimeIcon";

import NoUserImage from "../../assets/icons/user.png";
import HistoryTable from "../tables/HistoryTable";
import { languagesEs, transformToStringLanguages } from "../../models/languages";
import { gendersEs, transformToStringGender } from "../../models/genders";
import { useEffect } from "react";
import { useReadRides } from "../../hooks/useRides";

const AboutDriverInfo = ({ driver, driverTenure, totalTrips, stats, setSelectedDay }) => {
  const { rides, getCompletedRidesByDriver } = useReadRides();

  useEffect(() => {
    if (driver) {
      getCompletedRidesByDriver(driver._id, 1);
    }
  }, [driver])


  return (
    <div className="aboutInfo">
      <div className="aboutInfo__profile">
        <div className="aboutInfo__profileName">
          <div className="aboutInfo__image">
            <img
              src={driver?.user?.profilePicture ? driver.user.profilePicture : NoUserImage}
              alt="Client"
            />
          </div>
          <h2>
            {driver?.user?.name} {driver?.user?.lastName}
          </h2>
        </div>
        <div className="aboutInfo__profileContent">
          <p className="aboutInfo__profileField">
            Correo
            <span>{driver?.user?.email}</span>
          </p>
          <p className="aboutInfo__profileField">
            Género
            <span>{transformToStringGender(driver?.user?.gender, gendersEs)}</span>
          </p>
          <p className="aboutInfo__profileField">
            Número de teléfono
            <span>{driver?.user?.phone}</span>
          </p>
          <p className="aboutInfo__profileField">
            Idiomas
            <span>{driver && transformToStringLanguages(driver?.user?.languages, languagesEs)}</span>
          </p>
        </div>
      </div>

      {/* <section className="aboutInfo__stats">
        <div className="aboutInfo__column">
          <CarIcon />
          <h2>2 Viajes</h2>
          <p>Realizados en total</p>
        </div>
        <div className="aboutInfo__column">
          <TimeIcon />
          <h2>2 Años</h2>
          <p>En la plataforma</p>
        </div>
      </section> */}
      {/* <BarChar
        stats={stats}
        setSelectedDay={setSelectedDay}
      /> */}
      <div className="aboutInfo__table">
        <h2>
          Historial de viajes de {driver?.user?.name} {driver?.user?.lastName}
        </h2>
        {rides && <HistoryTable data={rides.data} driver />}
      </div>
    </div>
  );
};

export default AboutDriverInfo;
