import { useForm, Controller } from "react-hook-form";
import { createUserModel } from "../../models/userModels";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function SignUpForm({ newUser, setNewUser, changeForm, error }) {
  const {
    register,
    reset,
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    if (data) {
      const temporalUser = createUserModel(data);
      setNewUser(temporalUser);
      changeForm(true);
    }
  };

  useEffect(() => {
    if (newUser) {
      reset(newUser);
    }
  }, [reset, newUser]);

  return (
    <form
      className="signUp__form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {error && <p className="error_message">{error.response?.data?.error?.message}</p>}
      <div className="two-columns">
        <div className="form__input_container">
          <label>Name</label>
          <input
            type="text"
            {...register("name", {
              required: "Name is required",
            })}
          />
          {errors.name?.message && <p className="error">{errors.name?.message}</p>}
        </div>
        <div className="form__input_container">
          <label>Last Name</label>
          <input
            type="text"
            {...register("lastName", {
              required: "Last Name is required",
            })}
          />
          {errors.lastName?.message && <p className="error">{errors.lastName?.message}</p>}
        </div>
      </div>
      <div className="form__input_container">
        <label>Email</label>
        <input
          type="email"
          {...register("email", {
            required: "Email is required",
          })}
        />
        {errors.email?.message && <p className="error">{errors.email?.message}</p>}
      </div>
      <div className="two-columns">
        <div className="form__input_container">
          <label>Password</label>
          <input
            type="password"
            {...register("password", {
              required: "Password is required",
            })}
          />
          {errors.password?.message && <p className="error">{errors.password?.message}</p>}
        </div>
        <div className="form__input_container">
          <label>Confirm your Password</label>
          <Controller
            control={control}
            name="confirmPassword"
            render={({ field }) => (
              <input
                type="password"
                {...field}
              />
            )}
            rules={{
              required: "Password is required",
              validate: (value) => value === watch("password") || "Passwords not match",
            }}
          />
          {errors.confirmPassword?.message && <p className="error">{errors.confirmPassword?.message}</p>}
        </div>
      </div>
      <div className="signUp__checkbox">
        <input
          className="checkbox"
          type="checkbox"
          {...register("terms", {
            required: "Confirm Terms is required",
          })}
        />
        <p className="signUp__termsAndConditions">
          I accept the
          <Link to="/terms-and-conditions">Agreements</Link>
          and the <Link to="/privacy-policy">Privacy Policy</Link>
        </p>
      </div>
      {errors.terms?.message && <p className="error">{errors.terms?.message}</p>}

      <button
        className="action-button action-button--primary"
        type="submit"
      >
        <p>Continue</p>
      </button>
    </form>
  );
}

export default SignUpForm;
