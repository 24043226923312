import React from 'react'

const ProfileIcon = () => {
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6514 19.9711V17.9711C17.6514 16.9102 17.2299 15.8928 16.4798 15.1426C15.7296 14.3925 14.7122 13.9711 13.6514 13.9711H5.65137C4.5905 13.9711 3.57309 14.3925 2.82294 15.1426C2.07279 15.8928 1.65137 16.9102 1.65137 17.9711V19.9711"
        stroke="#959595"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.65137 9.97107C11.8605 9.97107 13.6514 8.18021 13.6514 5.97107C13.6514 3.76193 11.8605 1.97107 9.65137 1.97107C7.44223 1.97107 5.65137 3.76193 5.65137 5.97107C5.65137 8.18021 7.44223 9.97107 9.65137 9.97107Z"
        stroke="#959595"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ProfileIcon