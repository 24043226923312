import ClickableMap from '../../maps/clickableMap';

const AddPointsMap = ({ state, formState, setPointLocation }) => {

  return (
    <section className="form__map" style={state === formState.PointLocation ? {} : { display: 'none' }}>
      <ClickableMap
        setPointLocation={setPointLocation}
        isTouchable={true}
      />
    </section>
  );
}

export default AddPointsMap