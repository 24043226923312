import React, { useEffect, useState } from 'react';
import { PageSelector } from './pageSelector';

const Paginator = ({ maxPages, search, resultsShown, maxResults, page }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const selectPage = (number) => {
    setCurrentPage(number);
    search(resultsShown, number);
  };

  const renderPageNumbers = () => (
    Array(maxPages).fill(null).map((_, i) => (
      <PageSelector
        key={i}
        currentPage={currentPage}
        maxPages={maxPages}
        pageNumber={i + 1}
        selectPage={selectPage}
      />
    ))
  );

  useEffect(() => {
    setCurrentPage(page);
  }, [page])

  return (
    <div className='paginator'>
      <div className="paginator__container">
        {renderPageNumbers()}
      </div>
      {currentPage === maxPages && <p>{maxResults} de <strong>{maxResults} resultados</strong></p>}
      {currentPage < maxPages && <p>{resultsShown * currentPage} de <strong>{maxResults} resultados</strong></p>}
    </div>
  );
};

export default Paginator;