import { useState } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { FormEvents } from "../states/createUser";

export function useReadContent() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [images, setImages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getCovers = async () => {
    setLoading(true);
    setImages(null);

    try {
      const response = await axiosPrivate.get(
        'content/gallery',
      );
      if (isMounted) {
        setImages(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
        setImages(null);
      }
    } finally {
      isMounted && setLoading(false);
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  const getArticles = async (category) => {
    setLoading(true);
    setImages(null);

    try {
      const response = await axiosPrivate.get(
        category
          ? `content?category=${category}`
          : 'content',
      );
      if (isMounted) {
        setImages(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
        setImages(null);
      }
    } finally {
      isMounted && setLoading(false);
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  return { images, loading, error, getCovers, getArticles };
}

export function useCreateContent() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [content, setContent] = useState(false);
  const [createContentError, setError] = useState(null);

  const createCover = async (imageData, dispatch) => {
    const formData = new FormData();

    formData.append('image', imageData.coverPicture[0]);
    setError(null);

    try {
      await axiosPrivate.post(
        `content/gallery/upload?caption=${imageData.captionImage}`,
        formData,
      );
      if (isMounted) {
        dispatch(FormEvents.CreateUserSuccess)
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
        dispatch(FormEvents.CreateUserFail)
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  const createArticle = async (article, dispatch) => {
    setError(null);
    setContent(null);

    try {
      const response = await axiosPrivate.post(
        `content/create`,
        article,
      );
      if (isMounted) {
        setContent(response?.data);
        dispatch(FormEvents.CreateUserSuccess)
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
        dispatch(FormEvents.CreateUserFail)
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  return { content, createContentError, createCover, createArticle };
}

export function useUpdateContent() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [updatedContent, setUpdatedContent] = useState(null);
  const [contentImage, setContentImage] = useState(null);
  const [updateContentError, setUpdateContentError] = useState(null);

  const updateArticle = async (content, contentId) => {
    setUpdateContentError(null);
    setUpdatedContent(null);

    try {
      const response = await axiosPrivate.patch(
        `content/${contentId}/update`,
        content,
      );
      if (isMounted) {
        setUpdatedContent(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setUpdateContentError(error);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  const uploadArticleImage = async (imageData, articleId) => {
    const formData = new FormData();
    formData.append('contentImage', imageData[0]);

    try {
      const response = await axiosPrivate.patch(
        `content/${articleId}/update-image`,
        formData,
      );
      if (isMounted) {
        setContentImage(response?.data)
      }
    } catch (error) {
      if (isMounted) {
        setUpdateContentError(error)
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  return { updatedContent, contentImage, updateContentError, uploadArticleImage, updateArticle };
}

export function useDeleteContent() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [deleteImageLoading, setLoading] = useState(false);
  const [deleteImageError, setError] = useState(null);

  const deleteCover = async (imageId) => {
    setLoading(true);

    try {
      await axiosPrivate.delete(
        `content/gallery/${imageId}/delete`,
      );
    } catch (error) {
      if (isMounted) {
        setError(error);
      }
    } finally {
      isMounted && setLoading(false);
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  const deleteArticle = async (imageId) => {
    setLoading(true);
    console.log(imageId)

    try {
      await axiosPrivate.delete(
        `content/${imageId}/delete`,
      );
    } catch (error) {
      if (isMounted) {
        setError(error);
      }
    } finally {
      isMounted && setLoading(false);
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  return { deleteImageError, deleteImageLoading, deleteCover, deleteArticle };
}