import { useState, useEffect } from "react";
import { useReadRides } from "../../../hooks/useRides";
import { useAuth } from "../../../hooks/useAuth";

import Header from "../../../components/Header";
import CategoryButton from "../../../components/CategoryButton";
import TripsTable from "../../../components/tables/TripsTable";
import TripCard from "../../../components/informativeCards/tripCard";
import InformativeIcon from "../../../assets/svg/InformativeIcon";
import LazyLoading from "../../../components/LazyLoading";

function DriverTrips() {
  const [date, setDate] = useState("today");
  const [tripSelected, setTripSelected] = useState();
  const [isLoading, setIsLoading] = useState();

  const { auth } = useAuth();
  const { rides, getTodayRidesByDriver, getNextRidesByDriver } = useReadRides();

  useEffect(() => {
    const fetchRides = async () => {
      if (date === "today") {
        setIsLoading(true);
        await getTodayRidesByDriver();
        setIsLoading(false);
      } else {
        if (auth) {
          setIsLoading(true);
          await getNextRidesByDriver();
          setIsLoading(false);
        }
      }
    };
    fetchRides();
  }, [date]);

  return (
    <section className="cardLayout">
      <div className="cardLayout__title">
        <Header name="Mis viajes asignados" />
      </div>
      <div className="cardLayout__container">
        <div className="cardLayout__column">
          <div className="cardLayout__categories">
            <CategoryButton
              isActive={date === "today"}
              name="De hoy"
              onClick={() => setDate("today")}
            />
            <CategoryButton
              isActive={date === "tomorrow"}
              name="Próximos"
              onClick={() => setDate("tomorrow")}
            />
          </div>
          <h2 className="cardLayout__subtitle">Consulte sus viajes asignados para esta semana</h2>
          <section className={`cardLayout__content`}>
            {!isLoading && rides?.length > 0 && (
              <TripsTable
                driver
                data={rides}
                selected={setTripSelected}
              />
            )}
            {isLoading && <LazyLoading />}
            {!isLoading && (!rides || rides.length === 0) && (
              <div className="informativeContainer">
                <div>
                  <InformativeIcon />
                </div>
                <h2>Aún no tienes viajes asignados</h2>
                <p>No tienes viajes asignados por ahora. Si tienes algún problema, contáctate con un administrador.</p>
              </div>
            )}
          </section>
        </div>
        <div className="cardLayout__card">
          {tripSelected && (
            <TripCard
              isDriver
              onClose={() => setTripSelected(null)}
              data={tripSelected}
            />
          )}
        </div>
      </div>
    </section>
  );
}

export default DriverTrips;
