function PrivacyPolicy() {
  return (
    <section className="terms_container">
      <h1>Nosara Shuttle Privacy Policy</h1>
      <p>Effective Date: September 23, 2024</p>

      <div className="terms_section">
        <h2>1. Information We Collect</h2>
        <p>When using Nosara Shuttle, we may collect various types of personal information, including:</p>
        <ul>
          <li>
            <strong>Full Name:</strong> To identify you on our platform.
          </li>
          <li>
            <strong>Email Address:</strong> Used for communication and password recovery.
          </li>
          <li>
            <strong>Password:</strong> Stored in an encrypted format to ensure your security.
          </li>
          <li>
            <strong>Profile Picture:</strong> If you choose to upload one, it personalizes your account.
          </li>
          <li>
            <strong>Country Code and Phone Number:</strong> For communication and identity verification.
          </li>
          <li>
            <strong>Gender and Account Provider:</strong> To personalize your user experience.
          </li>
          <li>
            <strong>User Role:</strong> Determines access levels within the app.
          </li>
          <li>
            <strong>Preferred Languages:</strong> To provide content in your chosen language.
          </li>
          <li>
            <strong>Real-Time Location:</strong> Collected while using the travel function via Mapbox to provide
            accurate navigation services.
          </li>
          <li>
            <strong>Account Activity:</strong> Creation and update dates to manage the timeline of your account.
          </li>
        </ul>
      </div>

      <div className="terms_section">
        <h2>2. Collection Methods</h2>
        <p>We collect personal information through the following methods:</p>
        <ul>
          <li>
            <strong>Registration Forms:</strong> Information directly provided by you when creating an account on our
            app.
          </li>
          <li>
            <strong>Mapbox:</strong> A third-party service used to collect location data while utilizing our navigation
            features.
          </li>
        </ul>
      </div>

      <div className="terms_section">
        <h2>3. Use of Information</h2>
        <p>The information collected is used for the following purposes:</p>
        <ul>
          <li>
            <strong>Service Operation and Improvement:</strong> To provide, maintain, and enhance the Nosara Shuttle
            services.
          </li>
          <li>
            <strong>User Support:</strong> To respond to your inquiries, resolve technical issues, and ensure a smooth
            user experience.
          </li>
          <li>
            <strong>Security and Protection:</strong> To implement security measures such as JWT authentication and
            password encryption to safeguard your account and personal data.
          </li>
          <li>
            <strong>Service Personalization:</strong> To tailor our services and content to your preferences and
            location.
          </li>
        </ul>
      </div>

      <div className="terms_section">
        <h2>4. Information Sharing</h2>
        <p>We only share your information in the following circumstances:</p>
        <ul>
          <li>
            <strong>With Mapbox:</strong> To facilitate navigation services and improve travel route accuracy.
          </li>
          <li>
            <strong>Legal Compliance:</strong> If required to fulfill legal obligations, protect our rights, or respond
            to government requests.
          </li>
          <li>
            <strong>Service Providers:</strong> With providers that help us operate our app, as long as they comply with
            applicable privacy regulations.
          </li>
        </ul>
      </div>

      <div className="terms_section">
        <h2>5. Storage and Security</h2>
        <ul>
          <li>
            <strong>Data Security:</strong> Your personal data is securely stored in our databases, protected by
            advanced security measures, including password encryption and secure authentication.
          </li>
          <li>
            <strong>Restricted Access:</strong> Only authorized personnel have access to your personal data, and this
            access is strictly controlled.
          </li>
        </ul>
      </div>

      <div className="terms_section">
        <h2>6. Data Retention</h2>
        <p>
          We retain your personal data as long as your account is active or as necessary to provide you with services.
          You may request the deletion of your information at any time through our contact channels.
        </p>
      </div>

      <div className="terms_section">
        <h2>7. User Rights</h2>
        <p>Users have the following rights regarding their personal data:</p>
        <ul>
          <li>
            <strong>Access:</strong> You can request access to the personal information we hold about you.
          </li>
          <li>
            <strong>Rectification:</strong> You have the right to correct any inaccurate or incomplete personal
            information.
          </li>
          <li>
            <strong>Deletion:</strong> You can request the deletion of your account and personal data.
          </li>
          <li>
            <strong>Objection and Restriction:</strong> You can object to the processing of your data in certain
            circumstances or request restrictions on the use of your data. To exercise these rights, contact us at
            info@nosara.com.
          </li>
        </ul>
      </div>

      <div className="terms_section">
        <h2>8. Legal Compliance</h2>
        <p>
          We are committed to complying with all applicable data protection regulations, including Costa Rican privacy
          laws and other relevant international regulations such as the GDPR and CCPA, when applicable.
        </p>
      </div>

      <div className="terms_section">
        <h2>9. Changes to this Privacy Policy</h2>
        <p>
          We reserve the right to modify this privacy policy at any time. We will notify you of any significant changes
          through our app or by email, and we recommend that you review this policy regularly.
        </p>
      </div>

      <div className="terms_section">
        <h2>10. Contact</h2>
        <p>
          If you have any questions, concerns, or comments about this privacy policy, please feel free to contact us at
          info@nosara.com.
        </p>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
