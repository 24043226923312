import "react-international-phone/style.css";

import { useForm, Controller } from "react-hook-form";
import { useEffect } from "react";

import { tractions } from "../../../models/tractions";
import { months } from "../../../models/months";

import Select from 'react-select'

const AddVehicleInformation = ({ state, formState, createVehicle, validVehicle }) => {

  const { register, control, formState: { errors, isDirty, isValid }, handleSubmit } = useForm();

  const onSubmit = (data) => {
    const vehicle = {
      ...data,
      trunkCapacity: parseInt(data.trunkCapacity),
      passengerCapacity: parseInt(data.passengerCapacity),
      surfboardCapacity: parseInt(data.surfboardCapacity),
      status: "Available"
    }
    createVehicle(vehicle);
  }

  useEffect(() => {
    validVehicle({ isDirty, isValid })
  }, [isDirty, isValid, validVehicle])

  return (
    <form id="vehicle-information" onSubmit={handleSubmit(onSubmit)}>
      <section style={state === formState.UserInformation ? {} : { display: "none" }}>
        <h3>Información básica del vehículo</h3>
        <div className="form__two_columns">
          <div className="form__input_container">
            <label htmlFor="">Placa del vehículo</label>
            <input
              className={errors.plate?.message ? 'error' : ''}
              type="text"
              placeholder="Escribe la placa"
              {...register('plate', {
                required: "Es requerida una placa"
              })}
            />
          </div>
          <div className="form__input_container">
            <label htmlFor="">Modelo del vehículo</label>
            <input
              className={errors.model?.message ? 'error' : ''}
              type="text"
              placeholder="Escribe el modelo"
              {...register('model', {
                required: "Es requerido un modelo"
              })}
            />
          </div>
        </div>
        <div className="form__two_columns">
          <div className="form__input_container">
            <label htmlFor="">Capacidad del vehículo</label>
            <input
              className={errors.model?.message ? 'error' : ''}
              type="number"
              placeholder="Escribe el modelo"
              {...register('passengerCapacity', {
                required: "Es requerido un número"
              })}
            />
          </div>
          <div className="form__input_container">
            <label htmlFor="">Capacidad del maletero</label>
            <input
              className={errors.model?.message ? 'error' : ''}
              type="number"
              placeholder="Escribe el modelo"
              {...register('trunkCapacity', {
                required: "Es requerido un número"
              })}
            />
          </div>
        </div>
        <div className="form__two_columns">
          <div className="form__input_container">
            <label htmlFor="">Cantidad de tablas de surf</label>
            <input
              className={errors.model?.message ? 'error' : ''}
              type="number"
              placeholder="Escribe el modelo"
              {...register('surfboardCapacity', {
                required: "Es requerido un número"
              })}
            />
          </div>
          <div className="form__input_container">
            <label htmlFor="">Tipo de tracción</label>
            <Controller
              render={({ field: { onChange } }) => (
                <Select
                  classNamePrefix="react-select"
                  isClearable={false}
                  options={tractions}
                  placeholder="Selecciona el tipo de tracción"
                  onChange={(value) => { onChange(value.value) }}
                />
              )}
              control={control}
              name="traction"
              rules={{ required: true }}
            />
          </div>
        </div>
      </section>
      <section style={state === formState.UserInformation ? {} : { display: "none" }}>
        <h3>Información mecánica del vehículo</h3>
        <div className="form__input_container">
          <label htmlFor="">Mes de la revisión técnica</label>
          <Controller
            render={({ field: { onChange } }) => (
              <Select
                classNamePrefix="react-select"
                isClearable={false}
                options={months}
                placeholder="Seleccione el mes de inspección"
                onChange={(value) => { onChange(value.value) }}
              />
            )}
            control={control}
            name="technicalReviewMonth"
            rules={{ required: true }}
          />
        </div>
        <div className="form__input_container">
          <label htmlFor="">Descripción de detalles reportados en anteriores revisiones mecánicas</label>
          <textarea
            cols="30"
            rows="10"
            placeholder="Escriba los detalles anteriormente reportados"
            {...register('previousMechanicalIssuesDescription', {
              required: false
            })}
          ></textarea>
        </div>
      </section>
    </form>
  );
}

export default AddVehicleInformation