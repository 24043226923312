import React from 'react'

const ChatIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M45.3149 22.629C45.323 25.7114 44.6028 28.7521 43.2131 31.5034C41.5653 34.8003 39.0322 37.5734 35.8974 39.512C32.7627 41.4506 29.1501 42.4782 25.4643 42.4796C22.3819 42.4877 19.3412 41.7675 16.5899 40.3778L3.27832 44.815L7.71552 31.5034C6.32581 28.7521 5.60565 25.7114 5.61369 22.629C5.61511 18.9432 6.64268 15.3306 8.58128 12.1959C10.5199 9.0611 13.293 6.52798 16.5899 4.88022C19.3412 3.49051 22.3819 2.77035 25.4643 2.77839H26.632C31.4997 3.04693 36.0973 5.10152 39.5446 8.54875C42.9918 11.996 45.0464 16.5936 45.3149 21.4613V22.629Z"
        stroke="white"
        stroke-width="4.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ChatIcon