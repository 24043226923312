const FormState = {
    UserInformation: 0,
    CreatingUser: 1,
    UserImages: 2,
    UploadingImages: 3,
    Completed: 4
}

const FormEvents = {
    CreateUser: 0,
    CreateUserSuccess: 1,
    CreateUserFail: 2,
    UploadImages: 3,
    UploadImagesSuccess: 4,
    UploadImagesFail: 5
}

const createUserStates = (state, event) => {
    switch (state) {
        case FormState.UserInformation:
            if (event === FormEvents.CreateUser) return FormState.CreatingUser;
            break;
        case FormState.CreatingUser:
            if (event === FormEvents.CreateUserSuccess) return FormState.UserImages;
            if (event === FormEvents.CreateUserFail) return FormState.UserInformation;
            break;
        case FormState.UserImages:
            if (event === FormEvents.UploadImages) return FormState.UploadingImages;
            break;
        case FormState.UploadingImages:
            if (event === FormEvents.UploadImagesSuccess) return FormState.Completed;
            if (event === FormEvents.UploadImagesFail) return FormState.UserImages;
            break;
        default:
            return state;
    }
};

export { FormState, FormEvents, createUserStates };