function Delete() {
  return (
    <section className="terms_container">
      <h1>Nosara Shuttle Account Deletion Policy</h1>

      <div className="terms_section">
        <h2>1. Data Deletion</h2>
        <p>
          Upon requesting the deletion of your account, Nosara Shuttle will immediately and permanently remove all
          personal information associated with your profile from our system. This includes, but is not limited to:
        </p>
        <ul>
          <li>Personal data provided during registration or through the use of the app.</li>
          <li>Profile information, including your profile picture and contact details.</li>
        </ul>
        <p>
          Please note that no personal data will be retained in our database after the deletion request is processed.
          Once deleted, this data cannot be recovered.
        </p>
      </div>

      <div className="terms_section">
        <h2>2. Data Retention</h2>
        <p>
          Certain non-personal information will be retained following the deletion of your account, in compliance with
          legal and operational requirements. Specifically, the following data will be retained:
        </p>
        <ul>
          <li>Records of trips assigned, completed, or canceled under your account.</li>
        </ul>
        <p>
          This data is retained for operational purposes and for compliance with legal obligations. We guarantee that
          any retained data will be handled in strict accordance with applicable data protection laws and will not be
          used for any other purpose.
        </p>
      </div>

      <div className="terms_section">
        <h2>3. Steps to Delete Your Account</h2>
        <p>To initiate the account deletion process, please follow these steps:</p>
        <ul>
          <li>Log in to your account via nosara.site.</li>
          <li>Navigate to the Profile section.</li>
          <li>Press the button “Delete my account”.</li>
          <li>Confirm your request by agreeing to the prompt asking if you wish to permanently delete your account.</li>
        </ul>
      </div>
    </section>
  );
}

export default Delete;