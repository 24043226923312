import React from 'react'

const HistoryIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6511 17.6622C21.6511 18.1927 21.4404 18.7014 21.0653 19.0764C20.6903 19.4515 20.1816 19.6622 19.6511 19.6622H3.65112C3.12069 19.6622 2.61198 19.4515 2.23691 19.0764C1.86184 18.7014 1.65112 18.1927 1.65112 17.6622V3.66223C1.65112 3.1318 1.86184 2.62309 2.23691 2.24802C2.61198 1.87295 3.12069 1.66223 3.65112 1.66223H8.65112L10.6511 4.66223H19.6511C20.1816 4.66223 20.6903 4.87295 21.0653 5.24802C21.4404 5.62309 21.6511 6.1318 21.6511 6.66223V17.6622Z"
        stroke="#959595"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default HistoryIcon