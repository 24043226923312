import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { PhoneInput } from "react-international-phone";
import { languagesEs } from "../../../models/languages";
import { gendersEs } from "../../../models/genders";
import { adminForm } from "../../../models/roles";

import Select from 'react-select';

const AddManagerInformation = ({ state, formState, createUser, validUser }) => {
  const [countryCode, setCountryCode] = useState("")
  const { register, control, formState: { errors, isDirty, isValid }, handleSubmit } = useForm();

  const onSubmit = (data) => {
    const user = {
      ...data,
      accountProvider: "Nosara",
      countryCode: countryCode.toLocaleUpperCase(),
    };
    createUser(user);
  };

  useEffect(() => {
    validUser({ isDirty, isValid });
  }, [isDirty, isValid, validUser]);

  return (
    <form
      id="manager-information"
      onSubmit={handleSubmit(onSubmit)}
    >
      <section style={state === formState.UserInformation ? {} : { display: "none" }}>
        <h3>Información Personal</h3>
        <div className="form__two_columns">
          <div className="form__input_container">
            <label htmlFor="">Nombre (s)</label>
            <input
              type="text"
              placeholder="Escribe su nombre"
              {...register("name", {
                required: "Es necesario un nombre",
              })}
            />
          </div>
          <div className="form__input_container">
            <label htmlFor="">Apellido (s)</label>
            <input
              type="text"
              placeholder="Escribe su apellido"
              {...register("lastName", {
                required: "Es necesario un apellido",
              })}
            />
          </div>
        </div>
        <div className="form__two_columns">
          <div className="form__input_container">
            <label htmlFor="">Teléfono</label>
            <Controller
              render={({ field: { onChange } }) => (
                <PhoneInput
                  inputStyle={errors.phone ? { borderColor: "#F55555" } : { borderColor: "#7C7C7C" }}
                  defaultCountry="cr"
                  onChange={(phone, country) => {
                    setCountryCode(country.country.iso2);
                    onChange(phone);
                  }}
                />
              )}
              control={control}
              name="phone"
              rules={{ required: true, minLength: 5 }}
            ></Controller>
          </div>
          <div className="form__input_container">
            <label htmlFor="">Género</label>
            <Controller
              render={({ field: { onChange } }) => (
                <Select
                  classNamePrefix="react-select"
                  isClearable={false}
                  options={gendersEs}
                  placeholder="Seleccione un género"
                  onChange={(value) => {
                    onChange(value.value);
                  }}
                />
              )}
              control={control}
              name="gender"
              rules={{ required: true }}
            />
          </div>
        </div>
        <div className="form__input_container">
          <label htmlFor="">Idiomas</label>
          <Controller
            render={({ field: { onChange } }) => (
              <Select
                classNamePrefix="react-select"
                isClearable={true}
                isMulti={true}
                options={languagesEs}
                placeholder="Seleccione los idiomas"
                onChange={(data) => {
                  onChange(data.map((id) => id.value));
                }}
              />
            )}
            control={control}
            name="languages"
            rules={{ required: true }}
          />
        </div>
      </section>
      <section style={state === formState.UserInformation ? {} : { display: "none" }}>
        <h3>Información de la cuenta</h3>
        <div className="form__input_container">
          <label htmlFor="">Correo Electrónico</label>
          <input
            className={errors.email?.message ? "error" : ""}
            type="email"
            placeholder="Escribe su correo"
            {...register("email", {
              required: "Es requerido un correo electrónico",
            })}
          />
        </div>
        <div className="form__input_container">
          <label htmlFor="">Contraseña *</label>
          <input
            className={errors.password?.message ? "error" : ""}
            type="password"
            placeholder="Escribe su contraseña"
            {...register("password", {
              required: "Es necesaria una contraseña",
            })}
          />
          <p>
            *El administrador al hacer sesión por primera vez deberá generar una contraseña si no se le es asignada una en
            este apartado
          </p>
        </div>
      </section>
      <section style={state === formState.UserInformation ? {} : { display: "none" }}>
        <h3>Rol dentro de la Organización</h3>
        <div className="form__input_container">
          <label htmlFor="">Rol del Administrador</label>
          <Controller
            render={({ field: { onChange } }) => (
              <Select
                classNamePrefix="react-select"
                isClearable={false}
                options={adminForm}
                placeholder="Seleccione un rol"
                onChange={(value) => {
                  onChange(value.value);
                }}
              />
            )}
            control={control}
            name="role"
            rules={{ required: true }}
          />
        </div>
      </section>
    </form>
  );
};

export default AddManagerInformation;
