import { useEffect, useState } from "react";
import { useReadBookings } from "../../../hooks/useBookings";

import { tableHeaderReservations } from "../../../models/userModels";

import StatusButton from "../../../components/statusButtons";
import Header from "../../../components/Header";
import Paginator from "../../../components/paginator/paginator";
import ReservationTable from "../../../components/tables/ReservationTable";
import ReservationCard from "../../../components/informativeCards/ReservationCard";

const MAX_RESULTS = 10;

function Reservations() {
  const { bookings, getBookings } = useReadBookings();

  const [bookingSelected, setBookingSelected] = useState(null);
  const [buttonActive, setButtonActive] = useState("Pending for Assignment");

  const header = tableHeaderReservations();

  const handleSearchBookings = (limit, page) => {
    getBookings(buttonActive, limit, page);
  };

  useEffect(() => {
    setBookingSelected(null);
    handleSearchBookings(MAX_RESULTS, 1);
  }, [buttonActive]);

  return (
    <section className="cardLayout">
      <div className="cardLayout__title">
        <Header name="Reservaciones" />
      </div>
      <section className="cardLayout__container">
        <div className="cardLayout__column">
          <div className="cardLayout__statusButtons">
            <StatusButton
              buttonActive={buttonActive}
              setButtonActive={setButtonActive}
            />
          </div>
          <h2 className="cardLayout__subtitle">
            Añada, consulte o cancele una reservación
          </h2>
          <div className="cardLayout__content">
            <ReservationTable
              header={header}
              reservationCategory={buttonActive}
              data={bookings?.data}
              selected={setBookingSelected}
            />
          </div>
          {bookings && (
            <Paginator
              maxPages={bookings.totalPages}
              search={handleSearchBookings}
              resultsShown={MAX_RESULTS}
              maxResults={bookings.count}
              page={bookings.page}
            />
          )}
        </div>
        <section className="cardLayout__card">
          {bookingSelected && (
            <ReservationCard
              onClose={setBookingSelected}
              data={bookingSelected}
            />
          )}
        </section>
      </section>
    </section>
  );
}

export default Reservations;
