import { useState, useEffect } from 'react';

import { BarChart, Bar, Cell, XAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

function BarChar({ stats, setSelectedDay }) {

  const handleClick = (data, index) => {
    setActiveIndex(index);
    setSelectedDay(data.amt);
  };

  const [activeIndex, setActiveIndex] = useState(-1);

  return (
    <section className='barChar_container'>
      <div className='barChar_header'>
        <h2>Viajes Realizados</h2>
        <button className='barChar_filter'>{"Hace un año"}</button>
      </div>
      <ResponsiveContainer width='99%' height={160}>
        <BarChart data={stats?.data}>
          <CartesianGrid stroke="#D1D1D1" horizontal={true} vertical={false} />
          <XAxis dataKey="name" stroke='#959595' tickLine={false} axisLine={false} />
          <Bar dataKey="uv" onClick={handleClick}>
            {stats?.data?.map((entry, index) => (
              <Cell cursor="pointer" fill={index === activeIndex ? '#47A9E1' : '#D1D1D1'} key={`cell-${index}`} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </section>
  );
}

export default BarChar;