import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../models/routes"
import RouteMap from "../maps/routeMap";

const ReservationCard = ({ onClose, data }) => {
  const navigate = useNavigate();
  const [routeCoordinates, setRouteCoordinates] = useState(null);

  useEffect(() => {
    if (data.ticket.route) {
      setRouteCoordinates(routes.find(route => route.name === data.ticket.route))
    }
  }, [data.ticket.route])

  return (
    <section className="newReservationCard">
      <button
        className="close_button newReservationCard__close"
        onClick={() => onClose(null)}
      >
        <span></span>
        <span></span>
      </button>
      <div className="newReservationCard__map">
        <RouteMap routeCoordinates={routeCoordinates} isTouchable={false} />
        <p className="newReservationCard__id">
          ID Reserva
          <strong>{data.bookingNumber}</strong>
        </p>
      </div>
      <section className="newReservationCard__container">
        <p className="newReservationCard__row">
          Turista que lo solicitó
          <span>{data.clientInfo.name}</span>
        </p>
        <p className="newReservationCard__row">
          Tipo de servicio
          <span>{data.ticket.type}</span>
        </p>
        <p className="newReservationCard__row">
          Ruta
          <span>
            {data.ticket.route}
          </span>
        </p>
        <p className="newReservationCard__row">
          Fecha
          <span>
            {new Date(data.ticket.pickUpDate).toLocaleDateString('es-ES')}
          </span>
        </p>
      </section>
      <div className="newInformativeCard__buttons">
        {data.status === "Pending for Assignment" ? (
          <button
            className="action-button action-button--primary"
            onClick={() => navigate(`${data._id}/add`)}
          >
            Asignar el viaje
          </button>
        ) : (
          <button
            className="action-button action-button--primary"
            onClick={() => navigate(data._id)}
          >
            Más información
          </button>
        )}
      </div>
    </section>
  );
};

export default ReservationCard;
