
const tractions = [
    { label: "Delantera", value: "FWD" },
    { label: "Trasera", value: "RWD" },
    { label: "Integral", value: "AWD" },
    { label: "4x4", value: "4WD" }
]

const transformToStringTractions = (value, tractions) => {
    return tractions.find(item => item.value === value)?.label;
}

export { tractions, transformToStringTractions } 
