const gendersEs = [
    { label: "Masculino", value: "Male" },
    { label: "Femenino", value: "Female" },
    { label: "Otro", value: "Other" },
];

const gendersEn = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Other", value: "Other" },
];

const transformToStringGender = (value, genders) => {
    return genders.find(item => item.value === value)?.label;
}

export { gendersEs, gendersEn, transformToStringGender };