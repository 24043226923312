import { useState } from 'react'
import EditCoverPicture from './EditCoverPicture';
import GoBackButton from '../../GoBackButton';

const EditCover = ({close}) => {
  const [ pictureName, setPictureName ] = useState();

  const pictureData = { pictureName, setPictureName }

  return (
    <>
      <div className="edit-popup__header">
        <GoBackButton
          title="Edite la información del Conductor"
          subtitle="Edite la información del Conductor según sea necesario"
          onClick={close}
          isClose
        />
      </div>
      <EditCoverPicture pictureData={pictureData} />
    </>
  );
}

export default EditCover