import React, { useEffect, useReducer, useState } from "react";
import Header from "../../../components/Header";
import GoBackButton from "../../../components/GoBackButton";
import NewStepProcessBar from "../../../components/newStepProcessBar";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useReadBookings } from "../../../hooks/useBookings";
import { useReadUsers } from "../../../hooks/useUsers";
import { useAssignRides } from "../../../hooks/useRides";
import {
  FormEvents,
  FormState,
  bookingStates,
} from "../../../states/bookingStates";
import PopUp from "../../../components/modals/popUp";
import Overlays from "../../../components/modals/overlays";
import AddReservationInfo from "../../../components/forms/AddReservation/NewAddReservationInfo";
import AddReservationDriver from "../../../components/forms/AddReservation/NewAddReservationDriver";
import AddReservationVehicle from "../../../components/forms/AddReservation/NewAddReservationVehicle";
import AddReservationConfirm from "../../../components/forms/AddReservation/NewAddReservationConfirm";
import LazyLoading from "../../../components/LazyLoading";
import ReservationInfo from "../../../components/reservation/ReservationInfo";

const headerText = {
  title: [
    "Confirme la información de la reservación",
    "Asigne el conductor para la reservación",
    "Asigne el vehículo para la reservación",
    "Confirme la nueva información de la reservación",
    "Asignando la reservación",
    "Reserva asignada"
  ],
  subtitle: [
    "Verifique la información de la reservación antes de asignarla",
    "Seleccione un conductor en la tabla para asignar el conductor a cargo de la reservación",
    "Seleccione un vehículo en la tabla para asignar el vehículo en el que se transportará el turista",
    "Verifique la información ingresada antes de continuar",
    "Esto podría tomar unos minutos...",
    "La reserva se ha asignado correctamente"
  ],
};

const AddReservation = () => {
  const navigate = useNavigate();
  const adminUser = useAuth();

  const { id } = useParams();
  const { booking, getBookingById } = useReadBookings();
  const { ride, assignRideError, assignRide } = useAssignRides();
  const { user, getUserById } = useReadUsers();

  const [driverSelected, setDriverSelected] = useState(null);
  const [vehicleSelected, setVehicleSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [popUp, setPopUp] = useState({
    warning: false,
    informative: false,
    positive: false,
  });

  const [state, dispatch] = useReducer(
    bookingStates,
    FormState.BookingInformation
  );

  const updateState = () => {
    if (state === FormState.CheckBooking) {
      dispatch(FormEvents.NextStep);
    }
  };

  const goBack = () => {
    const isMobileView = window.innerWidth <= 810;

    if (isMobileView && state !== FormState.BookingInformation) {
      dispatch(FormEvents.BackStep);
    } else {
      setPopUp((prevPopup) => ({ ...prevPopup, warning: true }));
    }
  };

  const isButtonDisabled =
    (state === FormState.SelectDriver && !driverSelected) ||
    (state === FormState.SelectVehicle && !vehicleSelected) ||
    (state === FormState.UploadingBooking);

  useEffect(() => {
    if (state === FormState.UploadingBooking) {
      setPopUp({ ...popUp, informative: false });

      const newRide = {
        bookingId: booking._id,
        driverId: driverSelected._id,
        vehicleId: vehicleSelected._id,
        touristId: booking.clientInfo.client,
        assignedBy: adminUser.auth.user
      };

      assignRide(newRide);
    } else if (state === FormState.Completed) {
      setPopUp({ ...popUp, positive: true });
    }
  }, [state]);

  useEffect(() => {
    if (assignRideError) {
      dispatch(FormEvents.UploadingFail);
    }
  }, [assignRideError]);

  useEffect(() => {
    if (ride) {
      dispatch(FormEvents.UploadingSuccess);
    }
  }, [ride]);

  useEffect(() => {
    if (adminUser) {
      getUserById(adminUser.auth.user);
    }
  }, [adminUser]);

  useEffect(() => {
    if (id) {
      getBookingById(id);
      setIsLoading(false)
    }
  }, []);

  return (
    <section className="formLayout">
      <div className="formLayout__title">
        <Header name="Reservaciones" />
      </div>
      <div className="formLayout__container">
        <div className="formLayout__subtitle">
          <GoBackButton
            onClick={goBack}
            title={headerText.title[state]}
            subtitle={headerText.subtitle[state]}
          />
          <NewStepProcessBar stepsNumber={FormState.Completed + 1} actualStep={state + 1} />
          <p className="form__steps">
            <strong>Paso {state + 1}</strong> de 6
          </p>
        </div>
         <div className="formLayout__form">
          {isLoading && <LazyLoading /> }
          {!isLoading && booking && state === FormState.BookingInformation && (
            <AddReservationInfo
              state={state}
              dispatch={dispatch}
              formState={FormState}
              formEvents={FormEvents}
              booking={booking}
            />
          )}
          {state === FormState.SelectDriver && (
            <AddReservationDriver
              driverSelected={driverSelected}
              setDriverSelected={setDriverSelected}
            />
          )}
          {state === FormState.SelectVehicle && (
            <AddReservationVehicle
              vehicleSelected={vehicleSelected}
              setVehicleSelected={setVehicleSelected}
            />
          )}
          {state === FormState.CheckBooking && (
            <ReservationInfo
              booking={booking}
              driverSelected={driverSelected}
              vehicleSelected={vehicleSelected}
              admin={user}
            />
          )}
          {state === FormState.UploadingBooking && <LazyLoading />}
        </div>

        {state !== FormState.UploadingBooking &&
          state !== FormState.Completed && (
            <div className="formLayout__buttons">
              {state !== FormState.BookingInformation && (
                <button
                  className="action-button action-button--secondary"
                  onClick={() => dispatch(FormEvents.BackStep)}
                >
                  Atrás
                </button>
              )}
              <button
                className="action-button action-button--primary"
                disabled={isButtonDisabled}
                onClick={() => dispatch(FormEvents.NextStep)}
              >
                Continuar
              </button>
            </div>
          )}
      </div>

      <Overlays>
        {popUp.warning && (
          <PopUp
            type={"warning"}
            title={"¿Desea salir?"}
            subtitle={
              "Si sales ahora, perderás todo avance e información que has escrito hasta el momento"
            }
          >
            <button
              className="alert-button alert-button--secondary"
              onClick={() => navigate(-1)}
            >
              Sí
            </button>
            <button
              className="alert-button alert-button--primary"
              onClick={() => setPopUp({ ...popUp, warning: false })}
            >
              No
            </button>
          </PopUp>
        )}
        {popUp.informative && (
          <PopUp
            type={"informative"}
            title={"¿Está todo listo?"}
            subtitle={'Al presionar "Sí" se asignará la reserva'}
          >
            <button
              className="action-button action-button--secondary"
              onClick={() => updateState()}
            >
              Sí
            </button>
            <button
              className="action-button action-button--primary"
              onClick={() => setPopUp({ ...popUp, informative: false })}
            >
              No
            </button>
          </PopUp>
        )}
        {popUp.positive && (
          <PopUp
            type={"positive"}
            title={"¡La reserva se ha asignado correctamente!"}
            subtitle={`Al presionar "Aceptar" volverá a la vista principal`}
          >
            <button
              className="action-button action-button--primary"
              onClick={() => navigate(-1)}
            >
              Aceptar
            </button>
          </PopUp>
        )}
      </Overlays>
    </section>
  );
};

export default AddReservation;
