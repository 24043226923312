import { useState } from "react";
import useAxiosPrivate from "./useAxiosPrivate";

export function useReadBookings() {
  const axiosPrivate = useAxiosPrivate();

  const [booking, setBooking] = useState(null);
  const [bookings, setBookings] = useState(null);
  const [bookingError, setBookingError] = useState(null);

  let isMounted = true;

  const getBookings = async (status, limit, page) => {

    try {
      const response = await axiosPrivate.get(
        `reservation/?status=${status}&limit=${limit}&page=${page}`,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      );
      if (isMounted) {
        setBookings(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setBookingError(error);
        setBookings(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  const getBookingById = async (id) => {

    try {
      const response = await axiosPrivate.get(
        `reservation/${id}`,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      );
      if (isMounted) {
        setBooking(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setBookingError(error);
        setBooking(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  return { booking, bookings, bookingError, getBookings, getBookingById };
}

export function useUpdateBookings() {
  const axiosPrivate = useAxiosPrivate();

  const [updateBooking, setBooking] = useState(null);
  const [updateBookingError, setBookingError] = useState(null);

  let isMounted = true;

  const updateBookingStatus = async (bookingId, status) => {

    try {
      const response = await axiosPrivate.patch(
        `reservation/${bookingId}/update?status=${status}`,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      );
      if (isMounted) {
        setBooking(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setBookingError(error);
        setBooking(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  const updateBookingRide = async (bookingId, bookingData) => {
    console.log(bookingData)

    try {
      const response = await axiosPrivate.patch(
        `reservation/${bookingId}/update-ride`,
        bookingData,
      );
      if (isMounted) {
        setBooking(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setBookingError(error);
        setBooking(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };


  return { updateBooking, updateBookingError, updateBookingStatus, updateBookingRide };
}