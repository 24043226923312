import { useState, useEffect } from "react";
import { useUpdateVehicles } from "../../../hooks/useVehicles";

import EditVehicleInformation from "./EditVehicleInformation";
import EditVehicleDocuments from "./EditVehicleDocuments";
import LazyLoading from "../../../components/LazyLoading";
import GoBackButton from "../../GoBackButton";


const EditVehicle = ({ vehicle, close }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [formImage, setFormImage] = useState(null);
  const [state, setState] = useState({ success: false, fail: false })
  const [category, setCategory] = useState({
    information: true,
    documents: false,
  });

  const { updatedVehicle, registrationImage, updateVehicleError, updateVehicle, uploadRegistrationImage } = useUpdateVehicles();

  const handleCategory = (activeCategory) => {
    const initialValue = {
      information: false,
      documents: false,
    };
    setState({ success: false, fail: false })

    const updatedCategories = { ...initialValue };
    updatedCategories[activeCategory] = true;

    setCategory(updatedCategories);
  };

  useEffect(() => {
    if (vehicle) {
      setUser(vehicle)
    }
  }, [vehicle])

  useEffect(() => {
    if (updatedVehicle) {
      setUser(updatedVehicle)
    }
  }, [updatedVehicle])

  useEffect(() => {
    if (formData) {
      setIsLoading(true);
      updateVehicle(formData, vehicle._id);
    }
  }, [formData])

  useEffect(() => {
    if (formImage) {
      setIsLoading(true);
      uploadRegistrationImage(formImage, vehicle._id);
    }
  }, [formImage])

  useEffect(() => {
    if (updatedVehicle || registrationImage) {
      setState({ success: true, fail: false })
      setIsLoading(false);
    } else if (updateVehicleError) {
      setState({ success: false, fail: true })
      setIsLoading(false);
    }
  }, [updatedVehicle, registrationImage, updateVehicleError])

  return (
    <>
      <section className="edit-popup">
        <div className="edit-popup__box">
          <div className="edit-popup__container">
            <div className="edit-popup__header">
              <GoBackButton
                title="Edite la información del Vehículo"
                subtitle="Edite la información del Vehículo según sea necesario"
                onClick={close}
                isClose
              />
              {state.success && (
                <p className="edit-popup__status edit-popup__status--success">Cambios guardados con éxito</p>
              )}
              {state.fail && <p className="edit-popup__status edit-popup__status--error">Cambios no procesados</p>}
            </div>
            {!isLoading && (
              <>
                <div className="edit-popup__category">
                  <button
                    className={`category-button ${category.information ? "category-button--active" : ""}`}
                    onClick={() => handleCategory("information")}
                  >
                    Información
                  </button>
                  <button
                    className={`category-button ${category.documents ? "category-button--active" : ""}`}
                    onClick={() => handleCategory("documents")}
                  >
                    Documentos
                  </button>
                </div>
                {(category.information && user) && (
                  <EditVehicleInformation
                    data={user}
                    setFormData={setFormData}
                  />
                )}

                {(category.documents && user) &&
                  <EditVehicleDocuments
                    data={user}
                    setFormData={setFormImage}
                  />}
              </>
            )}
            {isLoading && <LazyLoading />}
          </div>
        </div>
      </section>
    </>
  );
};

export default EditVehicle;
