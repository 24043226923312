import React from 'react'

const WarningIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="110"
      height="110"
      viewBox="0 0 110 110"
      fill="none"
    >
      <path
        d="M47.1624 17.6916L8.34158 82.5C7.54118 83.8861 7.11768 85.4576 7.1132 87.0582C7.10871 88.6588 7.52341 90.2326 8.31603 91.6232C9.10866 93.0138 10.2516 94.1726 11.6311 94.9843C13.0106 95.796 14.5786 96.2324 16.1791 96.25H93.8207C95.4212 96.2324 96.9893 95.796 98.3688 94.9843C99.7483 94.1726 100.891 93.0138 101.684 91.6232C102.476 90.2326 102.891 88.6588 102.887 87.0582C102.882 85.4576 102.459 83.8861 101.658 82.5L62.8374 17.6916C62.0203 16.3446 60.8699 15.2309 59.4971 14.458C58.1242 13.6851 56.5754 13.2791 54.9999 13.2791C53.4245 13.2791 51.8756 13.6851 50.5028 14.458C49.1299 15.2309 47.9795 16.3446 47.1624 17.6916Z"
        stroke="#F55555"
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55 41.25V59.5833"
        stroke="#F55555"
        strokeWidth="7.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55 77.9166H55.0458"
        stroke="#F55555"
        strokeWidth="7.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default WarningIcon