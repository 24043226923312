import { useRef } from "react";
import { ReactComponent as ProfilePicture } from "../../../assets/icons/Profile_Picture.svg";
import CloudIcon from "../../../assets/svg/CloudIcon";

const EditCoverPicture = ({ pictureData }) => {
  const uploadPicture = useRef();

  const { pictureName, setPictureName } = pictureData;

  return (
    <>
      <form className="edit-popup__form">
        <div className="edit-popup__form-wrapper">
          <div className="form__input_container">
            <label htmlFor="">Nombre de la portada</label>
            <input
              type="text"
              name=""
              id=""
              placeholder="Agregue el nombre de la portada"
            />
          </div>
          <div className="form__upload_container">
            <ProfilePicture className="form__profile_picture" />
            <div className="form__upload_files">
              <p>Fotografía del sitio</p>
              <div className={`${pictureName ? "form__drag_and_drop--active" : "form__drag_and_drop"}`}>
                <CloudIcon />
                <h4>{pictureName ? pictureName : "Arrastra y suelta el archivo aquí"}</h4>
                <input
                  type="file"
                  onChange={(e) => setPictureName(e.target.value.split("\\").pop())}
                  ref={uploadPicture}
                  accept=".jpg,.png,.pdf,.jpeg"
                />
              </div>
              <button
                type="button"
                className="form__desktop_button"
                onClick={() => uploadPicture.current.click()}
              >
                Busca en tus archivos
              </button>
              <ol>
                <li>La fotografía debe ser clara y legible</li>
                <li>
                  Debe ser una fotografía donde se identifique el tipo de sitio, comercio o actividad a realizar en ese
                  lugar
                </li>
              </ol>
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="edit-popup__button action-button action-button--primary"
        >
          Guardar cambios
        </button>
      </form>
    </>
  );
};

export default EditCoverPicture;
