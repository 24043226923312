const languagesEs = [
  { label: "Español", value: "es" },
  { label: "Inglés", value: "en" },
  { label: "Mandarin", value: "zh" },
  { label: "Alemán", value: "de" },
  { label: "Hindú", value: "hi" },
  { label: "Arábigo", value: "ar" },
  { label: "Bengalí", value: "bn" },
  { label: "Portugués", value: "pt" },
  { label: "Japonés", value: "ja" },
  { label: "Francés", value: "fr" },
];

const languagesEn = [
  { label: "Spanish", value: "es" },
  { label: "English", value: "en" },
  { label: "Mandarin Chinese", value: "zh" },
  { label: "German", value: "de" },
  { label: "Hindi", value: "hi" },
  { label: "Arabic", value: "ar" },
  { label: "Bengali", value: "bn" },
  { label: "Portuguese", value: "pt" },
  { label: "Japanese", value: "ja" },
  { label: "French", value: "fr" },
];

const transformToStringLanguages = (list, languages) => {
  const labels = list.map(lang => languages.find(item => item.value === lang)?.label);
  if (!Array.isArray(labels)) {
    return '';
  }

  return labels.join(', ');
}

export { languagesEs, languagesEn, transformToStringLanguages };
