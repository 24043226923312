import React from 'react'

const DocumentImage = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="288"
      height="296"
      viewBox="0 0 288 296"
      fill="none"
    >
      <path
        d="M259.26 0.387207H29.5658C13.7705 0.387207 0.96582 13.1919 0.96582 28.9872V266.6C0.96582 282.396 13.7705 295.2 29.5658 295.2H259.26C275.055 295.2 287.86 282.396 287.86 266.6V28.9872C287.86 13.1919 275.055 0.387207 259.26 0.387207Z"
        fill="#F1F1F1"
      />
      <path
        d="M180.134 29.8179H25.6982V41.634H180.134V29.8179Z"
        fill="#959595"
      />
      <path
        d="M25.6982 120.405H263.124"
        stroke="#959595"
        strokeWidth="0.947272"
        stroke-miterlimit="10"
      />
      <path
        d="M135.166 120.405V265.769"
        stroke="#959595"
        strokeWidth="0.947272"
        stroke-miterlimit="10"
      />
      <path
        d="M123.998 195.133H25.6982V262.009H123.998V195.133Z"
        fill="#959595"
      />
      <path
        d="M120.775 198.623H95.2593V212.59H120.775V198.623Z"
        fill="#959595"
      />
      <path
        d="M56.5842 198.623H29.9927V203.993H56.5842V198.623Z"
        fill="#959595"
      />
      <path
        d="M170.253 133.895H140.65V139.265H170.253V133.895Z"
        fill="#959595"
      />
      <path
        d="M170.253 154.308H140.65V159.678H170.253V154.308Z"
        fill="#959595"
      />
      <path
        d="M170.253 174.721H140.65V180.091H170.253V174.721Z"
        fill="#959595"
      />
      <path
        d="M61.6878 133.895H25.6982V139.265H61.6878V133.895Z"
        fill="#959595"
      />
      <path
        d="M61.6878 154.308H25.6982V159.678H61.6878V154.308Z"
        fill="#959595"
      />
      <path
        d="M61.6878 174.721H25.6982V180.091H61.6878V174.721Z"
        fill="#959595"
      />
      <path
        d="M61.6878 53.856H25.6982V59.2263H61.6878V53.856Z"
        fill="#959595"
      />
      <path
        d="M61.6878 74.269H25.6982V79.6394H61.6878V74.269Z"
        fill="#959595"
      />
      <path
        d="M61.6878 94.6816H25.6982V100.052H61.6878V94.6816Z"
        fill="#959595"
      />
      <path
        d="M104.123 133.895H68.1333V139.265H104.123V133.895Z"
        fill="#959595"
      />
      <path
        d="M104.123 154.308H68.1333V159.678H104.123V154.308Z"
        fill="#959595"
      />
      <path
        d="M104.123 174.721H68.1333V180.091H104.123V174.721Z"
        fill="#959595"
      />
      <path
        d="M170.253 53.856H68.1333V59.2263H170.253V53.856Z"
        fill="#959595"
      />
      <path
        d="M263.124 53.856H184.161V59.2263H263.124V53.856Z"
        fill="#959595"
      />
      <path
        d="M170.253 74.269H68.1333V79.6394H170.253V74.269Z"
        fill="#959595"
      />
      <path
        d="M170.253 94.6816H68.1333V100.052H170.253V94.6816Z"
        fill="#959595"
      />
      <path
        d="M170.253 195.133H140.65V200.503H170.253V195.133Z"
        fill="#959595"
      />
      <path
        d="M170.253 215.546H140.65V220.916H170.253V215.546Z"
        fill="#959595"
      />
      <path
        d="M170.253 235.959H140.65V241.329H170.253V235.959Z"
        fill="#959595"
      />
      <path
        d="M263.128 133.895H180.134V139.265H263.128V133.895Z"
        fill="#959595"
      />
      <path
        d="M263.128 154.308H180.134V159.678H263.128V154.308Z"
        fill="#959595"
      />
      <path
        d="M263.128 174.721H180.134V180.091H263.128V174.721Z"
        fill="#959595"
      />
      <path
        d="M263.128 195.133H180.134V200.503H263.128V195.133Z"
        fill="#959595"
      />
      <path
        d="M263.128 215.546H180.134V220.916H263.128V215.546Z"
        fill="#959595"
      />
      <path
        d="M263.128 235.959H180.134V241.329H263.128V235.959Z"
        fill="#959595"
      />
      <path
        d="M263.124 87.251H184.161V92.2085H263.124V87.251Z"
        fill="#959595"
      />
      <path
        d="M263.124 94.8521H184.161V103.279H263.124V94.8521Z"
        fill="#959595"
      />
      <path
        d="M44.6744 207.22H29.9927V212.591H44.6744V207.22Z"
        fill="#959595"
      />
      <path
        d="M120.775 251.983H115.225V257.534H120.775V251.983Z"
        fill="#959595"
      />
      <path
        d="M112.72 251.983H107.169V257.534H112.72V251.983Z"
        fill="#959595"
      />
      <path
        d="M104.661 251.983H99.1104V257.534H104.661V251.983Z"
        fill="#959595"
      />
    </svg>
  );
}

export default DocumentImage