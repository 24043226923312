import React from 'react'

const ProfilePictureDemo3 = () => {
  return (
    <svg
      width="200"
      height="251"
      viewBox="0 0 200 251"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M170.276 0H29.6714C13.2843 0 0 13.1773 0 29.4322V221.436C0 237.691 13.2843 250.868 29.6714 250.868H170.276C186.663 250.868 199.948 237.691 199.948 221.436V29.4322C199.948 13.1773 186.663 0 170.276 0Z"
        fill="white"
      />
      <path
        d="M29.6716 250.868H170.276C177.439 250.868 184.011 248.349 189.138 244.153C175.803 211.071 140.92 187.464 99.974 187.464C59.0285 187.464 24.1446 211.071 10.8105 244.153C15.9366 248.349 22.5093 250.868 29.6716 250.868Z"
        fill="#959595"
      />
      <path
        d="M122.295 198.04C107.648 202.056 92.3012 202.056 77.6621 198.04V169.613H122.295V198.04Z"
        fill="#B1B1B1"
      />
      <path
        d="M99.9748 177.505C133.2 177.505 160.135 143.242 160.135 100.977C160.135 58.7115 133.2 24.4485 99.9748 24.4485C66.7492 24.4485 39.8145 58.7115 39.8145 100.977C39.8145 143.242 66.7492 177.505 99.9748 177.505Z"
        fill="#D9D9D9"
      />
      <path
        d="M41.5268 119.124C41.5268 119.124 52.3214 80.7471 59.3973 84.9038C66.4731 89.0605 80.9 100.977 95.6099 100.977C107.623 100.977 123.347 84.2409 128.717 78.0956C129.936 76.6996 131.956 76.2941 133.615 77.1363C139.928 80.3416 154.332 90.6436 158.428 119.117C162.634 110.405 165 100.61 165 90.2537C165 53.904 135.887 24.4407 99.9733 24.4407C64.0594 24.4407 34.9463 53.904 34.9463 90.2537C34.9463 100.61 37.3128 110.405 41.519 119.117"
        fill="#959595"
      />
      <path
        d="M146.211 114.494C145.928 113.94 145.598 113.409 145.244 112.887C144.261 111.756 142.374 110.446 138.899 110.446C138.546 110.446 138.145 110.423 137.704 110.376C134.898 110.376 132.091 110.368 129.292 110.36C125.4 110.36 121.509 110.352 117.617 110.352C115.667 110.352 113.725 110.352 111.775 110.36C110.313 110.36 108.874 110.329 107.475 110.82C106.233 111.257 105.155 112.029 104.306 113.027C104.015 113.371 103.78 113.893 103.497 114.283H96.578C96.5229 114.221 96.4679 114.158 96.4208 114.088C95.7446 113.113 94.9898 112.24 93.9835 111.585C91.3812 109.885 88.0948 110.337 85.1387 110.337C81.2627 110.337 77.3867 110.345 73.5029 110.345C69.8313 110.345 66.1518 110.345 62.4803 110.345C62.4095 110.345 62.2366 110.352 62.0086 110.368C61.6155 110.407 61.2538 110.423 60.9393 110.423C60.8293 110.423 60.7113 110.423 60.6091 110.423C58.1719 110.485 56.5523 111.202 55.4988 112.014C54.8305 112.801 54.1465 113.589 53.6355 114.462C52.1496 117.012 52.6449 120.366 53.2345 123.111C56.0177 135.916 69.8077 143.403 82.2455 139.356C87.8196 137.546 92.4504 133.6 95.1471 128.438C96.5386 125.778 97.3327 122.854 97.5765 119.867C97.6079 119.524 97.5136 118.596 97.5765 117.972H102.309C102.309 118.19 102.278 118.455 102.294 118.798C102.695 130.941 113.356 141.259 125.77 140.494C137.351 139.777 147.611 130.294 147.288 118.346C147.249 116.911 146.824 115.64 146.203 114.447L146.211 114.494Z"
        fill="#959595"
      />
    </svg>
  );
}

export default ProfilePictureDemo3