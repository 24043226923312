import { useState } from "react";
import useAxiosPrivate from "./useAxiosPrivate";

export function useReadRides() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [ride, setRide] = useState(null);
  const [rides, setRides] = useState(null);
  const [ridesError, setRidesError] = useState(null);

  const getTodayRidesByDriver = async () => {
    try {
      const response = await axiosPrivate.get(`rides/driver/find-today-rides`, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (isMounted) {
        setRides(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setRidesError(error);
        setRides(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    };

    return cleanUp;
  };

  const getNextRidesByDriver = async () => {
    try {
      const response = await axiosPrivate.get(`rides/driver/find-next-rides`, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (isMounted) {
        setRides(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setRidesError(error);
        setRides(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    };

    return cleanUp;
  };

  const getCompletedRidesByDriver = async (id, page) => {
    try {
      const response = await axiosPrivate.get(`rides/driver/${id}/completed?page=${page}`, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (isMounted) {
        setRides(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setRidesError(error);
        setRides(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    };

    return cleanUp;
  };

  const getRidesForDriver = async (driverId, startDate, endDate) => {
    try {
      const response = await axiosPrivate.get(
        startDate
          ? `rides/driver/filter-by-date/${driverId}?startDate=${startDate}&endDate=${endDate}`
          : `rides/driver/filter-by-date/${driverId}`,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (isMounted) {
        setRides(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setRidesError(error);
        setRides(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    };

    return cleanUp;
  };

  const getRideById = async (rideId) => {
    try {
      const response = await axiosPrivate.get(`rides/${rideId}`, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (isMounted) {
        setRide(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setRidesError(error);
        setRide(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    };

    return cleanUp;
  };

  return {
    ride,
    rides,
    ridesError,
    getTodayRidesByDriver,
    getNextRidesByDriver,
    getCompletedRidesByDriver,
    getRidesForDriver,
    getRideById,
  };
}

export function useReadRideStats() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [rides, setRides] = useState(null);
  const [ride, setRide] = useState(null);
  const [ridesLoading, setRidesLoading] = useState(false);
  const [ridesError, setRidesError] = useState(null);

  const getPendingRidesByUser = async (id, page) => {
    let params = new URLSearchParams();

    if (page) params.append("page", page);

    setRidesLoading(true);

    try {
      const response = await axiosPrivate.get(
        `rides/tourist/${id}?status=Pending&status=In Route&status=Driver Waiting&status=Trip In Progress&${params.toString()}`,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (isMounted) {
        setRides(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setRidesError(error);
        setRides(null);
      }
    } finally {
      isMounted && setRidesLoading(false);
    }

    const cleanUp = () => {
      isMounted = false;
    };

    return cleanUp;
  };

  const getRidesByUser = async (id, status, page) => {
    let params = new URLSearchParams();

    if (status) params.append("status", status);
    if (page) params.append("page", page);

    setRidesLoading(true);

    try {
      const response = await axiosPrivate.get(`rides/tourist/${id}?${params.toString()}`, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (isMounted) {
        setRides(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setRidesError(error);
        setRides(null);
      }
    } finally {
      isMounted && setRidesLoading(false);
    }

    const cleanUp = () => {
      isMounted = false;
    };

    return cleanUp;
  };

  const startRide = async (rideId) => {
    setRidesLoading(true);

    try {
      const response = await axiosPrivate.patch(
        `rides/${rideId}/start`,
        {},
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (isMounted) {
        setRide(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setRidesError(error);
        setRide(null);
      }
    } finally {
      isMounted && setRidesLoading(false);
    }

    const cleanUp = () => {
      isMounted = false;
    };

    return cleanUp;
  };

  const updateRide = async (rideId, status) => {
    setRidesLoading(true);

    try {
      const response = await axiosPrivate.patch(`rides/${rideId}/update`, status, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (isMounted) {
        setRide(response?.data);
      }
      console.log(response);
    } catch (error) {
      if (isMounted) {
        setRidesError(error);
        setRide(null);
      }
    } finally {
      isMounted && setRidesLoading(false);
    }

    const cleanUp = () => {
      isMounted = false;
    };

    return cleanUp;
  };

  const pickUpRide = async (rideId) => {
    setRidesLoading(true);

    try {
      const response = await axiosPrivate.patch(
        `rides/${rideId}/pick-up`,
        {},
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (isMounted) {
        setRide(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setRidesError(error);
        setRide(null);
      }
    } finally {
      isMounted && setRidesLoading(false);
    }

    const cleanUp = () => {
      isMounted = false;
    };

    return cleanUp;
  };

  const completeRide = async (rideId) => {
    setRidesLoading(true);

    try {
      const response = await axiosPrivate.patch(
        `rides/${rideId}/complete`,
        {},
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (isMounted) {
        setRide(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setRidesError(error);
        setRide(null);
      }
    } finally {
      isMounted && setRidesLoading(false);
    }

    const cleanUp = () => {
      isMounted = false;
    };

    return cleanUp;
  };

  return {
    rides,
    ride,
    ridesLoading,
    ridesError,
    getPendingRidesByUser,
    getRidesByUser,
    startRide,
    updateRide,
    pickUpRide,
    completeRide,
  };
}

export function useAssignRides() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [ride, setRide] = useState(null);
  const [assignRideError, setAssignRideError] = useState(null);

  const assignRide = async (newRide) => {
    setRide(null);
    setAssignRideError(null);

    try {
      const response = await axiosPrivate.post(`rides/create`, newRide, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (isMounted) {
        setRide(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setAssignRideError(error);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    };

    return cleanUp;
  };

  const reAssignRide = async (newRide, rideId) => {
    setRide(null);
    setAssignRideError(null);

    try {
      const response = await axiosPrivate.patch(`rides/${rideId}/update`, newRide, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (isMounted) {
        setRide(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setAssignRideError(error);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    };

    return cleanUp;
  };

  return { ride, assignRideError, assignRide, reAssignRide };
}

export function useUpdateRides() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [rideUpdated, setRideUpdated] = useState();
  const [rideUpdatedError, setRideUpdatedError] = useState();

  const startRide = async (rideId) => {
    setRideUpdatedError(null);

    try {
      const response = await axiosPrivate.patch(
        `rides/${rideId}/start`,
        {},
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (isMounted) {
        setRideUpdated(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setRideUpdatedError(error);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    };

    return cleanUp;
  };

  return { rideUpdated, rideUpdatedError, startRide };
}
