import React from "react";
import StartTripInformationRow from "./StartTripInformationRow";
import RouteLine from "../../assets/svg/RouteLine";
import MeetWith from "./MeetWith";

const StartTripInformationDriver = ({ user, ticket, tripState, goNext, cancelRide, setIsChatOpen }) => {
  return (
    <div className="startTripInformation">
      <div className="startTripInformation__container">
        <div className="startTripInformation__processBar">
          <span style={{ width: "100%" }} />
        </div>
        {(tripState === "In Route" || tripState === "Trip In Progress") && (
          <div className="startTripInformation__route">
            <RouteLine />
            <div className="startTripInformation__routeContainer">
              {tripState === "In Route" && <h4>Me dirijo a:</h4>}
              <StartTripInformationRow
                time={ticket.pickUpTime}
                site={ticket.pickUpLocation}
                isActive={tripState === "In Route"}
              />
              {tripState === "Trip In Progress" && <h4>Me dirijo a:</h4>}
              <StartTripInformationRow
                time={ticket.dropOffTime}
                site={ticket.dropOffLocation}
                isActive={tripState === "Trip In Progress"}
              />
            </div>
          </div>
        )}
        {tripState === "Driver Waiting" && (
          <MeetWith
            user={user}
            setIsChatOpen={setIsChatOpen}
          />
        )}
        {tripState === "In Route" && (
          <button
            className="startTripInformation__button action-button action-button--secondary"
            onClick={goNext}
          >
            He llegado
          </button>
        )}
        {tripState === "Trip In Progress" && (
          <button
            className="startTripInformation__button action-button action-button--primary"
            onClick={goNext}
          >
            Finalizar viaje
          </button>
        )}
        <button
          className="startTripInformation__button alert-button alert-button--primary"
          onClick={cancelRide}
        >
          Cancelar el viaje
        </button>
      </div>
    </div>
  );
};

export default StartTripInformationDriver;
