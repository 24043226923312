import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import DocumentIcon from "../../../assets/svg/DocumentIcon";
import DocumentImage from "../../../assets/svg/DocumentImage";
import CloudIcon from "../../../assets/svg/CloudIcon";
import UploadMarchamo from "../../uploadFile/uploadMarchamo";

const AddVehicleDocuments = ({ state, formState, validImages, uploadPictures }) => {
  const [registrationName, setRegistrationName] = useState("");
  const [documentsCompleted, setDocumentsCompleted] = useState(0);

  const {
    register,
    getFieldState,
    formState: { isDirty, isValid },
    handleSubmit,
  } = useForm();

  const isRegistrationUploadValid = getFieldState("vehicleRegistrationImage");

  const onSubmit = (data) => {
    uploadPictures(data.vehicleRegistrationImage);
  };

  useEffect(() => {
    if (isRegistrationUploadValid.isDirty) {
      setDocumentsCompleted(1);
      return;
    }
  }, [isRegistrationUploadValid]);

  useEffect(() => {
    validImages({ isDirty, isValid });
  }, [isDirty, isValid, validImages]);

  return (
    <form
      id="vehicle-pictures"
      onSubmit={handleSubmit(onSubmit)}
      className="form__upload"
      style={state === formState.UserImages ? {} : { display: "none" }}
    >
      {!isRegistrationUploadValid.invalid && (
        <>
          <div className={`form__document_progress ${registrationName ? "form__document_progress--active" : ""}`}>
            <p>
              <strong>Documento {registrationName ? 1 : 0}</strong> de {1}
            </p>
            <div className="form__document_progress--bar">
              <span style={{ width: `${registrationName ? 100 : 0}%` }}></span>
            </div>
          </div>
          <h3>Documentos requeridos</h3>
        </>
      )}
      <UploadMarchamo show registrationName={registrationName} setRegistrationName={setRegistrationName} register={register} />
    </form>
  );
};

export default AddVehicleDocuments;
