import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

import useLogout from "../hooks/useLogout";
import LogoutIcon from "../assets/svg/NavIcons/LogoutIcon";

const Header = ({ name }) => {
  const auth = useAuth()
  const navigate = useNavigate();
  const logout = useLogout();
  const goToLogin = async () => {
    await logout();
    navigate("/", { replace: true });
  };

  return (
    <section className="header">
      <h1>{name}</h1>
      <div className="header__profile">
        <p>{auth.auth.email}</p>
        <div className="header__dropdownContainer">
          <ul className="header__dropdown">
            <li className="_header__logoutButton">
              <button onClick={goToLogin}>
                <LogoutIcon />
                Cerrar Sesión
              </button>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Header;
