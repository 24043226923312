import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useReadVehicles } from "../../../hooks/useVehicles";

import Search from "../../../components/search";
import VehicleTable from "../../../components/tables/VehicleTable";
import VehicleCard from "../../../components/informativeCards/vehicleCard";
import AddButton from "../../../components/addButton";
import Paginator from "../../../components/paginator/paginator";
import Header from "../../../components/Header";
import CategoryButton from "../../../components/CategoryButton";

const MAX_RESULTS = 10;
const BUTTON_NAME = "Añadir Vehículo";
const BUTTON_ROUTE = "add";

function Vehicles() {
  const navigate = useNavigate();
  const { vehicle, vehicles, getVehicles, getVehicleById } = useReadVehicles();

  const [vehicleSelected, setVehicleSelected] = useState(null);
  const [search, setSearch] = useState("");

  function handleSearchVehicle(limit, page) {
    getVehicles(limit, page, search);
  }

  useEffect(() => {
    getVehicles(MAX_RESULTS, 1, search);
  }, [search]);

  useEffect(() => {
    if (vehicleSelected) {
      getVehicleById(vehicleSelected._id);
    }
  }, [vehicleSelected]);

  return (
    <section className="cardLayout">
      <div className="cardLayout__title">
        <Header name="Transporte" />
      </div>
      <section className="cardLayout__container">
        <div className="cardLayout__column">
          <div className="cardLayout__categories">
            <CategoryButton
              name="Conductores"
              onClick={() => navigate("/admin/transport/driver")}
            />
            <CategoryButton name="Vehículos" isActive={true} />
            <AddButton
              name={BUTTON_NAME}
              route={BUTTON_ROUTE}
              className="add-button"
            />
          </div>
          <h2 className="cardLayout__subtitle">
            Añada, consulte o elimine un vehículo
          </h2>
          <Search search={setSearch} calendar={false} typeOfSearch={"placa"} />
          <div className="cardLayout__content">
            <VehicleTable selected={setVehicleSelected} data={vehicles?.data} />
          </div>
          {vehicles && (
            <Paginator
              maxPages={vehicles.totalPages}
              search={handleSearchVehicle}
              resultsShown={MAX_RESULTS}
              maxResults={vehicles.count}
              page={vehicles.page}
            />
          )}
        </div>
        <div className="cardLayout__card">
          {vehicleSelected && vehicle && (
            <VehicleCard
              vehicle={vehicle}
              setClose={setVehicleSelected}
              isDescriptive={true}
              isEditable={false}
            />
          )}
        </div>
      </section>
    </section>
  );
}

export default Vehicles;
