import React from "react";
import StartTripInformationRow from "./StartTripInformationRow";
import RouteLine from "../../assets/svg/RouteLine";
import MeetWith from "./MeetWith";

const StartTripInformationDriver = ({ driver, ticket, tripState, confirmRide, stopTrip, setIsChatOpen }) => {
  return (
    <div className="startTripInformation">
      <div className="startTripInformation__container">
        <div className="startTripInformation__processBar">
          <span style={{ width: "100%" }} />
        </div>
        {(tripState === "In Route" || tripState === "Trip In Progress") && (
          <div className="startTripInformation__route">
            <RouteLine />
            <div className="startTripInformation__routeContainer">
              {tripState === "In Route" && <h4>El conductor se dirige a:</h4>}
              <StartTripInformationRow
                time={ticket.pickUpTime}
                site={ticket.pickUpLocation}
                isActive={tripState === "In Route"}
              />
              {tripState === "Trip In Progress" && <h4>Me dirijo a:</h4>}
              <StartTripInformationRow
                time={ticket.dropOffTime}
                site={ticket.dropOffLocation}
                dropOff
                isActive={tripState === "Trip In Progress"}
              />
            </div>
          </div>
        )}
        {tripState === "Driver Waiting" && (
          <>
            <MeetWith
              user={driver}
              setIsChatOpen={setIsChatOpen}
            />
            <button
              className="startTripInformation__button action-button action-button--secondary"
              onClick={confirmRide}
            >
              Confirmar abordo
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default StartTripInformationDriver;

// import React from "react";
// import StartTripInformationRow from "./StartTripInformationRow";
// import RouteLine from "../../assets/svg/RouteLine";
// import ChatIcon from "../../assets/svg/ChatIcon";
// import MeetWith from "./MeetWith";
// import { useReadRideStats } from "../../hooks/useRides";

// const titles = [
//   "El conductor se dirige a:",
//   "El conductor acaba de llegar a:",
//   "",
//   "Me dirijo a:",
//   "Me encuentro en:",
//   "Me encuentro en:",
// ];

// const StartTripInformationUser = ({ driver, ticket, tripState, setTripState, setIsChatOpen }) => {
//   const { updateRide } = useReadRideStats()

//   const confirmRide = () => {
//     updateRide("Trip In Progress");
//     setTripState("Trip In Progress");
//   };

//   return (
//     <div className="startTripInformation">
//       <div className="startTripInformation__container">
//         <div className="startTripInformation__processBar">
//           <span style={{ width: "100%" }} />
//         </div>
//         {(tripState === "In Route" || tripState === "Trip In Progress") && (
//           <div className="startTripInformation__route">
//             <RouteLine />
//             <div className="startTripInformation__routeContainer">
//               {tripState === "In Route" && <h4>El conductor se dirige a:</h4>}
//               <StartTripInformationRow
//                 time={ticket.pickUpTime}
//                 site={ticket.pickUpLocation}
//                 isActive={tripState === "In Route"}
//               />
//               {tripState === "Trip In Progress" && <h4>Me dirijo a:</h4>}
//               <StartTripInformationRow isActive={tripState === "Trip In Progress"} />
//             </div>
//           </div>
//         )}
//         {tripState === "Driver Waiting" && (
//           <MeetWith
//             user={driver}
//             setIsChatOpen={setIsChatOpen}
//           />
//         )}
//         <button
//           className="startTripInformation__button action-button action-button--secondary"
//           onClick={confirmRide}
//         >
//           Estoy a bordo
//         </button>
//       </div>
//     </div>
//   );
// };

// export default StartTripInformationUser;
