import React, { useState } from "react";
import { useForm } from "react-hook-form";
import UploadProfilePicture from "../../uploadFile/uploadProfilePicture";

const EditTouristProfilePhoto = ({ data, setFormData }) => {
  const [profilePictureName, setProfilePictureName] = useState(null);

  const { register, getFieldState, handleSubmit } = useForm();

  const isProfilePictureUploadValid = getFieldState("profilePicture");

  const onSubmit = (pictureData) => {
    setFormData(pictureData.profilePicture);
  };

  return (
    <>
      <h2 className="edit-popup__subtitle">Documentos requeridos</h2>
      <form
        className="edit-popup__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="edit-popup__form-wrapper">
          <UploadProfilePicture
            show
            profilePictureName={profilePictureName}
            setProfilePictureName={setProfilePictureName}
            register={register}
          />
        </div>
        <button
          type="submit"
          className="edit-popup__button action-button action-button--primary"
          disabled={!isProfilePictureUploadValid.isDirty}
        >
          Guardar cambios
        </button>
      </form>
    </>
  );
};

export default EditTouristProfilePhoto;
