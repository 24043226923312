import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import UploadArticlePicture from "../../uploadFile/uploadArticlePicture";

const AddArticlePicture = ({ state, formState, uploadPictures, validImages }) => {

  const [pictureName, setPictureName] = useState('');
  const [documentsCompleted, setDocumentsCompleted] = useState(0);

  const { register, getFieldState, formState: { isDirty, isValid }, handleSubmit } = useForm();

  const isPictureUploadValid = getFieldState("contentImage");

  const onSubmit = (data) => {
    uploadPictures(data.contentImage);
  }

  useEffect(() => {
    if (isPictureUploadValid.isDirty) {
      setDocumentsCompleted(1);
    }
  }, [isPictureUploadValid])

  useEffect(() => {
    validImages({ isDirty, isValid })
  }, [isDirty, isValid, validImages])

  return (
    <form id="article-picture" onSubmit={handleSubmit(onSubmit)}
      className="form__upload"
      style={state === formState.UserImages ? {} : { display: "none" }}
    >
      {!isPictureUploadValid && (
        <>
          <div className={`form__document_progress ${documentsCompleted > 0 && "form__document_progress--active"}`}>
            <p>
              <strong>Documento {documentsCompleted}</strong> de {1}
            </p>
            <div className="form__document_progress--bar">
              <span style={{ width: `${documentsCompleted * 100}%` }}></span>
            </div>
          </div>
          <h3>Documentos requeridos</h3>
        </>
      )}
      <UploadArticlePicture show articlePictureName={pictureName} setArticlePictureName={setPictureName} register={register} />

    </form>
  );
};

export default AddArticlePicture;
