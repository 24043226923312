import { useState } from "react";
import { useForm } from "react-hook-form";

import DocumentImage from "../../../assets/svg/DocumentImage";
import CloudIcon from "../../../assets/svg/CloudIcon";
import UploadMarchamo from "../../uploadFile/uploadMarchamo";

const EditVehicleDocuments = ({ data, setFormData }) => {
  const [registrationName, setRegistrationName] = useState(null);

  const {
    register,
    getFieldState,
    handleSubmit,
  } = useForm();

  const isVehicleRegistrationImageValid = getFieldState("vehicleRegistrationImage");

  const onSubmit = (vehicle) => {
    setFormData(vehicle.registrationPicture);
  };

  return (
    <>
      <h2 className="edit-popup__subtitle">Documentos Requeridos</h2>
      <form
        className="edit-popup__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="edit-popup__form-wrapper">
          <UploadMarchamo
            show
            registrationName={registrationName}
            setRegistrationName={setRegistrationName}
            register={register}
          />
        </div>
        <button
          type="submit"
          className="edit-popup__button action-button action-button--primary"
          disabled={!isVehicleRegistrationImageValid.isDirty}
        >
          Guardar cambios
        </button>
      </form>
    </>
  );
};

export default EditVehicleDocuments;
