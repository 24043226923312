import LicenseImage from "../../assets/svg/LicenseImage";
import CloudIcon from "../../assets/svg/CloudIcon";

const UploadLicense = ({ show, licenseName, setLicenseName, register }) => {
  return (
    <section className={`uploadFile ${show ? "uploadFile--show" : ""}`}>
      <div className="uploadFile__firstColumn">
        <div className="uploadFile__mainImage">
          <LicenseImage />
        </div>
      </div>
      <div className="uploadFile__secondColumn">
        <div className="uploadFile__input">
          <p>Licencia de conducir</p>
          <div className={`uploadFile__dragAndDrop ${licenseName ? "uploadFile__dragAndDrop--active" : ""}`}>
            <CloudIcon />
            <h4>{licenseName ? licenseName : "Arrastra y suelta el archivo aquí"}</h4>
            <input
              type="file"
              id="licensePicture"
              accept=".jpg, .png, .jpeg"
              {...register("licensePicture", {
                onChange: (e) => {
                  setLicenseName(e.target.files[0]?.name);
                },
                required: "Es necesaria una imagen",
              })}
            />
          </div>
          <button
            className="action-button action-button--primary uploadFile__button"
            type="button"
            onClick={() => document.getElementById("licensePicture").click()}
          >
            Busca en tus archivos
          </button>
        </div>
        <ol className="uploadFile__info">
          <li>La fotografía debe ser clara y legible</li>
          <li>La licencia debe de ser tipo B, C o E</li>
          <li>Debe ser un documento vigente</li>
        </ol>
      </div>
    </section>
  );
};

export default UploadLicense;
