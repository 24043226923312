import { useNavigate } from "react-router-dom";
import NoUserImage from "../../assets/icons/user.png";

const DriverTable = ({ selected, data, toAddReservation }) => {
  const navigate = useNavigate();

  const isMobileView = window.innerWidth <= 810;

  const handleCard = (user) => {
    if (isMobileView && !toAddReservation) {
      navigate(user?._id);
    } else {
      selected(user);
    }
  };

  return (
    <section className="dataTable">
      <section className="dataTable__header">
        <p>Nombre</p>
        <p>Apellido</p>
        <p>Teléfono</p>
      </section>
      <section className="dataTable__body">
        {data?.map((driver, i) => (
          <div
            key={driver._id}
            className="dataTable__row"
            onClick={() => handleCard(driver)}
          >
            <img
              src={driver.user.profilePicture ? driver.user.profilePicture : NoUserImage}
              alt=""
            />
            <div className="dataTable__rowItems">
              <p>{driver.user.name}</p>
              <p>{driver.user.lastName}</p>
              <p>{driver.user.phone}</p>
            </div>
            <div className="dataTable__rowItems--mobile">
              <p>
                {driver.user.name} {driver.user.lastName}
              </p>
              <p>{driver.user._id}</p>
              <p>{driver.user.phone}</p>
            </div>
            <div className="dataTable__arrow">
              <svg
                width="18"
                height="25"
                viewBox="0 0 10 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.70557 15.4904L8.58643 8.60959L1.70557 1.72873"
                  stroke="#959595"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ))}
      </section>
    </section>
  );
};

export default DriverTable;
