import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { tractions } from "../../../models/tractions";
import { months } from "../../../models/months";
import { availability } from "../../../models/vehicleAvailability";

import Select from 'react-select'

const EditVehicleInformation = ({ data, setFormData }) => {

  const { register, reset, control, formState: { errors, isValid }, handleSubmit } = useForm();

  const onSubmit = (vehicle) => {
    const newVehicle = {
      model: vehicle.model,
      passengerCapacity: parseInt(vehicle.passengerCapacity),
      plate: vehicle.plate,
      previousMechanicalIssuesDescription: vehicle.previousMechanicalIssuesDescription,
      status: vehicle.status,
      surfboardCapacity: parseInt(vehicle.surfboardCapacity),
      technicalReviewMonth: vehicle.technicalReviewMonth,
      traction: vehicle.traction,
      trunkCapacity: parseInt(vehicle.trunkCapacity)
    }
    setFormData(newVehicle);
  }

  const findValue = (value, array) => {
    return array.find(item => item.value === value);
  };

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [reset, data])

  return (
    <>
      <h2 className="edit-popup__subtitle">Información Requerida</h2>
      <form
        id="vehicle-information"
        className="edit-popup__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="edit-popup__form-wrapper">
          <section>
            <h3>Información básica del vehículo</h3>
            <div className="form__two_columns">
              <div className="form__input_container">
                <label htmlFor="">Placa del vehículo</label>
                <input
                  className={errors.plate?.message ? "error" : ""}
                  type="text"
                  placeholder="Escribe la placa"
                  {...register("plate", {
                    required: "Es requerida una placa",
                  })}
                />
              </div>
              <div className="form__input_container">
                <label htmlFor="">Modelo del vehículo</label>
                <input
                  className={errors.model?.message ? "error" : ""}
                  type="text"
                  placeholder="Escribe el modelo"
                  {...register("model", {
                    required: "Es requerido un modelo",
                  })}
                />
              </div>
            </div>
            <div className="form__input_container">
              <label htmlFor="">Disponibilidad del vehículo</label>
              <Controller
                render={({ field: { onChange } }) => (
                  <Select
                    classNamePrefix="react-select"
                    isClearable={false}
                    options={availability}
                    defaultValue={findValue(data?.status, availability)}
                    placeholder="Selecciona la capacidad del vehículo"
                    onChange={(value) => {
                      onChange(value.value);
                    }}
                  />
                )}
                control={control}
                name="status"
                rules={{ required: true }}
              />
            </div>
            <div className="form__two_columns">
              <div className="form__input_container">
                <label htmlFor="">Capacidad del vehículo</label>
                <input
                  className={errors.model?.message ? "error" : ""}
                  type="number"
                  placeholder="Escribe la capacidad de personas"
                  {...register("passengerCapacity", {
                    required: "Es requerido un modelo",
                  })}
                />
              </div>
              <div className="form__input_container">
                <label htmlFor="">Capacidad del maletero</label>
                <input
                  className={errors.model?.message ? "error" : ""}
                  type="number"
                  placeholder="Escribe la capacidad del maletero"
                  {...register("trunkCapacity", {
                    required: "Es requerido un modelo",
                  })}
                />
              </div>
            </div>
            <div className="form__two_columns">
              <div className="form__input_container">
                <label htmlFor="">Cantidad de tablas de surf</label>
                <input
                  className={errors.model?.message ? "error" : ""}
                  type="number"
                  placeholder="Escribe la capacidad de tablas de surf"
                  {...register("surfboardCapacity", {
                    required: "Es requerido un modelo",
                  })}
                />
              </div>
              <div className="form__input_container">
                <label htmlFor="">Tipo de tracción</label>
                <Controller
                  render={({ field: { onChange } }) => (
                    <Select
                      classNamePrefix="react-select"
                      isClearable={false}
                      options={tractions}
                      defaultValue={findValue(data?.traction, tractions)}
                      placeholder="Selecciona el tipo de tracción"
                      onChange={(value) => {
                        onChange(value.value);
                      }}
                    />
                  )}
                  control={control}
                  name="traction"
                  rules={{ required: true }}
                />
              </div>
            </div>
          </section>
          <section>
            <h3>Información mecánica del vehículo</h3>
            <div className="form__input_container">
              <label htmlFor="">Mes de la revisión técnica</label>
              <Controller
                render={({ field: { onChange } }) => (
                  <Select
                    classNamePrefix="react-select"
                    isClearable={false}
                    options={months}
                    defaultValue={findValue(data?.technicalReviewMonth, months)}
                    placeholder="Seleccione el mes de inspección"
                    onChange={(value) => {
                      onChange(value.value);
                    }}
                  />
                )}
                control={control}
                name="technicalReviewMonth"
                rules={{ required: true }}
              />
            </div>
            <div className="form__input_container">
              <label htmlFor="">Descripción de detalles reportados en anteriores revisiones mecánicas</label>
              <textarea
                cols="30"
                rows="10"
                placeholder="Escriba los detalles anteriormente reportados"
                {...register("previousMechanicalIssuesDescription", {
                  required: false,
                })}
              ></textarea>
            </div>
          </section>
        </div>
        <button
          type="submit"
          className="edit-popup__button action-button action-button--primary"
          disabled={!isValid}
        >
          Guardar cambios
        </button>
      </form>
    </>
  );
};

export default EditVehicleInformation;
