import { useState, useEffect } from "react";
import { useReadPoints } from "../../../hooks/usePoints";

import Search from "../../../components/search";
import PointCard from "../../../components/informativeCards/pointCard";
import AddButton from "../../../components/addButton";
import Paginator from "../../../components/paginator/paginator";
import Header from "../../../components/Header";
import PointTable from "../../../components/tables/PointTable";

const MAX_RESULTS = 10;
const BUTTON_NAME = "Añadir Punto";
const BUTTON_ROUTE = "add";

function Points() {
  const { points, getPoints } = useReadPoints();

  const [pointSelected, setPointSelected] = useState(null);
  const [search, setSearch] = useState("");

  function handleSearchPoint(limit, page) {
    getPoints(limit, page, search);
  }

  useEffect(() => {
    handleSearchPoint(MAX_RESULTS, 1, search);
  }, [search]);

  return (
    <section className="cardLayout">
      <div className="cardLayout__title">
        <Header name="Puntos de Interés" />
      </div>
      <section className="cardLayout__container">
        <div className="cardLayout__column">
          <div className="cardLayout__subtitleWithButton">
            <h2>Añada, edite o consulte los puntos de interés</h2>
            <AddButton
              name={BUTTON_NAME}
              route={BUTTON_ROUTE}
              className="add-button"
            />
          </div>
          <Search search={setSearch} calendar={false} typeOfSearch={"nombre"} />
          <div className="cardLayout__content">
            <PointTable data={points} selected={setPointSelected} />
          </div>
          {points && (
            <Paginator
              maxPages={points.totalPages}
              search={handleSearchPoint}
              resultsShown={MAX_RESULTS}
              maxResults={points.count}
              page={points.page}
            />
          )}
        </div>
        <div className="cardLayout__card">
          {pointSelected && (
            <PointCard
              point={pointSelected}
              setClose={setPointSelected}
              isDescriptive={true}
            />
          )}
        </div>
      </section>
    </section>
  );
}

export default Points;
