import { useEffect, useState, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useReadBookings } from "../../../hooks/useBookings";
import { useAssignRides } from "../../../hooks/useRides";
import {
  FormState,
  FormEvents,
  bookingStates,
} from "../../../states/bookingStates";

import AddReservationDriver from "../../../components/forms/AddReservation/NewAddReservationDriver";
import AddReservationVehicle from "../../../components/forms/AddReservation/NewAddReservationVehicle";
import AddReservationConfirm from "../../../components/forms/AddReservation/NewAddReservationConfirm";
import LazyLoading from "../../../components/LazyLoading";
import Header from "../../../components/Header";
import GoBackButton from "../../../components/GoBackButton";
import Overlays from "../../../components/modals/overlays";
import PopUp from "../../../components/modals/popUp";
import NewStepProcessBar from "../../../components/newStepProcessBar";

const headerText = {
  title: [
    "Confirme la información de la reservación",
    "Asigne el conductor para la reservación",
    "Asigne el vehículo para la reservación",
    "Confirme la nueva información de la reservación",
    "Editando la reservación",
  ],
  subtitle: [
    "Verifique la información de la reservación antes de editarla",
    "Seleccione un conductor en la tabla para asignar el conductor a cargo de la reservación",
    "Seleccione un vehículo en la tabla para asignar el vehículo en el que se transportará el turista",
    "Verifique la información ingresada antes de continuar",
    "Esto podría tomar unos minutos...",
  ],
};

const EditReservation = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const { booking, getBookingById } = useReadBookings();
  const { ride, assignRideError, reAssignRide } = useAssignRides();

  const [driverSelected, setDriverSelected] = useState(null);
  const [vehicleSelected, setVehicleSelected] = useState(null);
  const [popUp, setPopUp] = useState({
    warning: false,
    informative: false,
    positive: false,
  });

  const [state, dispatch] = useReducer(bookingStates, FormState.SelectDriver);

  const updateState = () => {
    if (state === FormState.CheckBooking) {
      dispatch(FormEvents.NextStep);
    }
  };

  useEffect(() => {
    if (state === FormState.BookingInformation) {
      navigate(-1);
    }
    if (state === FormState.UploadingBooking) {
      setPopUp({ ...popUp, informative: false });

      const newRide = {
        driver: driverSelected._id,
        vehicle: vehicleSelected._id,
      };

      reAssignRide(newRide, booking.ride._id);
    } else if (state === FormState.Completed) {
      setPopUp({ ...popUp, positive: true });
    }
  }, [state]);

  useEffect(() => {
    if (assignRideError) {
      dispatch(FormEvents.UploadingFail);
    }
  }, [assignRideError]);

  useEffect(() => {
    if (ride) {
      dispatch(FormEvents.UploadingSuccess);
    }
  }, [ride]);

  useEffect(() => {
    if (id) {
      getBookingById(id);
    }
  }, []);

  return (
    <section className="formLayout">
      <div className="formLayout__title">
        <Header name="Reservaciones" />
      </div>
      <div className="formLayout__container">
        <div className="formLayout__subtitle">
          <GoBackButton
            onClick={() => {
              setPopUp({ ...popUp, warning: true });
            }}
            title={headerText.title[state]}
            subtitle={headerText.subtitle[state]}
          />
          <NewStepProcessBar stepsNumber={5} actualStep={state + 1} />
          <p className="form__steps">
            <strong>Paso {state + 1}</strong> de 3
          </p>
        </div>
        <div className="formLayout__form">
          {state === FormState.SelectDriver && (
            <AddReservationDriver
              state={state}
              dispatch={dispatch}
              formState={FormState}
              formEvents={FormEvents}
              driverSelected={driverSelected}
              setDriverSelected={setDriverSelected}
            />
          )}
          {state === FormState.SelectVehicle && (
            <AddReservationVehicle
              state={state}
              dispatch={dispatch}
              formState={FormState}
              formEvents={FormEvents}
              vehicleSelected={vehicleSelected}
              setVehicleSelected={setVehicleSelected}
            />
          )}
          {state === FormState.CheckBooking && (
            <AddReservationConfirm
              state={state}
              setPopUp={setPopUp}
              popUp={popUp}
              formState={FormState}
              formEvents={FormEvents}
              booking={booking}
              driverSelected={driverSelected}
              vehicleSelected={vehicleSelected}
            />
          )}
          {state === FormState.UploadingBooking && <LazyLoading />}
        </div>
        <div className="formLayout__buttons">
          {state !== FormState.BookingInformation && (
            <button
              className="action-button action-button--secondary"
              onClick={() => dispatch(FormEvents.BackStep)}
            >
              Atrás
            </button>
          )}
          <button
            className="action-button action-button--primary"
            onClick={() => dispatch(FormEvents.NextStep)}
          >
            Continuar
          </button>
        </div>
        <Overlays>
          {popUp.warning && (
            <PopUp
              type={"warning"}
              title={"¿Desea salir?"}
              subtitle={
                "Si sales ahora, perderás todo avance e información que has escrito hasta el momento"
              }
            >
              <button
                className="alert-button alert-button--secondary"
                onClick={() => navigate(-1)}
              >
                Sí
              </button>
              <button
                className="alert-button alert-button--primary"
                onClick={() => setPopUp({ ...popUp, warning: false })}
              >
                No
              </button>
            </PopUp>
          )}
          {popUp.informative && (
            <PopUp
              type={"informative"}
              title={"¿Está todo listo?"}
              subtitle={'Al presionar "Sí" se asignará la reserva'}
            >
              <button
                className="action-button action-button--secondary"
                onClick={() => updateState()}
              >
                Sí
              </button>
              <button
                className="action-button action-button--primary"
                onClick={() => setPopUp({ ...popUp, informative: false })}
              >
                No
              </button>
            </PopUp>
          )}
          {popUp.positive && (
            <PopUp
              type={"positive"}
              title={"¡La reserva se ha asignado correctamente!"}
              subtitle={`Al presionar "Aceptar" volverá a la vista principal`}
            >
              <button
                className="action-button action-button--primary"
                onClick={() => navigate(-1)}
              >
                Aceptar
              </button>
            </PopUp>
          )}
        </Overlays>
      </div>
    </section>
  );
};

export default EditReservation;
