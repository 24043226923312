import { useState } from "react";
import { useForm } from "react-hook-form";

import { ReactComponent as ProfilePicture } from "../../../assets/icons/Profile_Picture.svg";
import LicenseImage from "../../../assets/svg/LicenseImage";
import CloudIcon from "../../../assets/svg/CloudIcon";
import ProfilePictureDemo1 from "../../../assets/svg/ProfilePicturesDemo/ProfilePictureDemo1";
import ProfilePictureDemo2 from "../../../assets/svg/ProfilePicturesDemo/ProfilePictureDemo2";
import ProfilePictureDemo3 from "../../../assets/svg/ProfilePicturesDemo/ProfilePictureDemo3";
import UploadLicense from "../../uploadFile/uploadLicense";
import UploadProfilePicture from "../../uploadFile/uploadProfilePicture";

const EditDriverDocuments = ({ data, setFormData }) => {
  const [profilePictureName, setProfilePictureName] = useState("");
  const [licenseName, setLicenseName] = useState("");

  const { register, getFieldState, handleSubmit } = useForm();

  const isProfilePictureUploadValid = getFieldState("profilePicture");
  const isLicensePictureUploadValid = getFieldState("licensePicture");

  const onSubmit = (response) => {
    let filePictures;
    if (profilePictureName) {
      filePictures = { ...filePictures, profilePicture: response.profilePicture }
    }

    if (licenseName) {
      filePictures = { ...filePictures, licensePicture: response.licensePicture }
    }

    setFormData(filePictures);
  };

  return (
    <>
      <h2 className="edit-popup__subtitle">Documentos requeridos</h2>
      <form
        className="edit-popup__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="edit-popup__form-wrapper">
          <UploadLicense
            show
            licenseName={licenseName}
            setLicenseName={setLicenseName}
            register={register}
          />
          <UploadProfilePicture
            show
            profilePictureName={profilePictureName}
            setProfilePictureName={setProfilePictureName}
            register={register}
          />
        </div>
        <button
          disabled={!(isProfilePictureUploadValid.isDirty || isLicensePictureUploadValid.isDirty)}
          className="edit-popup__button action-button action-button--primary"
        >
          Guardar cambios
        </button>
      </form>
    </>
  );
};

export default EditDriverDocuments;
