import "../App.scss";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

import Navbar from "./Navbar/AdminNavbar";
import { useState } from "react";
import UserNavbar from "./Navbar/UserNavbar";
import useLogout from "../hooks/useLogout";

const Layout = () => {
  const logout = useLogout();
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [isMenuActive, setIsMenuActive] = useState(false);

  const goToLogin = async () => {
    await logout();
    navigate("/", { replace: true });
  };

  const routeToGo = () => {
    switch (auth.role) {
      case "Super":
        navigate("/admin");
        break;

      case "Admin":
        navigate("/admin");
        break;

      case "Tourist":
        navigate("/user");
        break;

      case "Driver":
        navigate("/driver");
        break;

      default:
        navigate("/");
        break;
    }
  };

  return (
    <main className={`main_container ${auth?.role ? "main_container__navbar" : "main_container__without-navbar"}`}>
      {auth?.role && (
        <div className="main_container__title">
          {(auth.role === "Super" || auth.role === "Admin") && (
            <button
              className="main_container__menu"
              onClick={() => setIsMenuActive(!isMenuActive)}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          )}
          <img
            className="main_container__logo"
            src={require("../assets/icons/NosaraLogo.png")}
            alt="Nosara Logo"
            onClick={routeToGo}
          />
            <button
              className="logout_button"
              onClick={goToLogin}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="58"
                height="58"
                viewBox="0 0 58 58"
                fill="none"
              >
                <path
                  d="M22.168 7.81543H12.6127C11.3456 7.81543 10.1304 8.31879 9.23442 9.21476C8.33844 10.1107 7.83508 11.326 7.83508 12.5931V46.0365C7.83508 47.3036 8.33844 48.5188 9.23442 49.4147C10.1304 50.3107 11.3456 50.8141 12.6127 50.8141H22.168"
                  stroke="#0971AB"
                  stroke-width="4.77763"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M38.8896 41.2587L50.8337 29.3147L38.8896 17.3706"
                  stroke="#0971AB"
                  stroke-width="4.77763"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M50.8328 29.3145H22.167"
                  stroke="#0971AB"
                  stroke-width="4.77763"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>

        </div>
      )}
      {(auth.role === "Super" || auth.role === "Admin") && (
        <div className={`main_navbar ${isMenuActive ? "main_navbar--active" : ""}`}>
          <Navbar
            onClose={setIsMenuActive}
            user={auth.role}
            setAuth={setAuth}
          />
        </div>
      )}
      {(auth?.role === "Driver" || auth?.role === "Tourist") && (
        <UserNavbar
          user={auth.role}
          setAuth={setAuth}
        />
      )}
      <Outlet />
    </main>
  );
};

export default Layout;
