const FormState = {
    PointLocation: 0,
    PointInformation: 1,
    CreatingPoint: 2,
    PointImage: 3,
    UploadingImage: 4,
    Completed: 5
}

const FormEvents = {
    SelectPoint: 0,
    CreatePoint: 1,
    CreatePointSuccess: 2,
    CreatePointFail: 3,
    UploadImage: 4,
    UploadImageSuccess: 5,
    UploadImageFail: 6
}

const createPointStates = (state, event) => {
    switch (state) {
        case FormState.PointLocation:
            if (event === FormEvents.SelectPoint) return FormState.PointInformation;
            break;
        case FormState.PointInformation:
            if (event === FormEvents.CreatePoint) return FormState.CreatingPoint;
            break;
        case FormState.CreatingPoint:
            if (event === FormEvents.CreatePointSuccess) return FormState.PointImage;
            if (event === FormEvents.CreatePointFail) return FormState.PointInformation;
            break;
        case FormState.PointImage:
            if (event === FormEvents.UploadImage) return FormState.UploadingImage;
            break;
        case FormState.UploadingImage:
            if (event === FormEvents.UploadImageSuccess) return FormState.Completed;
            if (event === FormEvents.UploadImageFail) return FormState.PointImage;
            break;
        default:
            return state;
    }
};

export { FormState, FormEvents, createPointStates };