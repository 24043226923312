import { useState } from "react";
import useAxiosPrivate from "./useAxiosPrivate";

export function useReadDrivers() {
  const axiosPrivate = useAxiosPrivate();

  const [drivers, setDrivers] = useState(null);
  const [driver, setDriver] = useState(null);
  const [driversError, setError] = useState(null);

  let isMounted = true;

  const getAvailableDrivers = async (status, searchDate) => {

    try {
      const response = await axiosPrivate.get(
        `driver?status=${status}`,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      );
      if (isMounted) {
        setDrivers(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
        setDrivers(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  const getDrivers = async (limit, page, lastName) => {
    let params = new URLSearchParams();

    if (limit) params.append('limit', limit);
    if (page) params.append('page', page);
    if (lastName) params.append('lastName', lastName);

    const url = `driver?${params.toString()}`;

    try {
      const response = await axiosPrivate.get(
        url,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      );
      if (isMounted) {
        setDrivers(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
        setDrivers(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  const getDriverById = async (userId) => {

    try {
      const response = await axiosPrivate.get(
        `driver/${userId}`,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          }
        },
      );
      if (isMounted) {
        setDriver(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
        setDriver(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  const getDriverProfile = async () => {

    try {
      const response = await axiosPrivate.get(
        `driver/profile/me `,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          }
        },
      );
      if (isMounted) {
        setDriver(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
        setDriver(null);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };


  return { driver, drivers, driversError, getAvailableDrivers, getDrivers, getDriverById, getDriverProfile };
}

export function useUpdateDrivers() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [updateDriverError, setUpdateDriverError] = useState(null);
  const [licensePicture, setLicensePicture] = useState(null);

  const uploadLicensePicture = async (imageData, userId) => {
    const formData = new FormData();
    formData.append('licensePhoto', imageData[0]);

    try {
      const response = await axiosPrivate.patch(
        `driver/${userId}/upload-license`,
        formData
      );
      if (isMounted) {
        setLicensePicture(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setUpdateDriverError(error);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  return { licensePicture, updateDriverError, uploadLicensePicture };
}

export function useAssignVehicle() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [assignVehicleLoading, setAssignLoading] = useState(true);
  const [releaseVehicleLoading, setReleaseLoading] = useState(true);
  const [assignVehicleError, setAssignError] = useState(null);
  const [releaseVehicleError, setReleaseError] = useState(null);

  const assignVehicle = async (userId, vehicleId,) => {
    setAssignLoading(true);

    try {
      await axiosPrivate.patch(
        `vehicle-assignment/assign?driverId=${userId}&vehicleId=${vehicleId}`,
        {},
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      );
    } catch (error) {
      if (isMounted) {
        setAssignError(error);
      }
    } finally {
      isMounted && setAssignLoading(false);
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  const releaseVehicle = async (userId, vehicleId,) => {
    setReleaseLoading(true);

    try {
      await axiosPrivate.patch(
        `driver/assign?driverId=${userId}&vehicleId=${vehicleId}`,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      );
    } catch (error) {
      if (isMounted) {
        setReleaseError(error);
      }
    } finally {
      isMounted && setReleaseLoading(false);
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  return { assignVehicleLoading, assignVehicleError, releaseVehicleLoading, releaseVehicleError, assignVehicle, releaseVehicle };
}

export function useDeleteDrivers() {
  const axiosPrivate = useAxiosPrivate();
  let isMounted = true;

  const [driverDeleted, setDriverDeleted] = useState(null)
  const [deleteDriverError, setError] = useState(null);

  const deleteDriver = async (driverId) => {
    setDriverDeleted(null);

    try {
      const response = await axiosPrivate.delete(
        `driver/${driverId}/delete`,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      );
      if (isMounted) {
        setDriverDeleted(response?.data);
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
      }
    }

    const cleanUp = () => {
      isMounted = false;
    }

    return cleanUp;
  };

  return { driverDeleted, deleteDriverError, deleteDriver };
}