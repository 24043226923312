import { useEffect } from "react";
import { useForm } from "react-hook-form";


const EditDriverAccount = ({ data, setFormData }) => {
  const {
    register,
    reset,
    watch,
    formState: { isValid },
    handleSubmit,
  } = useForm();

  const onSubmit = (userData) => {
    let newUserData;

    if (userData?.email !== data?.email) {
      newUserData = { ...newUserData, email: userData.email };
    }

    if (userData?.password) {
      newUserData = { ...newUserData, password: userData.password };
    }

    setFormData(newUserData);
  };

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [reset, data]);

  return (
    <>
      <h2 className="edit-popup__subtitle">Información de la cuenta</h2>
      <form
        className="edit-popup__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="edit-popup__form-wrapper">
          <div className="form__input_container">
            <label htmlFor="">Nuevo Correo Electrónico *</label>
            <input
              type="email"
              placeholder="Escribe el nuevo correo electrónico"
              {...register("email", {
                required: "Es necesario un correo electrónico",
              })}
            />
          </div>
          <div className="form__input_container">
            <label htmlFor="">Nueva Contraseña *</label>
            <input
              type="password"
              placeholder="Escribe la nueva contraseña"
              {...register("password", {
                validate: (password) => {
                  const isPasswordValid = /^(?=.*?[!@#$%])(?=.*?[A-Z])[A-Za-z\d!@#$%]{8,12}$/;

                  if (password === "" && password.match(isPasswordValid) === false) {
                    return "La longitud debe ser entre 8 y 12 caracteres, con al menos una letra mayúscula y un carácter especial";
                  }
                },
              })}
            />
            <p>*La nueva contraseña requiere:</p>
            <ol>
              <li>Una longitud entre 8 y 12 caracteres</li>
              <li>
                Al menos un carácter especial, como:
                <span aria-label="Signo de exclamación">!</span>,<span aria-label="Arroba">@</span>,
                <span aria-label="Símbolo numeral">#</span>,<span aria-label="Símbolo de dólar">$</span>,
                <span aria-label="Signo de porcentaje">%</span>, etc.
              </li>
              <li>Al menos una letra mayúscula.</li>
            </ol>
          </div>
          <div className="form__input_container">
            <label htmlFor="">Confirma la contraseña *</label>
            <input
              type="password"
              {...register("confirm_password", {
                validate: (value) => {
                  if (watch("password") !== value) {
                    return "Your passwords do no match";
                  }
                },
              })}
            />
          </div>
        </div>
          <button
            type="submit"
            className="edit-popup__button action-button action-button--primary"
            disabled={!isValid}
          >
            Guardar cambios
          </button>
      </form>
    </>
  );
};

export default EditDriverAccount;
